import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import ProfileImg from '../../assets/images/profile_image.png';
import StarImg from '../../assets/images/star.png';
import * as reduxActions from "../../constants/ActionTypes";

import './LayoutStyle.css';

class ProfileWidget extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      lesson_credits: null,
    };
  };

  componentDidMount() {
    const { user_type, getStudentWalletRequest } = this.props;
    if (user_type === 'student') getStudentWalletRequest();
  }

  componentDidUpdate(prevProps) {
    const { wallet } = this.props;

    if (prevProps.wallet !== wallet) {
      const { result } = wallet;
      if (result.success) {
        this.setState({
          lesson_credits: _.get(result, 'response.data.lesson_credits', null),
        });
      }
    }
  }

  render() {
    const { lesson_credits } = this.state;
    const { user, user_type, totalStars, calendarType } = this.props;
    return (
      <section className="section section-student-header-bar">
        <div className="level">
          <div className="level-left student-header-bar">
            <div className="level-item justify-left">
              <img className="profile-image" src={ProfileImg} alt="Profile" />
              <div>{`${_.get(user, 'profile.nickname', '') || _.get(user, 'profile.fullname', '')}`} {calendarType && `${calendarType} schedule`}</div>
            </div>
            {user_type === 'student' && <div className="level-item justify-left">
              <img src={StarImg} className="star" alt="Star" />{totalStars}
            </div>}
            {user_type === 'student' && <div className="level-item justify-left credit">Class Credits: {lesson_credits}</div>}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _.get(state, "auth.login.result.response.data", {}),
    user_type: _.get(state, "auth.login.result.response.role", ""),
    wallet: _.get(state, "room.wallet", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentWalletRequest: payload => dispatch({ type: reduxActions.GET_WALLET_DETAIL_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWidget);
