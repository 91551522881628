import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { _ROUTES } from "../../constants/GlobalConst";
import PageLoader from "../layout/PageLoader";

class PrivateRoute extends React.Component {
  render() {
    const { component: Component, token, componentProps, ...rest } = this.props;

    if(_.isEmpty(token)){
      window.location = _ROUTES.PATH_LOGIN;
      return;
    }

    if (token)
      return (

        <Route
          {...rest}
          render={(props) =>
              <div>
                <PageLoader />
                <div>
                  <Component {...props} {...componentProps} />
                </div>
              </div>
          }
        />

      )
  }
}

const mapStateToProps = (state) => ({
  token: _.get(state, "auth.login.result.response.token", ""),
});

export default connect(mapStateToProps, null)(PrivateRoute);