import React, { Suspense } from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { zoomRange, _CONST, _ROUTES } from "../../constants/GlobalConst";
import * as reduxActions from "../../constants/ActionTypes";
import WalletNotification from '../../components/layout/WalletNotification';
import ConfirmModal from '../../components/layout/ConfirmModal';
import './CourseStyle.css';

const StripeForm = React.lazy(() => import('./components/StripeForm'));
const MSG_PAID_SUCCESS = '<b>Heads up!</b> Successfully Paid';
class Course extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      courses: [],
      card: {},
      selected_course_uuid: '',
      stripe_token: '',
      error: '',
      showConfirmPaymentModal: false,
      showConfirmRemoveCardModal: false,
      render: false,
    };
  }

  componentDidMount() {
    if(document.body.style.zoom !== zoomRange.one){
      document.body.style.zoom = zoomRange.one
    }
    const { getAllCoursesRequest, getAllCardsRequest } = this.props;

    getAllCoursesRequest();
    getAllCardsRequest();
  }

  componentDidUpdate(prevProps) {
    const {
      all_courses, all_cards, add_card, charge_card, remove_card, history,
      storeNotificationRequest,
    } = this.props;

    if (prevProps.all_courses !== all_courses) {
      const { result } = all_courses;
      if (result.success) {
        this.setState({
          courses: _.reverse(_.sortBy(_.get(result, 'response.data', []), ['number_of_lessons'])),
        });
      } else {
        this.setState({ error: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.all_cards !== all_cards) {
      const { result } = all_cards;
      if (result.success) {
        const cards = _.get(result, 'response.data', [])
        this.setState({
          card: cards.length ? cards[0] : {},
          render: true,
        });
      } else {
        this.setState({ error: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.add_card !== add_card) {
      const { result } = add_card;
      if (result.success) {
        const card = _.get(result, 'response.data', {})
        this.setState({
          card: !_.isEmpty(card) ? card : {},
        });
      } else {
        this.setState({ error: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.remove_card !== remove_card) {
      const { result } = remove_card;
      if (result.success) {
        this.setState({
          card: {},
          showConfirmRemoveCardModal: false,
        });
      } else {
        this.setState({ showConfirmRemoveCardModal: false, error: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.charge_card !== charge_card) {
      const { result } = charge_card;
      if (result.success) {
        storeNotificationRequest(MSG_PAID_SUCCESS);
        history.push(_ROUTES.PATH_HOME);
      } else {
        this.setState({ showConfirmPaymentModal: false, error: _.get(result, 'response.message', '') });
      }
    }
  }

  _formatAmount = (value) => {
    if (_.isNumber(value)) value /= 100;
    try {
      return `${_CONST.CURRENCY_SIGN}${value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`;
    } catch {
      return value;
    }
  }

  _handleCourseSelection = (selected_course_uuid) => {
    this.setState({ selected_course_uuid });
  }

  _handleToken = (stripe_token) => {
    this.setState({ stripe_token }, () => {
      this.props.addCardRequest({ token: stripe_token });
    });
  }

  _handleCharge = () => {
    this.setState({ showConfirmPaymentModal: true });
  }

  _handlePaymentModalSubmit = () => {
    const { selected_course_uuid, card } = this.state;
    if (!_.isEmpty(selected_course_uuid) && !_.isEmpty(card)) {
      this.props.chargeCardRequest({
        lesson_package_uuid: selected_course_uuid,
        stripe_card_uuid: card.uuid,
      });
    }
  }

  _handleRemoveCard = () => {
    this.setState({ showConfirmRemoveCardModal: true });
  }

  _handleRemoveCardModalSubmit = () => {
    const { card } = this.state;
    if (!_.isEmpty(card)) {
      this.props.removeCardRequest({
        card_id: card.uuid,
      });
    }
  }

  render() {
    const { user_type } = this.props;
    const {
      courses, card, selected_course_uuid, render, error,
      showConfirmPaymentModal, showConfirmRemoveCardModal,
    } = this.state;

    return (
      <div className="course-section">
        <div className="container">
          {user_type === 'student' && <WalletNotification />}
          {!_.isEmpty(error) && <section className="section pb0 pt10"><div className="notification">{error}</div></section>}

          {render && <section className="section">
            <div className="container">
              <h2 className="title">Get Course</h2>
              <h2 className="subtitle">Select Packages</h2>
              <div className="columns">
                {_.map(courses, (course, key) => {
                  return (<div className="column" key={key}>
                    <div className="card">
                      <header className="card-header">
                        <p className="card-header-title">{_.get(course, 'name', '')}</p>
                      </header>
                      <div className="card-content">
                        <div className="content">
                          {_.get(course, 'description', '')}
                          <br />
                          {this._formatAmount(_.get(course, 'price.amount', 0))}
                        </div>
                      </div>
                      <footer className="card-footer">
                        <button
                          disabled={course.uuid === selected_course_uuid}
                          className={`button ${course.uuid === selected_course_uuid ? `is-success` : `is-light`} card-footer-item justify-left`}
                          onClick={() => this._handleCourseSelection(course.uuid)}>Select</button>
                      </footer>
                    </div>
                  </div>)
                })}
              </div>

              {(_.isEmpty(card) && !_.isEmpty(selected_course_uuid)) && <div className="card">
                <header className="card-header">
                  <p className="card-header-title">Add New Credit Card</p>
                </header>
                <div className="card-content">
                  <div className="content">
                    <Suspense fallback={'Loading...'}>
                      <StripeForm handleToken={this._handleToken} />
                    </Suspense>
                  </div>
                </div>
              </div>}

              {(!_.isEmpty(card) && !_.isEmpty(selected_course_uuid)) && <div className="buttons">
                <button className="button is-success" onClick={this._handleCharge}>Pay Now with Credit Card •••• {card.last4}</button>
                <button className="button is-danger" onClick={this._handleRemoveCard}>Remove Card</button>
              </div>}
            </div>
          </section>}
        </div>

        <ConfirmModal
          title="Card Payment Confirmation"
          message="Are you sure want to proceed with this card payment?"
          visible={showConfirmPaymentModal}
          submit={this._handlePaymentModalSubmit}
          cancel={() => this.setState({ showConfirmPaymentModal: false })}
        />

        <ConfirmModal
          title="Remove Card Confirmation"
          message="Are you sure want to remove this card?"
          visible={showConfirmRemoveCardModal}
          submit={this._handleRemoveCardModalSubmit}
          cancel={() => this.setState({ showConfirmRemoveCardModal: false })}
        />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    all_courses: _.get(state, "course.all_courses", {}),
    all_cards: _.get(state, "course.all_cards", {}),
    add_card: _.get(state, "course.add_card", {}),
    remove_card: _.get(state, "course.remove_card", {}),
    charge_card: _.get(state, "course.charge_card", {}),
    user_type: _.get(state, "auth.login.result.response.role", ""),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllCoursesRequest: () => dispatch({ type: reduxActions.GET_ALL_COURSE_REQUEST }),
  getAllCardsRequest: () => dispatch({ type: reduxActions.GET_ALL_CARDS_REQUEST }),
  addCardRequest: payload => dispatch({ type: reduxActions.ADD_CARD_REQUEST, payload }),
  removeCardRequest: payload => dispatch({ type: reduxActions.REMOVE_CARD_REQUEST, payload }),
  chargeCardRequest: payload => dispatch({ type: reduxActions.CHARGE_CARD_REQUEST, payload }),
  storeNotificationRequest: payload => dispatch({ type: reduxActions.STORE_NOTIFICATION_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Course);
