import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  userLogin,
  userLogout,
} from "../../services/auth.service";
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* userLoginWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(userLogin, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.USER_LOGIN_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.USER_LOGIN_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* userLogoutWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(userLogout, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.USER_LOGOUT_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.USER_LOGOUT_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* storeAutoLoginDataWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    yield put({
      type: reduxActions.STORE_AUTO_LOGIN_DATA_SUCCESS,
      payload: action.payload,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.STORE_AUTO_LOGIN_DATA_FAILURE,
      payload: {},
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

/* WATCHERS SAGA */

// Watcher for user login
export function* userLoginWatcher() {
  yield takeLatest(reduxActions.USER_LOGIN_REQUEST, userLoginWorker);
}

// Watcher for user logout
export function* userLogoutWatcher() {
  yield takeLatest(reduxActions.USER_LOGOUT_REQUEST, userLogoutWorker);
}

// Watcher for store user auto login data
export function* storeAutoLoginDataWatcher() {
  yield takeLatest(
    reduxActions.STORE_AUTO_LOGIN_DATA_REQUEST,
    storeAutoLoginDataWorker
  );
}

export default function* rootSaga() {
  yield all([
    fork(userLoginWatcher),
    fork(userLogoutWatcher),
    fork(storeAutoLoginDataWatcher),
  ]);
}
