import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

// get twilio token
const twilio_token = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_TWILIO_TOKEN_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_TWILIO_TOKEN_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// get class room slides
const class_slides = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_CLASS_ROOM_SLIDES_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_CLASS_ROOM_SLIDES_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// get class room slides
const class_videos = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_CLASS_ROOM_VIDEOS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_CLASS_ROOM_VIDEOS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// get class room slider slides
const slider_slides = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_CLASS_ROOM_SLIDER_SLIDES_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_CLASS_ROOM_SLIDER_SLIDES_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// get class room session details
const class_session = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_CLASS_ROOM_SESSION_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_CLASS_ROOM_SESSION_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// Get all classroom session data by teacher ID
const get_all_sessions_by_teacher = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_ROOM_SESSIONS_SUCCESS_BY_TEACHER:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_ROOM_SESSIONS_FAILURE_BY_TEACHER:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// get class room slide drawing
const class_drawing = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_CLASS_ROOM_DRAWING_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_CLASS_ROOM_DRAWING_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// save class room video
const save_video = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.SAVE_CLASS_ROOM_VIDEO_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.SAVE_CLASS_ROOM_VIDEO_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// save class room slide drawing
const save_drawing = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.SAVE_CLASS_ROOM_DRAWING_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.SAVE_CLASS_ROOM_DRAWING_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// remove class room slide drawing
const remove_drawing = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.REMOVE_CLASS_ROOM_DRAWING_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.REMOVE_CLASS_ROOM_DRAWING_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// list classroom sessions
const all_classes = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_CLASS_ROOM_SESSION_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_CLASS_ROOM_SESSION_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// save classroom report
const save_report = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.SAVE_CLASS_ROOM_REPORT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.SAVE_CLASS_ROOM_REPORT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// get classroom report
const get_report = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_CLASS_ROOM_REPORT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_CLASS_ROOM_REPORT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// get all pets
const all_pets = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_PETS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_PETS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// get a pet
const get_pet = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_A_PET_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_A_PET_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// get wallet detail
const wallet = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_WALLET_DETAIL_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_WALLET_DETAIL_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const call_parents = (state = {}, action) => {
  switch(action.type){
    case reduxActions.CALL_CLASS_STUDENT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.CALL_CLASS_STUDENT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const update_parents_suggestions = (state = {}, action) => {
  switch(action.type){
    case reduxActions.PARENTS_SUGGESTIONS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.PARENTS_SUGGESTIONS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const restrict_text_for_student = (state = {}, action) => {
  switch(action.type){
    case reduxActions.RESTRICT_TEXT_FOR_STUDENT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.RESTRICT_TEXT_FOR_STUDENT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}


export default combineReducers({
  get_all_sessions_by_teacher,
  twilio_token,
  class_slides,
  class_videos,
  slider_slides,
  class_session,
  class_drawing,
  save_drawing,
  remove_drawing,
  all_classes,
  save_report,
  get_report,
  all_pets,
  get_pet,
  wallet,
  call_parents,
  update_parents_suggestions,
  restrict_text_for_student,
  save_video,
});
