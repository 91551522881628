import React, { useState, useEffect } from "react";
import Video from "twilio-video";
import Participant from "./Participant";
import { first } from "lodash";
import { useSelector } from 'react-redux'
import * as Toastr from 'toastr';
import io from "socket.io-client";
import { API_BASE_URL } from "../../../constants/Config";
import * as reduxActions from "../../../constants/ActionTypes";
import { useDispatch } from "react-redux";

const socketReference = React.createRef();

const VideoStream = ({ roomName, token, handleLogout, class_id, isScreenSharedByTeacher, setIsScreenSharedByTeacher, isScreenSharingAllowed }) => {
  const dispatch = useDispatch();
  const user_type = useSelector((state) => state.auth.login.result.response.role);
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    Video.connect(token, {
      name: roomName,
    }).then((room) => {
      setRoom(room);
      if (user_type === "teacher") {
        socketReference.current.emit("screen_sharing", { class_id: class_id, isScreenSharing: false });
      }
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  useEffect(() => {
    try {
      socketReference.current = io.connect(API_BASE_URL, {
        transports: ["websocket"],
        secure: true,
      });
    } catch (err) {
      console.log(err)
    }
  }, []);

  const remoteParticipants = participants.map((participant) => (
    <Participant key={participant.sid} participant={participant} />
  ));

  const startShareScreen = () => {
    setIsScreenSharedByTeacher(true);
    navigator.mediaDevices.getDisplayMedia({ video: true, selfBrowserSurface: "include" }).then(stream => {
      const newScreenTrack = first(stream.getVideoTracks());
      room.localParticipant.videoTracks.forEach((t) => {
        t.unpublish()
      });
      room.localParticipant.publishTrack(newScreenTrack);
      socketReference.current.emit("screen_sharing", { class_id: class_id, isScreenSharing: true });
      dispatch({
        type: reduxActions.SCREEN_SHARED_BY_TEACHER_REQUEST, payload: {
          id: class_id,
          screen_shared_by_teacher: true
        }
      })
      newScreenTrack.onended = function () {
        stopShareScreen();
      }
    }).catch(() => {
      setIsScreenSharedByTeacher(false);
      socketReference.current.emit("screen_sharing", { class_id: class_id, isScreenSharing: false });
      dispatch({
        type: reduxActions.SCREEN_SHARED_BY_TEACHER_REQUEST, payload: {
          id: class_id,
          screen_shared_by_teacher: false
        }
      })
      Toastr.error("Could not share the screen.")
    });
  }

  const stopShareScreen = async () => {
    try {
      const localTrack = await Video.createLocalVideoTrack()
      setIsScreenSharedByTeacher(false);
      room.localParticipant.videoTracks.forEach((t) => {
        t.unpublish()
      });
      room.localParticipant.publishTrack(localTrack);
      socketReference.current.emit("screen_sharing", { class_id: class_id, isScreenSharing: false });
      dispatch({
        type: reduxActions.SCREEN_SHARED_BY_TEACHER_REQUEST, payload: {
          id: class_id,
          screen_shared_by_teacher: false
        }
      })
    }
    catch (error) { Toastr.error(error.message) }
  }

  return (
    <div className="room">
      <div className="streams">

        {remoteParticipants.length > 0 && (
          <div className={isScreenSharedByTeacher && user_type === "student" ? "shared-screen-box" : "video-box"}>
            <div className="participants">{remoteParticipants}</div>
          </div>
        )}

        {isScreenSharedByTeacher && user_type === "student" ? null :

          <div className="video-box mt3">
            {room && (
              <Participant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
              />
            )}
          </div>}

        {
          user_type === "teacher" && isScreenSharingAllowed ?
            <button className="tag share-screen" onClick={() => !isScreenSharedByTeacher ? startShareScreen() : stopShareScreen()}>
              {!isScreenSharedByTeacher ? "SHARE SCREEN" : "STOP SHARING"}
            </button> : null
        }
      </div>
    </div>
  );
};

export default VideoStream;
