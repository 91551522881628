import { API } from './API';

// user registration
export function userRegistration(data) {
  return new Promise((resolve, reject) => {
    return API('POST', `auth/register/student`, data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

//get all students
export function getAllStudents() {
  return new Promise((resolve, reject) => {
    return API('GET', `teacher/students/all`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

