import React from 'react';
import _ from 'lodash';
import { APP_USER_TYPE } from "../../../constants/Config";
import LogoImg from '../../../assets/images/starkid_logo.png';

import '../LoginStyle.css';

let EL_HTML = null;

class LoginForm extends React.Component {
  componentDidMount() {
    try {
        EL_HTML = document.getElementsByTagName("html")[0];
        EL_HTML.classList.add("bg-page");
    } catch { }
  }

  componentWillUnmount() {
    try {
        EL_HTML.classList.remove("bg-page");
    } catch { }
  }

  render() {
    const {
      data, errors, loginResp, showErrorBlock, hideErrorBlock, loading,
      handleChange, handleLogin, handleSignup
    } = this.props;

    return (
      <div>
        <div className="container login-box">
          <div>
            <div className="logo">
              <img src={LogoImg} alt="StarKid Logo" />
            </div>

            {(!_.isEmpty(loginResp) && showErrorBlock) && <div className="notification is-danger">
              <button className="delete" onClick={hideErrorBlock}></button>
              {loginResp}
            </div>}

            <form onSubmit={handleLogin} noValidate>
              <div className="field">
                <div className="control">
                  <input
                    type="email"
                    className={`input ${!_.isEmpty(_.get(errors, 'email', '')) ? `is-danger` : ``}`}
                    placeholder="Please enter your email"
                    value={data.email}
                    onChange={({ target: { value } }) => handleChange({ email: value })}
                  />
                </div>
                <p className="help is-danger ml15">{_.get(errors, 'email', '')}</p>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    type="password"
                    className={`input ${!_.isEmpty(_.get(errors, 'password', '')) ? `is-danger` : ``}`}
                    placeholder="Please enter your password"
                    value={data.password}
                    onChange={({ target: { value } }) => handleChange({ password: value })}
                  />
                </div>
                <p className="help is-danger ml15">{_.get(errors, 'password', '')}</p>
              </div>
              <div className="field">
                <button className={`button btn-login-signup is-info is-fullwidth ${loading ? `is-loading` : ``}`} type="submit">Login</button>
              </div>
              {APP_USER_TYPE === 'student' && <div className="field signup-link-container">
                If you do not have an account? <a className="a-link" onClick={handleSignup}>Sign up</a> for free here
              </div>}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginForm;
