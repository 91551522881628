import React, { Component } from "react";

class TimerClock extends Component {
  render() {
    return (
      <div className="timer">
        <div className="timer-time">
          {this.props.hours ? this.props.hours : "00"} :{" "}
          {this.props.minutes ? this.props.minutes : "00"} :{" "}
          {this.props.seconds ? this.props.seconds : "00"}
        </div>
        &nbsp;&nbsp;
        <div className="timer-button-container">
          {!this.props.timerStatus && (
            <button
              className="button is-primary start-button"
              onClick={() => this.props.handleTimerStart()}
            >
              Start
            </button>
          )}
          {this.props.timerStatus && (
            <button
              className="button is-primary stop-button"
              onClick={() => this.props.handleTimerStop()}
            >
              Stop
            </button>
          )}
          &nbsp;&nbsp;
          <button
            className="button is-primary button-color reset-button"
            onClick={() => this.props.handleTimerReset()}
          >
            Reset
          </button>
        </div>
      </div>
    );
  }
}

export default TimerClock;