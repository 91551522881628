import React, { Component } from 'react';
import noVideo from '../../../assets/images/no-video-recording.png';
import screenShot from '../../../assets/images/screenshot.png';

class MediaNote extends Component {
    render() {
        return (
            <div className='mediaNote'>
                <p className='headNote'>Please allow camera &amp; microphone access in Chrome</p>
                <hr />
                <ol>
                    <li><p>點擊 Chrome網址欄右上角的  <img className="image is-16x16" src={noVideo} alt="noVideo" /> ，如下圖：</p></li>
                    <img src={screenShot} alt={screenShot} />
                    <li>然後選擇允許 (Always allow) 再按 “Done” 確認</li>
                    <li>現在按一下刷新（Refresh）↻ 頁面，即可上課</li>
                </ol>
                <hr />
                
            </div>
        );
    }
}

export default MediaNote;