import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./appRedux/store";
import { _ROUTES } from "./constants/GlobalConst";
import PrivateRoute from "./components/hoc/PrivateRoute";
import App from "./App";
import Login from "./Routes/Login";
import Signup from "./Routes/Signup";
import Logout from "./Routes/Logout";
import { NODE_ENV } from "./constants/Config";
import { SENTRY_DSN } from "./constants/Config";
import { VERSION } from "./constants/Config";

import "./assets/css/site.css";

if (typeof SENTRY_DSN === "string") {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: NODE_ENV,
    release: VERSION,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path={_ROUTES.PATH_LOGIN} component={Login} />
          <Route exact path={_ROUTES.PATH_REGISTRATION} component={Signup} />
          <Route exact path={_ROUTES.PATH_LOGOUT} component={Logout} />
          <PrivateRoute path="/" component={App} />
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
