import { API } from './API';

// get all courses
export function getAllCourse() {
  return new Promise((resolve, reject) => {
    return API('GET', `lesson/packages`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// get all cards
export function getAllCard() {
  return new Promise((resolve, reject) => {
    return API('GET', `student/cards`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// add new card
export function addCard(data) {
  return new Promise((resolve, reject) => {
    return API('POST', `student/card`, data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}


// charge card
export function chargeCard(data) {
  return new Promise((resolve, reject) => {
    return API('POST', `lesson/package/charge`, data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// remove card
export function removeCard(data) {
  return new Promise((resolve, reject) => {
    return API('DELETE', `student/card/${data.card_id}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}