import axios from 'axios';
import _ from 'lodash';
import { store } from "../appRedux/store";
import { API_BASE_URL } from "../constants/Config";
import { _ROUTES } from '../constants/GlobalConst';

const ERR_MSG_SOMETHING_WENT_WRONG = 'Something went wrong, please try again later';
const DEFAULT_HEADER = { 'Content-Type': 'application/json' };

export default function API(method, endpoint, payload, headers = DEFAULT_HEADER) {
  const _store = store.getState();

  let params = {
    method,
    url: endpoint,
    data: payload,
  };

  // Create an Axios Client with defaults
  let client = axios.create({
    baseURL: `${API_BASE_URL}/api`,
    headers,
  });

  client.interceptors.request.use(function (config) {
    const token = _.get(_store, 'auth.login.result.response.token', '');
    if (!_.isEmpty(token)) {
      config.headers.common['Authorization'] = `Bearer ${token}`
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  client.interceptors.response.use((response) => {
    return response;
  },
    function (error) {
      return Promise.reject(error);
    });

  return client(params)
    .then((response) => {
      if (response.status === 200) {
        return {
          message: 'Success',
          success: true,
          response: response.data,
        };
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        // Unauthorized access or token is expired
        localStorage.removeItem('auth');
        window.location = _ROUTES.PATH_LOGIN;
      } else {
        return {
          success: false,
          message: ERR_MSG_SOMETHING_WENT_WRONG,
          response: error.response.data,
        };
      }
    });
}

export {
  API
}
