import React from "react";
import { connect } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import ProfileImg from "../../assets/images/profile_image.png";
import Popup from "../../components/PopUp/EventPopUp";
import moment from "moment";
import * as reduxActions from "../../constants/ActionTypes";
import ConfirmModal from "../../components/layout/ConfirmModal";
import DatePicker from "react-datepicker";
import { AiOutlineBook, AiOutlineClose, AiOutlineExclamationCircle } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.min.css";
import "./calendar.css";
import _ from "lodash";
let studentList = [],
  viewState = "";
class UserCalendar extends React.Component {
  showModal = (e) => {
    this.setState({
      show: !this.state.show,
    });
  };
  getRightContainerClass = (title, remark, sub_title) => {
    let classes = "";

    if (title === "Available") {
      classes = "event-card-available";
    } else if (sub_title === "Regular") {
      classes = "event-card-reserved";
    } else if (sub_title === "Trial") {
      classes = "event-card-trial";
    } else if (sub_title === "Special") {
      classes = "event-card-special";
    } else {
      classes = "event-card-scheduled";
    }

    if (Boolean(remark) === true) {
      classes += " event-note";
    }

    return classes;
  };
  closeUpdate = (e) => {
    this.setState({
      show: !this.state.show,
      updateMode: false,
    });
  };
  handleChange = (value) => {
    this.setState({ available: value });
  };
  _onAdd = () => {
    const { addUserEvents } = this.props;
    const payload = {
      title: "Available",
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      remarks: "",
    };
    if (this.state.selectedStudent) {
      payload.student_uuid = this.state.selectedStudent.uuid;
    }
    addUserEvents(payload);
  };
  _onUpdate = () => {
    const { updateEventRequest } = this.props;
    let payload = {
      title: this.state.available ? "Available" : "Reserved",
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      remarks: this.state.remarks,
      uuid: this.state.eventID,
    };
    if (this.state.selectedStudent) {
      payload.student_uuid = this.state.selectedStudent.uuid;
    }
    updateEventRequest(payload);
  };
  OnupdateEvent = (value) => {
    const { updateEventRequest } = this.props;

    let payload = {
      start_date: new Date(value.event.start),
      end_date: new Date(value.event.end),
      uuid: value.event.extendedProps.uuid,
    };
    updateEventRequest(payload);
  };
  onDeleteEvent = () => {
    const { removeEventRequest } = this.props;
    removeEventRequest(this.state.eventID);
    this.setState({
      eventID: "",
      deleteMode: false,
      show: false,
    });
  };
  _handleRemoveEventModalSubmit = () => {
    this.props.removeEventRequest({
      event_id: this.state.eventID,
    });
  };
  constructor(props) {
    super(props);
    const { user } = this.props;

    this.state = {
      events: [],
      eventID: "",
      showConfirmRemoveEventModal: false,
      show: false,
      available: false,
      remarks: "",
      startDate: "",
      endDate: "",
      updateMode: false,
      students: [],
      selectedStudent: {},
      view: "",
      dayView: false,
      currentUserId: { id: user.uuid },
    };
    this.spanRef = React.createRef();
  }
  async componentDidMount() {
    const {
      getAllUserEvents,
      getAllStudentRequest,
      getAllRoomSessionsByTeacher,
    } = this.props;
    getAllUserEvents();
    getAllStudentRequest();
    getAllRoomSessionsByTeacher(this.state.currentUserId);
  }
  componentDidUpdate(prevProps) {
    const {
      event,
      remove_event,
      add_event,
      getAllUserEvents,
      update_event,
      students,
      get_all_sessions_by_teacher,
    } = this.props;

    /*
     * Fetch all classroom sessions based on teacher selection
     */

    if (prevProps.get_all_sessions_by_teacher !== get_all_sessions_by_teacher) {
      const { result } = get_all_sessions_by_teacher;
      let allPrevListEvents = this.state.events;

      if (Boolean(result)) {
        let resultedData = _.reverse(
          _.sortBy(_.get(result, "response.data", []))
        );

        let mergedData = [];

        if (resultedData.length > 0) {
          if (allPrevListEvents.length > 0) {
            mergedData[0] = allPrevListEvents[0].concat(resultedData);
          } else {
            mergedData[0] = resultedData;
          }
        } else {
          mergedData = allPrevListEvents;
        }
        this.setState({
          events: mergedData,
        });
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.event !== event) {
      const { result } = event;
      if (result.success) {
        this.setState({
          events: _.reverse(_.sortBy(_.get(result, "response.data", []))),
          view: "",
        });
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }
    if (prevProps.remove_event !== remove_event) {
      const { result } = remove_event;
      if (result.success) {
        this.setState({
          eventID: "",
          showConfirmRemoveEventModal: false,
        });
        getAllUserEvents();
      } else {
        this.setState({
          showConfirmRemoveEventModal: false,
          error: _.get(result, "response.message", ""),
        });
      }
    }
    if (prevProps.add_event !== add_event) {
      const { result } = add_event;
      if (result.success) {
        getAllUserEvents();
        this.setState({ show: false });
      } else {
        this.setState({
          show: false,
          error: _.get(result, "response.message", ""),
        });
      }
    }
    if (prevProps.update_event !== update_event) {
      const { result } = update_event;
      if (result.success) {
        if (this.state.updateMode) {
          this.setState({ updateMode: false });
          getAllUserEvents();
          this.showModal();
        } else {
          getAllUserEvents();
        }
      } else {
        this.setState({
          show: false,
          error: _.get(result, "response.message", ""),
        });
      }
    }
    if (prevProps.students !== students) {
      const { result } = students;
      if (result.success) {
        this.setState({ students: result.response.data });
        studentList = result.response.data.map((student) => {
          return (
            <option
              value={`${student.profile.fullname} (${student.profile.nickname})`}
            >
              {" "}
            </option>
          );
        });
      } else {
        this.setState({
          show: false,
          error: _.get(result, "response.message", ""),
        });
      }
    }
  }
  populateStudentOrTeacherName = (props, title) => {
    if (Boolean(props.account) === true && title === "Reserved") {
      return props.account.profile.fullname;
    } else if (Boolean(props.teacher_account)) {
      return props.teacher_account.profile.fullname;
    }
  };
  removeButton = (args, element) => {
    return (
      <div
        data-id={args.event.extendedProps.uuid}
        className={this.getRightContainerClass(
          args.event.title,
          args.event.extendedProps.remarks,
          args.event.extendedProps.sub_title
        )}
      >
        <div
          className="event_inner_detail tooltip"
          onClick={() => {
            if (args.event.title === "Available") {
              this.updateDate(args.event);
            }
          }}
        >
          <div className="text-container">
            <p>
            {this.populateStudentOrTeacherName(
              args.event.extendedProps,
              args.event.title
            )}
            </p>
            {Boolean(args.event.extendedProps.remarks) === true ? 
            <AiOutlineExclamationCircle fontSize={22} color={'#ffdd57'} fontWeight={700} /> : null} 
          </div>
          <p>
            {`(${moment(args.event.start).format("hh:mm")} - ${
              moment(args.event.start).format("YY-MM-DD") ==
              moment(args.event.end).format("YY-MM-DD")
                ? moment(args.event.end).format("hh:mm")
                : "12:00"
            })`}
          </p>
          {Boolean(args.event.extendedProps.remarks) === true ? (
            <p className="note-text" title={args.event.extendedProps.remarks}>
              {args.event.extendedProps.remarks}
            </p>
          ) : null}
          <span className="tooltiptext">
            <p>
              {this.populateStudentOrTeacherName(
                args.event.extendedProps,
                args.event.title
              )}
            </p>
            <p>
              {`(${moment(args.event.start).format("hh:mm")} - ${
                moment(args.event.start).format("YY-MM-DD") ==
                moment(args.event.end).format("YY-MM-DD")
                  ? moment(args.event.end).format("hh:mm")
                  : "12:00"
              })`}
            </p>
            {Boolean(args.event.extendedProps.remarks) === true ? (
              <p title={args.event.extendedProps.remarks}>
                {args.event.extendedProps.remarks.length < 50 ? 
                    args.event.extendedProps.remarks : 
                      args.event.extendedProps.remarks.slice(0, 50) + '...'}
              </p>
            ) : null}
          </span>
        </div>
      </div>
    );
  };
  render() {
    const { user } = this.props;
    return (
      <div className="container">
        <section className="section section-student-header-bar">
          <div className="level">
            <div className="level-left student-header-bar">
              <div className="level-item justify-left">
                <img className="profile-image" src={ProfileImg} alt="Profile" />
                <div>
                  {`${_.get(user, "profile.fullname", "")}'s`} {this.state.view}{" "}
                  schedule
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="calendar-font"
          style={{
            marginTop: "2%",
            padding: "15px 25px 10px 25px",
            backgroundColor: "white",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <FullCalendar
            ref={this.spanRef}
            eventContent={this.removeButton}
            plugins={[
              dayGridPlugin,
              interactionPlugin,
              timeGridPlugin,
              listPlugin,
            ]}
            height={600}
            initialView={"timeGridWeek"}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            buttonText={{
              today: "Today",
              month: "Month",
              week: "Week",
              day: "Day",
            }}
            selectable={true}
            selectMirror={true}
            events={this.state.events[0]}
            editable={true}
            eventDurationEditable={false}
            eventResizableFromStart={false}
            select={this.selectedDate}
            eventDrop={this.OnupdateEvent}
            eventResize={this.OnupdateEvent}
            eventColor={"#ffff"}
            slotDuration={"00:15:00"}
            scrollTime={"09:00:00"}
            slotMinTime={"09:00:00"}
            slotMaxTime={"24:00:00"}
          />
          <div>
            <Popup
              onClose={
                this.state.updateMode ? this.closeUpdate : this.showModal
              }
              show={this.state.show}
              onAdd={this._onAdd}
              updateMode={this.state.updateMode}
              onUpdate={this._onUpdate}
            >
              <div>
                <div>
                  <div>
                    <b>Start Date</b>
                    <div
                      className="sk-datepicker"
                      style={{ textAlign: "center" }}
                    >
                      <DatePicker
                        className="input"
                        id="startDatePicker"
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        selected={this.state.startDate}
                        onChange={(date) => this.setState({ startDate: date })}
                        timeIntervals={5}
                        dateFormat="Pp"
                      ></DatePicker>
                    </div>
                  </div>
                </div>
                <br />
                <div>
                  <div>
                    <b>End Date</b>
                    <div
                      className=" sk-datepicker"
                      style={{ textAlign: "center" }}
                    >
                      <DatePicker
                        className="input"
                        id="endDatePicker"
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        selected={this.state.endDate}
                        onChange={(date) => this.setState({ endDate: date })}
                        dateFormat="Pp"
                      ></DatePicker>
                    </div>
                  </div>
                </div>
                <br />

                <br />
              </div>
            </Popup>
          </div>
          <ConfirmModal
            title="Remove Event Confirmation"
            message="Are you sure want to remove this event?"
            visible={this.state.showConfirmRemoveEventModal}
            submit={this._handleRemoveEventModalSubmit}
            cancel={() => this.setState({ showConfirmRemoveEventModal: false })}
          />
        </div>
      </div>
    );
  }

  getCalendrType = () => {
    if (this.spanRef.current) {
      let calApi = this.spanRef.current.getApi();
      log;
      return calApi.currentDataManager.data.currentDataManager;
    }
  };

  setv = (info) => {
    if (this.spanRef.current) {
      this.setState({
        view: this.spanRef.current.getApi().currentDataManager.data
          .currentDataManager,
      });
    }
  };

  setStudent = (name) => {
    let index = this.state.students.findIndex(
      (student) =>
        student.profile.fullname === name.split("(")[0].trim() &&
        student.profile.nickname === name.split("(")[1].trim().replace(")", "")
    );
    this.setState({ selectedStudent: this.state.students[index] });
  };
  deleteEvent = (uuid) => {
    this.setState({
      showConfirmRemoveEventModal: true,
      eventID: uuid,
    });
  };

  updateDate = (event) => {
    this.setState({
      eventID: event.extendedProps.uuid,
      updateMode: true,
      startDate: event.start,
      endDate: event.end,
      available: event.title == "Available" ? true : false,
      remarks: event.extendedProps.remarks,
    });
    this.showModal();
    this.setStudent(
      `${event.extendedProps.account.profile.fullname} (${event.extendedProps.account.profile.nickname})`
    );
  };

  selectedDate = (arg) => {
    let momentStartDate = moment(arg.startStr);
    let momentEndDate = moment(arg.endStr);
    let diffrence = momentEndDate.diff(momentStartDate, "minutes");
    if (diffrence < 30) {
      momentEndDate = moment(arg.endStr).add(30 - diffrence, "minutes");
    }
    this.setState({
      startDate: new Date(moment(arg.startStr)),
      endDate:
        moment(momentEndDate).format("hh:mm a") == "12:00 am"
          ? new Date(moment(momentEndDate).subtract(1, "minute"))
          : new Date(moment(momentEndDate)),
    });
    this.showModal();
  };
}

const mapStateToProps = (state) => {
  return {
    event: _.get(state, "event.user_event", {}),
    remove_event: _.get(state, "event.remove_event", {}),
    add_event: _.get(state, "event.add_event", {}),
    update_event: _.get(state, "event.update_event", {}),
    students: _.get(state, "user.students", {}),
    user: _.get(state, "auth.login.result.response.data", {}),
    get_all_sessions_by_teacher: _.get(
      state,
      "room.get_all_sessions_by_teacher",
      {}
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllUserEvents: () =>
    dispatch({ type: reduxActions.GET_ALL_USER_EVENT_REQUEST }),
  addUserEvents: (payload) =>
    dispatch({ type: reduxActions.ADD_EVENT_REQUEST, payload }),
  removeEventRequest: (payload) =>
    dispatch({ type: reduxActions.REMOVE_EVENT_REQUEST, payload }),
  updateEventRequest: (payload) =>
    dispatch({ type: reduxActions.UPDATE_EVENT_REQUEST, payload }),
  getAllStudentRequest: () =>
    dispatch({ type: reduxActions.GET_ALL_STUDENT_REQUEST }),
  getAllRoomSessionsByTeacher: (payload) =>
    dispatch({
      type: reduxActions.GET_ALL_ROOM_SESSIONS_REQUEST_BY_TEACHER,
      payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCalendar);
