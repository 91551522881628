import { all } from "redux-saga/effects";
import globalSagas from "./global.saga";
import authSagas from "./auth.saga";
import roomSagas from "./classroom.saga";
import courseSagas from "./course.saga";
import userSagas from "./user.saga";
import eventSagas from "./event.saga"

export default function* rootSaga(getState) {
  yield all([
    globalSagas(),
    authSagas(),
    roomSagas(),
    courseSagas(),
    userSagas(),
    eventSagas()
  ]);
}
