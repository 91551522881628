/* eslint-disable-next-line */
export const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export const generateStudentGrade = (strenth_and_weakness) => {

    // Calculate grade
    let gradesTotal = 0;

    strenth_and_weakness.map((item) => {
        gradesTotal += parseInt(Object.values(item)[0]);
    });

    let studentAverage = gradesTotal/5;
    let studentGrade = '';

    if(studentAverage >= 97 && studentAverage <=100){
        studentGrade = 'A+';
      }
      else if(studentAverage >= 93 && studentAverage <=96){
        studentGrade = 'A';
      }
      else if(studentAverage >= 90 && studentAverage <=92){
        studentGrade = 'A-';
      }
      else if(studentAverage >= 87 && studentAverage <=89){
        studentGrade = 'B+';
      }
      else if(studentAverage >= 83 && studentAverage <=86){
        studentGrade = 'B';
      }
      else if(studentAverage >= 80 && studentAverage <=82){
        studentGrade = 'B-';
      }
      else if(studentAverage >=77 && studentAverage <=79){
        studentGrade = 'C+';
      }
      else if(studentAverage >= 73 && studentAverage <=76){
        studentGrade = 'C';
      }
      else if(studentAverage >= 70 && studentAverage <=72){
        studentGrade = 'C-';
      }
      else if(studentAverage >= 67 && studentAverage <=69){
        studentGrade = 'D+';
      }
      else if(studentAverage >= 63 && studentAverage <=66){
        studentGrade = 'D';
      }
      else if(studentAverage >= 60 && studentAverage <=62){
        studentGrade = 'D-';
      }
      else if(studentAverage >= 0 && studentAverage <=59){
        studentGrade = 'F';
      }

      return { grade: studentGrade, average: studentAverage };
}
