import React, { Component } from 'react';

export default class Popup extends Component {
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };
    onAdd = e => {
        this.props.onAdd && this.props.onAdd(e);
    }
    onUpdate = e => {
        this.props.onUpdate && this.props.onUpdate(e);
    }
    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div>
                <div className="jw-modal">
                    <div className="jw-modal-body">
                        {this.props.children}
                        <div>
                            <button className="toggle-button" onClick={this.onClose} style={{ marginLeft: '5%' }}>
                                Cancel
                            </button>

                            {!this.props.updateMode && <button className="toggle-button" onClick={this.onAdd} style={{ marginLeft: '5%' }} >
                                Add
                            </button>}
                            {this.props.updateMode && <button className="toggle-button" onClick={this.onUpdate} style={{ marginLeft: '5%' }} >
                                Update
                            </button>}
                        </div>
                    </div >
                </div >
                <div className="jw-modal-background"></div>
            </div >
        );
    }
}
