import React from 'react';

const ConfirmModal = ({ title, message, visible, submit, cancel }) => {
  return (
    <div className={`modal ${visible ? `display-block` : `display-hide`}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" aria-label="close" onClick={cancel}></button>
        </header>
        <section className="modal-card-body">
          {message}
        </section>
        <footer className="modal-card-foot">
          <button className="button is-primary" onClick={submit}>Confirm</button>
          <button className="button" onClick={cancel}>Cancel</button>
        </footer>
      </div>
    </div>
  );
}

export default ConfirmModal;
