import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import * as reduxActions from "../../constants/ActionTypes";

class Notification extends React.Component {

  hideNotification = () => {
    this.props.storeNotificationRequest('');
  }

  render() {
    const { notification, type, allowDelete } = this.props;
    return (
      <section className="section pb0 pt0">
        {!_.isEmpty(notification) && <div className={`notification ${type || ''}`}>
          {allowDelete && <button className="delete" onClick={this.hideNotification}></button>}
          <div dangerouslySetInnerHTML={{ __html: notification }}></div>
        </div>}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notification: _.get(state, "global.notification.message", ''),
  };
};

const mapDispatchToProps = (dispatch) => ({
  storeNotificationRequest: payload => dispatch({ type: reduxActions.STORE_NOTIFICATION_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
