import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";
import { ACKNOWLEDGE, ALL, REPORT, zoomRange, _CONST, _PET_IMAGES, _ROUTES } from "../../constants/GlobalConst";
import * as reduxActions from "../../constants/ActionTypes";
import ProfileWidget from "../../components/layout/ProfileWidget";
import Notification from "../../components/layout/Notification";
import ClassIcon from "../../assets/images/icon_class.jpg";
import UserIcon from "../../assets/images/icon_user.jpg";
import { FaVideo } from "react-icons/fa";
import UpdateStatusPopUp from "../../components/PopUp/UpdateStatusPopup";
import "./List.css";

class ClassList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      class_list: [],
      pets: [],
      total_stars: null,
      lesson_credits: null,
      error: "",
      render: false,
      classroomStatus: "",
      isStatusChanged: false,
      classroomSubstatus: "",
      classroomReason: "",
      sub_status_error: "",
      filterValue: "all"
    };
  }

  componentDidMount() {
    if(document.body.style.zoom !== zoomRange.one){
      document.body.style.zoom = zoomRange.one
    }
    const { getAllClassRoomSessionRequest } = this.props;
    getAllClassRoomSessionRequest();
  }

  componentDidUpdate(prevProps) {
    const { all_classes, all_pets, get_pet, user_type } = this.props;
    if (prevProps.all_classes !== all_classes) {
      const { result } = all_classes;
      if (result.success) {
        const class_list = _.get(result, "response.data", []);
        this.updateClassesOnFilter(this.state.filterValue, class_list)
        this.setState({ render: user_type !== "student" })
        if (user_type === "student") this.props.getAllPetsRequest();
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.all_pets !== all_pets) {
      const { result } = all_pets;
      if (result.success) {
        this.setState(
          {
            pets: _.get(result, "response.data.pets", []),
            total_stars: _.get(result, "response.data.stars_in_total", null),
            render: true,
          },
          () => this._loadPetImage()
        );
      } else {
        // pets not found
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.get_pet !== get_pet) {
      const { result } = get_pet;
      if (result.success) {
        this.props.getAllPetsRequest();
      } else {
        // error in get a pet call
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }
  }

  _disabledReportButton = (class_report, end_at, class_report_card) => {
    const { user_type } = this.props;

    // Teacher : Report should not be submitted
    // Teacher : End time should not be expired with NOW time.
    if (user_type === "teacher") {
      return _.isEmpty(class_report)
        ? moment()
          .tz(_CONST.TIMEZONE)
          .isBefore(moment(end_at).tz(_CONST.TIMEZONE))
        : true;
    } else {
      // Student : View only if report is submitted by teacher
      if (Boolean(class_report_card)) {
        return _.isEmpty(class_report_card);
      }
      else {
        return _.isEmpty(class_report);
      }
    }
  };

  _goToClassRoom = (session) => {
    if (!_.isEmpty(session))
      this.props.history.push(
        _.replace(
          _ROUTES.PATH_CLASSROOM_SESSION,
          new RegExp(":classId?", "g"),
          session.uuid
        )
      );
  };

  _viewRoomSlides = (session) => {
    if (!_.isEmpty(session))
      this.props.history.push(
        _.replace(
          _ROUTES.PATH_CLASSROOM_SLIDES,
          new RegExp(":classId?", "g"),
          session.uuid
        )
      );
  };

  _viewRoomVideos = (session) => {
    if (!_.isEmpty(session))
      this.props.history.push(
        _.replace(
          _ROUTES.PATH_CLASSROOM_VIDEOS,
          new RegExp(":classId?", "g"),
          session.uuid
        )
      );
  };

  _goToWriteReport = (session) => {
    if (!_.isEmpty(session))
      this.props.history.push(
        _.replace(
          _ROUTES.PATH_WRITE_REPORT,
          new RegExp(":classId?", "g"),
          session.uuid
        )
      );
  };

  _goToViewReport = (session) => {
    if (!_.isEmpty(session))
      this.props.history.push(
        _.replace(
          _ROUTES.PATH_VIEW_REPORT,
          new RegExp(":classId?", "g"),
          session.uuid
        )
      );
  };

  _loadPetImage = () => {
    const { pets } = this.state;
    let img = `../../assets/images/pets/none.jpg`;
    try {
      const found = _.find(_PET_IMAGES, (pet) => {
        return pet.level === pets.length;
      });
      if (!_.isEmpty(found)) img = `../../assets/images/pets/${found.name}.jpg`;
    } catch {
      return img;
    }
    return img;
  };

  _handleGetAPet = () => {
    this.props.getAPetRequest();
  };

  _handleReport = (session) => {
    const { user_type } = this.props;

    if (user_type === "teacher") this._goToWriteReport(session);
    if (user_type === "student") this._goToViewReport(session);
  };

  _handleChageSelect = (value, session) => {
    this.setState({
      isStatusChanged: true,
      classroomStatus: value,
      session: session,
    });
  };

  updateClassesOnFilter = (value, class_list) => {
    let filteredClasses = []
    if (value === ALL) {
      class_list.forEach(cl => {
        cl.report_disabled = this._disabledReportButton(
          cl.class_report,
          cl.end_at,
          cl.class_report_card
        )
        cl.classroom_disabled = moment()
          .tz(_CONST.TIMEZONE)
          .isAfter(
            moment(cl.end_at)
              .tz(_CONST.TIMEZONE)
              .add(
                _.has(cl, "extended_min") ? cl.extended_min || 0 : 0,
                "minutes"
              )
          )
        filteredClasses.push(cl)
      })
    } else if (value === REPORT) {
      class_list.forEach(cl => {
        const isClassroomSessionClose = moment()
          .tz(_CONST.TIMEZONE)
          .isAfter(
            moment(cl.end_at)
              .tz(_CONST.TIMEZONE)
              .add(
                _.has(cl, "extended_min") ? cl.extended_min || 0 : 0,
                "minutes"
              )
          )
        if (_.isEmpty(cl?.class_report) && _.isEmpty(cl?.class_report_card) && isClassroomSessionClose) {
          cl.report_disabled = this._disabledReportButton(
            cl.class_report,
            cl.end_at,
            cl.class_report_card
          )
          cl.classroom_disabled = moment()
            .tz(_CONST.TIMEZONE)
            .isAfter(
              moment(cl.end_at)
                .tz(_CONST.TIMEZONE)
                .add(
                  _.has(cl, "extended_min") ? cl.extended_min || 0 : 0,
                  "minutes"
                )
            )
          filteredClasses.push(cl)
        }
      })
    } else if (value === ACKNOWLEDGE) {
      class_list.forEach(cl => {
        if (cl?.class_report_card?.is_acknowledge === false) {
          cl.report_disabled = this._disabledReportButton(
            cl.class_report,
            cl.end_at,
            cl.class_report_card
          )
          cl.classroom_disabled = moment()
            .tz(_CONST.TIMEZONE)
            .isAfter(
              moment(cl.end_at)
                .tz(_CONST.TIMEZONE)
                .add(
                  _.has(cl, "extended_min") ? cl.extended_min || 0 : 0,
                  "minutes"
                )
            )
          filteredClasses.push(cl)
        }
      })
    }
    this.setState({ filterValue: value, class_list: filteredClasses })
  }

  _handleFilter = (value) => {
    const { all_classes } = this.props;
    const { result } = all_classes;
    if (result.success) {
      const class_list = _.get(result, "response.data", []);
      this.updateClassesOnFilter(value, class_list)
    }
  }

  _closeModal = () => {
    this.setState({ isStatusChanged: false });
  };

  _onUpdate = () => {
    const payload = {
      status: this.state.classroomStatus,
      sub_status: this.state.classroomSubstatus,
      reason: this.state.classroomReason,
      id: this.state.session.uuid,
    };

    if (payload.status === "Incomplete" && !payload.sub_status) {
      this.setState({
        sub_status_error: "Please select any one radion option",
        error: true,
      });
    } else {
      this.setState({ isStatusChanged: false }, () => {
        this.props.updateClassroomSessionRequest(payload);
        this.props.getAllClassRoomSessionRequest();
      });
    }
  };

  handleChange = (value) => {
    this.setState({ classroomSubstatus: Object.keys(value)[0] });
  };

  handleChangeReason = (value) => {
    this.setState({ classroomReason: Object.values(value)[0] });
  };

  render() {
    const { user, user_type, allow_video_recording } = this.props;
    const {
      total_stars,
      render,
      error,
      class_list,
      isStatusChanged,
      classroomStatus,
      sub_status_error,
    } = this.state;

    return (
      <div className="container">
        <Notification allowDelete={user_type === "student"} />
        {!_.isEmpty(error) && (
          <section className="section pb0 pt0">
            <div className="notification">{error}</div>
          </section>
        )}

        {render && (
          <div>
            <ProfileWidget totalStars={total_stars} />
            {isStatusChanged && (
              <UpdateStatusPopUp
                show={isStatusChanged}
                onClose={this._closeModal}
                onUpdate={this._onUpdate}
              >
                <div>
                  <p className="modal-title">
                    Are you sure to update the status ?
                  </p>
                  {classroomStatus === "Incomplete" && (
                    <div>
                      <p>Please select any reason</p>
                      <form onSubmit={this._onUpdate}>
                        <div className="control radio-list">
                          <label className="radio">
                            <input
                              type="radio"
                              name="class_status"
                              onChange={({ target: { value } }) =>
                                this.handleChange({
                                  "Cancelled by student": value,
                                })
                              }
                            />
                            Cancelled by student
                          </label>
                          <label className="radio">
                            <input
                              type="radio"
                              name="class_status"
                              onChange={({ target: { value } }) =>
                                this.handleChange({
                                  "Cancelled by teacher": value,
                                })
                              }
                            />
                            Cancelled by teacher
                          </label>
                          <label className="radio">
                            <input
                              type="radio"
                              name="class_status"
                              onChange={({ target: { value } }) =>
                                this.handleChange({
                                  "Regular student no-show": value,
                                })
                              }
                            />
                            Regular student no-show
                          </label>
                          <label className="radio">
                            <input
                              type="radio"
                              name="class_status"
                              onChange={({ target: { value } }) =>
                                this.handleChange({
                                  "Trial student no-show": value,
                                })
                              }
                            />
                            Trial student no-show
                          </label>
                          <label className="radio">
                            <input
                              type="radio"
                              name="class_status"
                              onChange={({ target: { value } }) =>
                                this.handleChange({ "Teacher no-show": value })
                              }
                            />
                            Teacher no-show
                          </label>
                          <label className="radio">
                            <input
                              type="radio"
                              name="class_status"
                              onChange={({ target: { value } }) =>
                                this.handleChange({ Others: value })
                              }
                            />
                            Others
                          </label>
                        </div>
                        <p
                          className="help is-danger ml15"
                          style={{ fontSize: "20px" }}
                        >
                          {error && sub_status_error}
                        </p>
                        <div className="field">
                          <div className="control">
                            <textarea
                              id="reason"
                              type="text"
                              className={`textarea`}
                              placeholder="Reason to cancel the session"
                              onChange={({ target: { value } }) =>
                                this.handleChangeReason({ value })
                              }
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </UpdateStatusPopUp>
            )}
            {user_type === "student" && (
              <section className="section student-pet-bar pt0 pb0">
                <div className="columns">
                  <div className="column is-9">
                    {`${_.get(user, "profile.nickname", "") ||
                      _.get(user, "profile.fullname", "")
                      }'s Kingdom`}
                  </div>
                  <div className="column is-3">
                    <button
                      disabled={total_stars < _CONST.PET_STAR_LIMIT}
                      className="button is-fullwidth is-info btn-get-pet"
                      onClick={this._handleGetAPet}
                    >
                      Get Pet
                    </button>
                  </div>
                </div>
              </section>
            )}

            {user_type === "student" && (
              <section className="section pt10 pb0">
                <div className="animal-kingdom-section">
                  <span>
                    <img src={this._loadPetImage()} alt="Pet Image" />
                  </span>
                </div>
              </section>
            )}
            <section className="section pt0">
              <div className="class-list">
                <div className="myclass-container">
                  <h2 className="title is-spaced">My Classes</h2>
                  {user_type !== 'student' && <div className="filter-container">
                    {/* <label>Filter Classes</label> */}
                    <div className="select is-info">
                      <select
                        className="list-select filter-select"
                        value={this.state.filterValue}
                        onChange={({ target: { value } }) =>
                          this._handleFilter(value)
                        }
                      >
                        <option value={ALL}>All Classes</option>
                        <option value={REPORT}>Class report needed to fill</option>
                        <option value={ACKNOWLEDGE}>Class needed to acknowledge</option>
                      </select>
                    </div>
                  </div>
                  }
                </div>

                {this.state.filterValue === ALL && class_list.length === 0 && (
                  <p className="subtitle">contact us to setup class.</p>
                )}

                {this.state.filterValue !== ALL && class_list.length === 0 && (
                  <p className="subtitle">No classes found.</p>
                )}
            

                {_.map(class_list, (session, key) => {
                  return (
                    <div className="box classroom-session-box" key={key}>
                      <div className="columns">
                        <div className="column is-8">
                          <div className="time has-text-centered-mobile">{`${moment(
                            session.start_at
                          )
                            .tz(_CONST.TIMEZONE)
                            .format("YYYY - M - D dddd h:mmA")} - ${moment(
                              session.end_at
                            )
                              .tz(_CONST.TIMEZONE)
                              .format("h:mmA")}`}</div>
                          <div className="level details">
                            <div className="level-left">
                              <div
                                className="level-item"
                                style={{ display: "block" }}
                              >
                                {user_type === "student" && (
                                  <span
                                    className={
                                      session.status === "Completed"
                                        ? "tag is-success"
                                        : session.status === "Scheduled"
                                          ? "tag is-info"
                                          : session.status === "Incomplete"
                                            ? "tag is-danger"
                                            : ""
                                    }
                                  >
                                    {_.get(session, "status")}
                                  </span>
                                )}
                                {user_type === "teacher" &&
                                  session.status === "Incomplete" && (
                                    <span className="tag is-danger">
                                      {_.get(session, "sub_status")}
                                    </span>
                                  )}
                                {user_type === "teacher" &&
                                  session.status !== "Incomplete" && (
                                    <span
                                      className={
                                        session.status === "Completed"
                                          ? "tag is-success"
                                          : session.status === "Scheduled"
                                            ? "tag is-info"
                                            : session.status === "Incomplete"
                                              ? "tag is-danger"
                                              : ""
                                      }
                                    >
                                      {_.get(session, "status")}
                                    </span>
                                  )}
                              </div>
                              {user_type === "teacher" && session.allow_video_recording === "Allowed" && (
                                <div className="level-item video-icon-hvr" onClick={() => this._viewRoomVideos(session)} title="Watch classroom videos">
                                  <FaVideo />
                                </div>
                              )}
                              <div className="level-item">
                                <img
                                  className="image is-16x16"
                                  src={UserIcon}
                                  alt="User"
                                />
                                {user_type === "student" && (
                                  <span className="ml10">
                                    {_.get(
                                      session,
                                      "teacher_account.profile.fullname",
                                      ""
                                    )}
                                  </span>
                                )}
                                {user_type === "teacher" && (
                                  <span className="ml10">
                                    {_.get(
                                      session,
                                      "account.profile.fullname",
                                      ""
                                    )}
                                  </span>
                                )}
                              </div>
                              <div className="level-item">
                                <img
                                  className="image is-16x16"
                                  src={ClassIcon}
                                  alt="Class"
                                />
                                <p>
                                  <span className="ml10">{`Level ${_.get(
                                    session,
                                    "course.level",
                                    ""
                                  )} - Unit ${_.get(
                                    session,
                                    "course.unit",
                                    ""
                                  )} - Lesson ${_.get(
                                    session,
                                    "course.lesson",
                                    ""
                                  )}`}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column is-4">
                          <div className="columns">
                            <div
                              className={session.allow_video_recording === "Not Allowed" && user_type === "student"? "column is-12 col-goto-btn _pt0" : "column is-6 col-goto-btn _pt0"}
                            >
                              <button
                                className={session.allow_video_recording === "Allowed"? "button is-primary btn-go-to-class goto-btn-width": "button is-primary is-fullwidth btn-go-to-class"}
                                disabled={session.classroom_disabled}
                                onClick={() => this._goToClassRoom(session)}
                              >
                                Go to Classroom
                              </button>
                            </div>
                            {user_type === "student" && session.allow_video_recording === "Allowed" && (
                            <div className="column is-6 video_btn_width _pt0">
                              <button
                                className="button is-primary is-fullwidth btn-go-to-class"
                                disabled={session.recorded_videos.length > 0 && session.recorded_videos[0].s3_url !== null ? false : true}
                                onClick={() => this._viewRoomVideos(session)}
                              >
                                Class Videos
                              </button>
                            </div>)}
                            {user_type === "teacher" && (
                              <div className="column is-6 col-select-status">
                                <div className="select is-info">
                                  <select
                                    className="list-select"
                                    value={session.status ? session.status : ''}
                                    onChange={({ target: { value } }) =>
                                      this._handleChageSelect(value, session)
                                    }
                                  >
                                    <option value="Scheduled">Scheduled</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Incomplete">
                                      Incomplete
                                    </option>
                                  </select>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="columns">
                            <div className="column is-6 col-report-btn _pt0">
                              <button
                                className={`button is-fullwidth btn-report ${_.get(session, "class_report_card.is_acknowledge", "") === false && user_type === "teacher" ? 'is-secondary-blue' : 'is-primary'}`}
                                disabled={session.report_disabled}
                                onClick={() => this._handleReport(session)}
                              >
                                Class Report
                              </button>
                            </div>
                            <div className="column is-6 col-slide-btn _pt0">
                              <button
                                className="button is-primary is-fullwidth"
                                onClick={() => this._viewRoomSlides(session)}
                              >
                                Class Slides
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _.get(state, "auth.login.result.response.data", {}),
    all_classes: _.get(state, "room.all_classes", {}),
    user_type: _.get(state, "auth.login.result.response.role", ""),
    all_pets: _.get(state, "room.all_pets", {}),
    get_pet: _.get(state, "room.get_pet", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateClassroomSessionRequest: (payload) =>
    dispatch({ type: reduxActions.UPDATE_CLASS_ROOM_SESSION_REQUEST, payload }),
  getAllClassRoomSessionRequest: (payload) =>
    dispatch({
      type: reduxActions.GET_ALL_CLASS_ROOM_SESSION_REQUEST,
      payload,
    }),
  getAllPetsRequest: () =>
    dispatch({ type: reduxActions.GET_ALL_PETS_REQUEST }),
  getAPetRequest: (payload) =>
    dispatch({ type: reduxActions.GET_A_PET_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassList);
