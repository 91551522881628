import { uniqueId } from 'lodash';
import { API } from './API';

// Get All user's event
export function getAllUserEvents() {
    return new Promise((resolve, reject) => {
        return API('GET', `events/`).then((response) => {
            response.success ? resolve(response) : reject(response);
        }).catch((error) => {
            reject(error);
        });
    });
}

// Add User's Event
export function addUserEvents(data) {
    return new Promise((resolve, reject) => {
        return API('POST', `events/`, data).then((response) => {
            response.success ? resolve(response) : reject(response);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function updateUserEvents(data) {
    return new Promise((resolve, reject) => {
        return API('PUT', `events/${data.uuid}`, data).then((response) => {
            response.success ? resolve(response) : reject(response);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function deleteUserEvent(data) {
    return new Promise((resolve, reject) => {
        return API('DELETE', `events/${data.event_id}`).then((response) => {
            response.success ? resolve(response) : reject(response);
        }).catch((error) => {
            reject(error);
        });
    });
}