import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { userRegistration, getAllStudents } from '../../services/user.service';
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* userRegistrationWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(userRegistration, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.USER_REGISTRATION_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.USER_REGISTRATION_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getAllStudentsWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllStudents, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_STUDENT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_STUDENT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

/* WATCHERS SAGA */

// Watcher for user registration
export function* userRegistrationWatcher() {
  yield takeLatest(reduxActions.USER_REGISTRATION_REQUEST, userRegistrationWorker);
}

export function* getAllStudentsWatcher() {
  yield takeLatest(reduxActions.GET_ALL_STUDENT_REQUEST, getAllStudentsWorker);
}

export default function* rootSaga() {
  yield all([
    fork(userRegistrationWatcher),
    fork(getAllStudentsWatcher),

  ]);
}
