import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import * as reduxActions from "../../constants/ActionTypes";

class WalletNotification extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      lesson_credits: null, 
    };
  };

  componentDidMount() {
    this.props.getStudentWalletRequest();
  }

  componentDidUpdate(prevProps) {
    const { wallet } = this.props;

    if (prevProps.wallet !== wallet) {
      const { result } = wallet;
      if (result.success) {
        this.setState({
          lesson_credits: _.get(result, 'response.data.lesson_credits', null),
          show: true,
        });
      }
    }
  }

  hideNotification = () => {
    this.setState({ show: false });
  }

  render() {
    const { show, lesson_credits } = this.state;
    return (
      <section className="section pb0 pt0">
        {(show && _.isNumber(lesson_credits)) && <div className="notification">
          <button className="delete" onClick={this.hideNotification}></button>
          <div>You have {lesson_credits} classes available</div>
        </div>}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wallet: _.get(state, "room.wallet", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentWalletRequest: payload => dispatch({ type: reduxActions.GET_WALLET_DETAIL_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletNotification);
