import { combineReducers } from "redux";
import global from "./global.reducer";
import auth from "./auth.reducer";
import room from "./classroom.reducer";
import course from "./course.reducer";
import user from "./user.reducer";
import event from './event.reducer';

const reducers = combineReducers({
  global,
  auth,
  room,
  course,
  user,
  event
});

export default reducers;
