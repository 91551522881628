import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import './LayoutStyle.css';

function PageLoader({ isVisible }) {
  return isVisible ? (<div className="page-loader-container">
    <div className="spin-loader"></div>
  </div>) : null;
}

const mapStateToProps = (state) => {
  return {
    isVisible: _.get(state, 'global.pageLoader.isVisible', false),
  };
};

export default connect(mapStateToProps)(PageLoader);
