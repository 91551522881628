import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

// user registration
const registration = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.USER_REGISTRATION_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.USER_REGISTRATION_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const students = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_STUDENT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_STUDENT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  registration,
  students
});
