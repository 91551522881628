import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

// get all courses
const all_courses = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_COURSE_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_COURSE_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

// get all cards
const all_cards = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_CARDS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_CARDS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

// add new card
const add_card = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.ADD_CARD_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.ADD_CARD_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

// charge card
const charge_card = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.CHARGE_CARD_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.CHARGE_CARD_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

// remove card
const remove_card = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.REMOVE_CARD_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.REMOVE_CARD_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  all_courses,
  all_cards,
  add_card,
  charge_card,
  remove_card,
});
