import React from "react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { _ROUTES } from "../../constants/GlobalConst";

const Header = ({ user_type }) => {
  return (
    <div className="container">
      <section className="section header-section">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <NavLink to={_ROUTES.PATH_DASHBOARD} className="navbar-item _pl0">
              Starkid {user_type === "teacher" ? `Teacher` : ``}
            </NavLink>
            <a
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start">
              {user_type === "teacher" && (
                <NavLink to={_ROUTES.PATH_CALENDAR} className="navbar-item">
                  Calendar (beta)
                </NavLink>
              )}
              <NavLink to={_ROUTES.PATH_DASHBOARD} className="navbar-item">
                Dashboard
              </NavLink>
              {user_type === "student" && (
                <NavLink to={_ROUTES.PATH_GET_COURSES} className="navbar-item">
                  Get Course
                </NavLink>
              )}

              <NavLink to={_ROUTES.PATH_DEVICE_TESTING} className="navbar-item">
                Device Testing
              </NavLink>

              {user_type === "student" && (
                <a
                  href={_ROUTES.PATH_MAGIC_WRITING}
                  className="navbar-item"
                  target="_blank"
                >
                  <span className="badge-container">
                    Magic Story
                    <span className="badge">new</span>
                  </span>
                </a>
              )}
            </div>
            <div className="navbar-end">
              <NavLink to={_ROUTES.PATH_LOGOUT} className="navbar-item _pr0">
                Logout
              </NavLink>
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
};

export default Header;
