import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { _ROUTES } from "../../constants/GlobalConst";
import { APP_USER_TYPE } from "../../constants/Config";
import SignupForm from './components/SignupForm';
import { validEmailRegex } from "../../helpers/Utils";
import Popup from "../../components/PopUp/Popup";

import './SignupStyle.css';

import * as reduxActions from "../../constants/ActionTypes";

const MSG_SIGNUP_WELCOME = '<b>Heads up!</b> Your account has been created successfully!';
const MSG_INVALID_EMAIL = 'Email is not valid';
const MSG_INVALID_PWD = 'Password must be minimum 6 characters long';
const MSG_INVALID_FIELD = "Field can't be blank";
const MSG_PWD_NOT_MATCHED = "Confirm Password doesn't match";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      signup_resp: null,
      form_data: {
        email: '',
        phone_number: '',
        fullname: '',
        nickname: '',
        date_of_birth: '',
        english_exposure_level: '',
        password: '',
        confirm_password: '',
      },
      form_errors: {
        email: '',
        phone_number: '',
        fullname: '',
        nickname: '',
        date_of_birth: '',
        english_exposure_level: '',
        password: '',
        confirm_password: '',
      },
      showErrorBlock: false,
      loading: false,
      is_skip_phone: false
    };
  }

  componentDidMount() {
    const { login, history } = this.props;

    // Redirect user back to dashboard if token exists
    const token = _.get(login, 'result.response.token', '');
    if (!_.isEmpty(token)) {
      history.push(_ROUTES.PATH_HOME);
    }

    if(APP_USER_TYPE === 'teacher'){
      history.push(_ROUTES.PATH_LOGIN);
    }
  }

  componentDidUpdate(prevProps) {
    const { login, registration, history, storeNotificationRequest, storeAutoLoginDataRequest } = this.props;

    if (prevProps.login !== login) {
      const { result } = login;
      if (result.success) {
        storeNotificationRequest(MSG_SIGNUP_WELCOME);
        history.push(_ROUTES.PATH_DASHBOARD);
      }
    }

    if (prevProps.registration !== registration) {
      const { result } = registration;
      if (result.success) {
        localStorage.setItem('auth', JSON.stringify(result));
        storeAutoLoginDataRequest(result);
      } else {
        const signup_resp  = _.get(result, 'response.message', '');

        if(signup_resp === "phone number has already been taken") {
          this.setState({
            signup_resp,
            showPopUp: true,
            loading: false,
          });
        }
        else{
          this.setState({
            signup_resp,
            showErrorBlock: true,
            loading: false,
          });
        }
      }
    }
  }

  _validatePassword = (value) => {
    return value ? value.length >= 6 : false;
  }

  _validateFormField = (field, value) => {
    const { form_data } = this.state;
    if (!value) {
      return MSG_INVALID_FIELD;
    } else {
      if (field === 'email') {
        return validEmailRegex.test(value) ? '' : MSG_INVALID_EMAIL;
      }

      if (field === 'password') {
        return this._validatePassword(value) ? '' : MSG_INVALID_PWD;
      }

      if (field === 'confirm_password') {
        return form_data.password !== value ? MSG_PWD_NOT_MATCHED : '';
      }
    }
  }

  _validateForm = (fields) => {
    const { form_data, form_errors } = this.state;
    let errors = { ...form_errors };

    _.map(fields, name => {
      errors = {
        ...errors,
        [name]: this._validateFormField(name, form_data[name])
      };
      return name;
    });

    return errors;
  }

  _handleChange = (payload) => {
    const { form_data } = this.state;

    this.setState({
      form_data: {
        ...form_data,
        ...payload,
      },
    });
  }

  _hideErrorBlock = () => {
    this.setState({ showErrorBlock: false });
  }

  _handleSubmit = (e) => {
    e.preventDefault();

    this._handle_sign_up(false);
  }

  _navigateLogin = () => {
    this.props.history.push(_ROUTES.PATH_LOGIN);
  }

  _handle_sign_up = (skip) => {
    const { form_data } = this.state;
    const errors = this._validateForm(['email', 'phone_number', 'fullname', 'nickname', 'date_of_birth', 'english_exposure_level', 'password', 'confirm_password']);
    const found = _.find(errors, field => { return !_.isEmpty(field) });

    if (!_.isEmpty(found)) {
      this.setState({ form_errors: errors });
    } else {
      this.setState({ loading: true, form_errors: errors }, () => {
        this.props.userRegistrationRequest({
          email: form_data.email,
          phone_number: form_data.phone_number,
          fullname: form_data.fullname,
          nickname: form_data.nickname,
          date_of_birth: form_data.date_of_birth,
          english_exposure_level: form_data.english_exposure_level,
          password: form_data.password,
          is_skip_phone: skip
        });
      });
    }
  }

  onPopUpClose = (e) => {
    e.preventDefault();
    this.setState({showPopUp : false});

    // Skipp the phonenumber validation
    this._handle_sign_up(true);
  }

  render() {
    const { form_data, form_errors, signup_resp, showErrorBlock, loading } = this.state;

    return (
      <div>
        <Popup onClose={this.onPopUpClose} show={this.state.showPopUp} >
          <h3>Phone number is already registered. Are you sure you want to register with the same number?</h3>
        </Popup>
        <SignupForm
          handleSubmit={this._handleSubmit}
          handleChange={this._handleChange}
          hideErrorBlock={this._hideErrorBlock}
          navigateLogin={this._navigateLogin}
          signupResp={signup_resp}
          errors={form_errors}
          data={form_data}
          showErrorBlock={showErrorBlock}
          loading={loading}
        />
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    login: _.get(state, 'auth.login', {}),
    registration: _.get(state, 'user.registration', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  userRegistrationRequest: payload => dispatch({ type: reduxActions.USER_REGISTRATION_REQUEST, payload }),
  storeNotificationRequest: payload => dispatch({ type: reduxActions.STORE_NOTIFICATION_REQUEST, payload }),
  storeAutoLoginDataRequest: payload => dispatch({ type: reduxActions.STORE_AUTO_LOGIN_DATA_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
