import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

let auth = null;

try {
  auth = JSON.parse(localStorage.getItem("auth"));
} catch (error) {}

const INIT_STATE = {
  result: {
    ...auth,
  },
};

// user login
const login = (state = INIT_STATE, action) => {
  switch (action.type) {
    case reduxActions.USER_LOGIN_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.USER_LOGIN_FAILURE:
      return {
        result: action.payload,
      };
    case reduxActions.USER_LOGOUT_SUCCESS:
      return { result: {} };
    case reduxActions.STORE_AUTO_LOGIN_DATA_SUCCESS:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// user logout
const logout = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.USER_LOGOUT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.USER_LOGOUT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  login,
  logout,
});
