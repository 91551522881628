import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import VideoStream from './VideoStream';
import * as reduxActions from "../../../constants/ActionTypes";

class TwilioVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
    };
  }

  componentDidMount() {
    this._joinVideoConfRoom();
  }

  componentDidUpdate(prevProps) {
    const { twilio } = this.props;

    if (prevProps.twilio !== twilio) {
      const { result } = twilio;
      if (result.success) {
        this.setState({ token: _.get(result, 'response.token') });
      }
    }
  }

  _joinVideoConfRoom = () => {
    const { userName, roomName, getTwilioTokenRequest } = this.props;
    const params = JSON.stringify({ identity: userName, room: roomName });
    getTwilioTokenRequest(params);
  }

  _handleLogout = () => {
    this.setState({ token: null });
  }

  render() {
    const { token } = this.state;
    const { roomName, class_id, isScreenSharedByTeacher, setIsScreenSharedByTeacher, isScreenSharingAllowed } = this.props;
    return (
      <div>
        {
          token
            ? <VideoStream 
               roomName={roomName} 
               token={token} 
               handleLogout={this._handleLogout} 
               class_id={class_id}
               isScreenSharedByTeacher={isScreenSharedByTeacher}
               setIsScreenSharedByTeacher={setIsScreenSharedByTeacher}
               isScreenSharingAllowed={isScreenSharingAllowed} />
            : 'Loading Video Stream...'
        }
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    twilio: _.get(state, 'room.twilio_token', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTwilioTokenRequest: payload => dispatch({ type: reduxActions.GET_TWILIO_TOKEN_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TwilioVideo);
