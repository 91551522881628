import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { getAllUserEvents, addUserEvents, deleteUserEvent, updateUserEvents } from '../../services/event.services';
import * as reduxActions from "../../constants/ActionTypes";

function* getAllUserEventsWorker(action) {
    try {
        yield put({ type: reduxActions.SHOW_PAGE_LOADER });
        const resp = yield call(getAllUserEvents, action.payload);
        if (resp.success) {
            yield put({
                type: reduxActions.GET_ALL_USER_EVENT_SUCCESS,
                payload: resp
            });
        }
        yield put({ type: reduxActions.HIDE_PAGE_LOADER });
    } catch (error) {
        yield put({
            type: reduxActions.GET_ALL_USER_EVENT_FAILURE,
            payload: error
        });
        yield put({ type: reduxActions.HIDE_PAGE_LOADER });
    }
}

function* addEventWorker(action) {
    try {
        yield put({ type: reduxActions.SHOW_PAGE_LOADER });
        const resp = yield call(addUserEvents, action.payload);
        if (resp.success) {
            yield put({
                type: reduxActions.ADD_EVENT_SUCCESS,
                payload: resp
            });
        }
        yield put({ type: reduxActions.HIDE_PAGE_LOADER });
    } catch (error) {
        yield put({
            type: reduxActions.ADD_EVENT_FAILURE,
            payload: error
        });
        yield put({ type: reduxActions.HIDE_PAGE_LOADER });
    }
}

function* removeEventWorker(action) {
    try {
        yield put({ type: reduxActions.SHOW_PAGE_LOADER });
        const resp = yield call(deleteUserEvent, action.payload);
        if (resp.success) {
            yield put({
                type: reduxActions.REMOVE_EVENT_SUCCESS,
                payload: resp
            });
        }
        yield put({ type: reduxActions.HIDE_PAGE_LOADER });
    } catch (error) {
        yield put({
            type: reduxActions.REMOVE_EVENT_FAILURE,
            payload: error
        });
        yield put({ type: reduxActions.HIDE_PAGE_LOADER });
    }
}

function* updateEventWorker(action) {
    try {
        yield put({ type: reduxActions.SHOW_PAGE_LOADER });
        const resp = yield call(updateUserEvents, action.payload);
        if (resp.success) {
            yield put({
                type: reduxActions.UPDATE_EVENT_SUCCESS,
                payload: resp
            });
        }
        yield put({ type: reduxActions.HIDE_PAGE_LOADER });
    } catch (error) {
        yield put({
            type: reduxActions.UPDATE_EVENT_FAILURE,
            payload: error
        });
        yield put({ type: reduxActions.HIDE_PAGE_LOADER });
    }
}

export function* getAllUserEventsWatcher() {
    yield takeLatest(reduxActions.GET_ALL_USER_EVENT_REQUEST, getAllUserEventsWorker);
}
export function* addUserEventsWatcher() {
    yield takeLatest(reduxActions.ADD_EVENT_REQUEST, addEventWorker);
}
export function* removeEventWatcher() {
    yield takeLatest(reduxActions.REMOVE_EVENT_REQUEST, removeEventWorker);
}
export function* updateEventWatcher() {
    yield takeLatest(reduxActions.UPDATE_EVENT_REQUEST, updateEventWorker);
}

export default function* rootSaga() {
    yield all([
        fork(getAllUserEventsWatcher),
        fork(removeEventWatcher),
        fork(addUserEventsWatcher),
        fork(updateEventWatcher)
    ]);
}