import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";
import { _CONST, _ROUTES } from "../../constants/GlobalConst";
import ClassColorIcon from "../../assets/images/icon_class_color.png";
import UserIcon from "../../assets/images/icon_user.jpg";
import * as reduxActions from "../../constants/ActionTypes";
import { generateStudentGrade } from "../../helpers/Utils";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import starkid_logo from '../../assets/images/starkid-logo-transparent-01.png'
const MSG_INVALID_FIELD = "can't be blank";

class WriteReport extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { classId },
      },
    } = props;

    this.state = {
      class_id: classId,
      class_session: {},
      form_data: {
        number_of_stars_rewarded_to_student: 0,
        feedback: "",
        social_studies_and_science_level: null,
        speaking_and_listening_level: null,
        reading_and_writing_level: null,
        grammar_level: null,

        concentration_level: 0,
        reaction_level: 0,
        expression_level: 0,
        interaction_level: 0,

        strenth_and_weakness: [{ reading: '50' }, { listening: '50' }, { grammar: '50' }, { pronunciation: '50' }, { vocabulary: '50' }],
        teacher_suggested_area: [{ sugestion: 'Grammar', isSelected: false }, { sugestion: 'Oral', isSelected: false }, { sugestion: 'Reading', isSelected: false }, { sugestion: 'Listening', isSelected: false }, { sugestion: 'Vocabulary', isSelected: false }],
        parents_suggested_area: [{ sugestion: 'Grammar', isSelected: false }, { sugestion: 'Oral', isSelected: false }, { sugestion: 'Reading', isSelected: false }, { sugestion: 'Listening', isSelected: false }, { sugestion: 'Vocabulary', isSelected: false }],
        csv_data: []
      },
      form_errors: {},
      error: "",
      render: false,
      is_report_card: false,
      parents_suggested_area: [],
      parents_feedback: "",
      is_report_generated: false,
      student_grade: { grade: 'F', average: 0 },
      class_learning: 0,
      is_generate_report_disabled: false,
      is_acknowledge: null,
      report_card_uuid: "",
      isReviewButtonEnabled: false
    };
  }
  componentDidMount() {
    const { getClassRoomSessionRequest } = this.props;
    const { class_id } = this.state;

    // Get session details
    getClassRoomSessionRequest({ id: class_id });
  }

  componentDidUpdate(prevProps) {
    const {
      class_session,
      save_report,
      history,
      storeNotificationRequest,
      update_parents_suggestions,
      upload_pdf_report
    } = this.props;


    if (prevProps.update_parents_suggestions !== update_parents_suggestions) {
      const { result } = update_parents_suggestions;

      if (result.success) {
        // Disable Acknowledge button
        storeNotificationRequest(
          "<b>Heads up!</b> You have acknowledged the report successfully."
        );
        history.push(_ROUTES.PATH_DASHBOARD);
      }
    }

    if (prevProps.class_session !== class_session) {
      const { result } = class_session;
      if (result.success) {
        const cs = _.get(result, "response.data", {});
        const csv_response = _.get(result, "response.course_report", {});

        if (Boolean(csv_response[0]?.csv_data)) {
          let csv_report_data;
          let strenth_and_weakness;
          let teacher_suggested_area;
          let parents_suggested_area = [];

          // If Unit report data available show report only
          if (cs?.class_report_card?.csv_report_data) {
            csv_report_data = JSON.parse(cs.class_report_card.csv_report_data);
            strenth_and_weakness = JSON.parse(cs.class_report_card.strenth_and_weakness);
            teacher_suggested_area = JSON.parse(cs.class_report_card.teacher_suggested_area);

            if (cs.class_report_card.parents_suggested_area) {
              parents_suggested_area = JSON.parse(cs.class_report_card.parents_suggested_area);
            }

            if (this._validClassSession(cs)) {
              this.setState({
                class_session: cs,
                render: true,
                csv_data: csv_report_data,
                is_report_card: true,
                is_report_generated: true,
                form_data: {
                  ...this.state.form_data,
                  strenth_and_weakness,
                  teacher_suggested_area,
                  feedback: cs.class_report_card.feedback
                },
                student_grade: generateStudentGrade(strenth_and_weakness),
                class_learning: cs.class_report_card.class_learning,
                parents_suggested_area,
                parents_feedback: cs.class_report_card.parents_feedback,
                is_acknowledge: cs.class_report_card.is_acknowledge
              });
            } else {
              this.setState({ error: "Invalid classroom session" });
            }
          }
          else {
            const csv_parsed_data = JSON.parse(csv_response[0].csv_data);
            const column_array = [];

            const arrayColumn = (arr, n) => arr.map(data => {
              if (Boolean(data[n])) {
                const reportTaskStatus = {
                  task: data[n],
                  isChecked: true
                }
                return reportTaskStatus;
              }
            });

            const length = csv_parsed_data.length > 0 ? csv_parsed_data[0].length : 0;

            for (var i = 0; i < length; i++) {

              let transformedData = arrayColumn(csv_parsed_data, i);
              column_array.push(transformedData);
            }

            if (this._validClassSession(cs)) {
              this.setState({
                class_session: cs,
                render: true,
                csv_data: column_array,
                is_report_card: true
              });
            } else {
              this.setState({ error: "Invalid classroom session" });
            }
          }
        } else {
          if (this._validClassSession(cs)) {
            this.setState({
              class_session: cs,
              render: true,
            });
          } else {
            this.setState({ error: "Invalid classroom session" });
          }
        }
      } else {
        // class session is not found
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.save_report !== save_report) {
      const { result } = save_report;

      if (result.success) {
        const cs = _.get(result, "response.data", {});

        if (cs?.expression_level) {
          storeNotificationRequest(
            "<b>Heads up!</b> Class report has been submitted."
          );
          history.push(_ROUTES.PATH_DASHBOARD);
        } else {
          // let strenth_and_weakness = JSON.parse(cs.strenth_and_weakness);
          let parents_suggested_area = [];

          if (cs?.parents_suggested_area) {
            parents_suggested_area = JSON.parse(cs.parents_suggested_area);
          }
          this.setState({
            isReviewButtonEnabled: false,
            parents_suggested_area,
            parents_feedback: cs.parents_feedback,
            is_acknowledge: cs.is_acknowledge,
            report_card_uuid: cs.uuid,
          })
          storeNotificationRequest(
            "<b>Heads up!</b> Class report has been submitted."
          );
          history.push(_ROUTES.PATH_DASHBOARD);
        }
      }
      else {
        this._scrollTop();
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }
  }


  _render_student_strenth_weakness = () => {
    return (
      <div className="line">
        <div className="columns mb0">
          <div className="column mar-s-and-w">
            <span className="subtitle">
              Reading
            </span>
            <select
              className="list-select"
              value={parseInt(this.state.form_data.strenth_and_weakness[0].reading)}
              onChange={({ target: { value } }) => {
                this._handleChange({
                  field: "reading",
                  value,
                  strenth: true
                })
              }}
            >
              <option value={100}>100/100</option>
              <option value={90}>90/100</option>
              <option value={80}>80/100</option>
              <option value={70}>70/100</option>
              <option value={60}>60/100</option>
              <option value={50}>50/100</option>
              <option value={40}>40/100</option>
              <option value={30}>30/100</option>
              <option value={20}>20/100</option>
              <option value={10}>10/100</option>
              <option value={0}>0/100</option>
            </select>
          </div>
        </div>

        <div className="columns mb0">
          <div className="column mar-s-and-w">
            <span className="subtitle">
              Listening
            </span>
            <select
              className="list-select"
              value={parseInt(this.state.form_data.strenth_and_weakness[1].listening)}
              onChange={({ target: { value } }) => {
                this._handleChange({
                  field: "listening",
                  value,
                  strenth: true
                })
              }}
            >
              <option value={100}>100/100</option>
              <option value={90}>90/100</option>
              <option value={80}>80/100</option>
              <option value={70}>70/100</option>
              <option value={60}>60/100</option>
              <option value={50}>50/100</option>
              <option value={40}>40/100</option>
              <option value={30}>30/100</option>
              <option value={20}>20/100</option>
              <option value={10}>10/100</option>
              <option value={0}>0/100</option>
            </select>
          </div>
        </div>

        <div className="columns mb0">
          <div className="column mar-s-and-w">
            <span className="subtitle">
              Grammar
            </span>
            <select
              className="list-select"
              value={parseInt(this.state.form_data.strenth_and_weakness[2].grammar)}
              onChange={({ target: { value } }) => {
                this._handleChange({
                  field: "grammar",
                  value,
                  strenth: true
                })
              }}
            >
              <option value={100}>100/100</option>
              <option value={90}>90/100</option>
              <option value={80}>80/100</option>
              <option value={70}>70/100</option>
              <option value={60}>60/100</option>
              <option value={50}>50/100</option>
              <option value={40}>40/100</option>
              <option value={30}>30/100</option>
              <option value={20}>20/100</option>
              <option value={10}>10/100</option>
              <option value={0}>0/100</option>
            </select>
          </div>
        </div>

        <div className="columns mb0">
          <div className="column mar-s-and-w">
            <span className="subtitle">
              Pronunciation
            </span>
            <select
              className="list-select"
              value={parseInt(this.state.form_data.strenth_and_weakness[3].pronunciation)}
              onChange={({ target: { value } }) => {
                this._handleChange({
                  field: "pronunciation",
                  value,
                  strenth: true
                })
              }}
            >
              <option value={100}>100/100</option>
              <option value={90}>90/100</option>
              <option value={80}>80/100</option>
              <option value={70}>70/100</option>
              <option value={60}>60/100</option>
              <option value={50}>50/100</option>
              <option value={40}>40/100</option>
              <option value={30}>30/100</option>
              <option value={20}>20/100</option>
              <option value={10}>10/100</option>
              <option value={0}>0/100</option>
            </select>
          </div>
        </div>

        <div className="columns mb0">
          <div className="column mar-s-and-w">
            <span className="subtitle">
              Vocabulary
            </span>
            <select
              className="list-select"
              value={parseInt(this.state.form_data.strenth_and_weakness[4].vocabulary)}
              onChange={({ target: { value } }) => {
                this._handleChange({
                  field: "vocabulary",
                  value,
                  strenth: true
                })
              }}
            >
              <option value={100}>100/100</option>
              <option value={90}>90/100</option>
              <option value={80}>80/100</option>
              <option value={70}>70/100</option>
              <option value={60}>60/100</option>
              <option value={50}>50/100</option>
              <option value={40}>40/100</option>
              <option value={30}>30/100</option>
              <option value={20}>20/100</option>
              <option value={10}>10/100</option>
              <option value={0}>0/100</option>
            </select>
          </div>
        </div>
      </div>
    );
  }

  _render_student_graph_strenth = () => {
    return (
      <div className="line">
        <div className="columns mb0">
          <div className="column mar-s-and-w">
            <span className="strength-title">
              Reading
            </span>
            <div className="progress-report">
              <div className="progress-bar" style={{ width: `${this.state.form_data.strenth_and_weakness[0].reading}%` }}>
              </div>
            </div>
            <span className="txt-align-right">
              {this.state.form_data.strenth_and_weakness[0].reading}/100
            </span>
          </div>
        </div>

        <div className="columns mb0">
          <div className="column mar-s-and-w">
            <span className="strength-title">
              Listening
            </span>
            <div className="progress-report">
              <div className="progress-bar" style={{ width: `${this.state.form_data.strenth_and_weakness[1].listening}%` }}>
              </div>
            </div>
            <span className="txt-align-right">
              {this.state.form_data.strenth_and_weakness[1].listening}/100
            </span>
          </div>
        </div>

        <div className="columns mb0">
          <div className="column mar-s-and-w">
            <span className="strength-title">
              Grammar
            </span>
            <div className="progress-report">
              <div className="progress-bar" style={{ width: `${this.state.form_data.strenth_and_weakness[2].grammar}%` }}>
              </div>
            </div>
            <span className="txt-align-right">
              {this.state.form_data.strenth_and_weakness[2].grammar}/100
            </span>
          </div>
        </div>

        <div className="columns mb0">
          <div className="column mar-s-and-w">
            <span className="strength-title">
              Pronunciation
            </span>
            <div className="progress-report">
              <div className="progress-bar" style={{ width: `${this.state.form_data.strenth_and_weakness[3].pronunciation}%` }}>
              </div>
            </div>
            <span className="txt-align-right">
              {this.state.form_data.strenth_and_weakness[3].pronunciation}/100
            </span>
          </div>
        </div>

        <div className="columns mb0">
          <div className="column mar-s-and-w">
            <span className="strength-title">
              Vocabulary
            </span>
            <div className="progress-report">
              <div className="progress-bar" style={{ width: `${this.state.form_data.strenth_and_weakness[4].vocabulary}%` }}>
              </div>
            </div>
            <span className="txt-align-right">
              {this.state.form_data.strenth_and_weakness[4].vocabulary}/100
            </span>
          </div>
        </div>
      </div>
    );
  }

  _render_teacher_suggested_area = () => {
    return (
      <div className="control">
        {
          this.state.form_data.teacher_suggested_area.map((item, index) => {
            return (
              <button key={`key-${index}`} className="button btn-go-to-class teacher-suggestin-buttons" style={{ backgroundColor: item.isSelected === true ? '#ffcc01' : '#efefef' }} onClick={(e) => {
                e.preventDefault();

                if (this._getSugestionCount() < 3 || (this._getSugestionCount() === 3 && item.isSelected === true)) {
                  this._handleSetSugestions(item);
                }
              }}>{`${item.sugestion}`}</button>
            )
          })
        }
      </div>
    );
  }

  _render_parents_suggested_area = () => {
    if (this.state.parents_suggested_area?.length > 0) {
      return (
        <div className="line">
          <div className="title is-spaced">
            Parents' Suggested Area of Focus
          </div>
          <div className="field">
            <div className="control">
              {
                this.state.parents_suggested_area.filter(function (filterItem) {
                  return filterItem.isSelected === true;
                }).map((item, index) => {
                  return (
                    <div className="button btn-go-to-class teacher-suggestin-buttons" key={`key-${index}`} style={{ backgroundColor: '#efefef' }}>{`${item.sugestion}`}</div>
                  )
                })
              }
            </div>
          </div>
        </div>
      );
    }
    else {
      return (
        <>
          <div className="line">
            <div className="title is-spaced">
              Parents' Suggested Area of Focus
            </div>
            <div className="field">
              <div className="control">
                {
                  this.state.form_data.parents_suggested_area.map((item, index) => {
                    return (
                      <div className="button btn-go-to-class teacher-suggestin-buttons" key={`key-${index}`} style={{ backgroundColor: '#efefef' }}>{`${item.sugestion}`}</div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          {
            this.state.isReviewButtonEnabled === true && (
              <div className="button-holder pb40">
                <button
                  className="button is-large"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Confirm Review
                </button>
              </div>
            )
          }

        </>
      );
    }
  }

  _display_teacher_suggested_area = () => {
    return (
      <div className="control">
        {
          this.state.form_data.teacher_suggested_area.length > 0 && this.state.form_data.teacher_suggested_area
            .map((item, index) => {
              if (item.isSelected) {
                return (
                  <div className="button btn-go-to-class teacher-suggestin-buttons" key={`key-${index}`} style={{ backgroundColor: '#efefef' }} >{`${item.sugestion}`}</div>
                )
              }
            })
        }
      </div>
    );
  }

  // Validate class room session against login user
  _validClassSession = (class_session) => {
    const { user } = this.props;
    try {
      return user.uuid === class_session.teacher_account_uuid; // only teacher can write report
    } catch {
      return false;
    }
  };

  _handleChange = (payload) => {
    const { form_data, form_errors } = this.state;
    let errors = { ...form_errors };

    errors = {
      ...errors,
      [payload.field]: this._validateFormField(payload.field, payload.value),
    };

    if (payload.strenth) {
      var strenth_and_weakness = this.state.form_data.strenth_and_weakness.map(function (strenth) {
        if (strenth.hasOwnProperty(String(payload.field))) {

          strenth[payload.field] = payload.value;
          return strenth;
        }
        else {
          return strenth;
        }
      });

      this.setState({
        form_data: {
          ...form_data,
          strenth_and_weakness,
        },
        form_errors: errors,
      });
    }
    else {
      this.setState({
        form_data: {
          ...form_data,
          [payload.field]: payload.value,
        },
        form_errors: errors,
      });
    }
  };

  _handleSaveReport = (e) => {
    e.preventDefault();
    const { form_data, class_id, is_report_card } = this.state;

    const errors = this._validateForm([
      "number_of_stars_rewarded_to_student",
      "feedback",
      "social_studies_and_science_level",
      "speaking_and_listening_level",
      "reading_and_writing_level",
      "grammar_level",
      "concentration_level",
      "reaction_level",
      "expression_level",
      "interaction_level",
    ]);

    form_data.is_report_card = is_report_card;

    const found = _.find(errors, (field) => {
      return !_.isEmpty(field);
    });

    if (!_.isEmpty(found)) {
      this._scrollTop();
      this.setState({
        form_errors: errors,
        error: "<b>Error!</b> Form field(s) can't be blank",
      });
    } else {
      this.props.saveClassRoomReportRequest({
        class_id,
        ...form_data,
        is_report_card,
      });
    }
  }

  calculateClassLearing = (array) => {

    let checked = 0, unchecked = 0
    array.slice(1).forEach(item => {
      item.slice(1).forEach(obj => {
        if (obj) {
          if (!obj.isChecked)
            unchecked++;
          else
            checked++;
        }
      })
    })

    let total = unchecked + checked
    let percentage = (unchecked / total) * 100;
    return Math.round(100 - percentage)
  }

  _handleSaveReportCard = (e) => {
    e.preventDefault();
    const { form_data, class_id, csv_data, is_report_card } = this.state;

    const errors = this._validateForm([
      "sugestion",
      "feedback",
    ]);

    const found = _.find(errors, (field) => {
      return !_.isEmpty(field);
    });

    if (!_.isEmpty(found)) {
      this._scrollTop();
      this.setState({
        form_errors: errors,
        error: "<b>Error!</b> Form field(s) can't be blank",
      });
    }
    else {
      // Remove unaselected teacheras asuggeastion area
      let teachersSuggestedArea = form_data.teacher_suggested_area.filter(function (filterItem) {
        return filterItem.isSelected === true;
      });

      let formData = {};
      let studentGrade = generateStudentGrade(form_data.strenth_and_weakness)
      formData.is_report_card = is_report_card;
      formData.feedback = form_data.feedback;
      formData.csv_report_data = JSON.stringify(csv_data);
      formData.strenth_and_weakness = JSON.stringify(form_data.strenth_and_weakness);
      formData.teacher_suggested_area = JSON.stringify(teachersSuggestedArea);
      formData.student_grade = JSON.stringify(studentGrade);

      let class_learning = this.calculateClassLearing(csv_data)
      this.setState({
        render: true,
        csv_data,
        is_report_card: true,
        is_report_generated: true,
        class_learning,
        form_data: {
          ...this.state.form_data,
          strenth_and_weakness: form_data.strenth_and_weakness,
          teacher_suggested_area: teachersSuggestedArea,
          feedback: form_data.feedback
        },
        student_grade: studentGrade,
        isReviewButtonEnabled: true
      }, () => {
        html2canvas(document.querySelector(".first_page")).then(canvas => {
          var report_card = document.querySelector(".first_page")
          var HTML_Width = report_card.getBoundingClientRect().width;
          var HTML_Height = report_card.getBoundingClientRect().height;
          var top_left_margin = 2;
          var PDF_Width = HTML_Width + (top_left_margin * 2);
          var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 1);
          var canvas_image_width = 630;
          var canvas_image_height = HTML_Height - 480;

          var imgData = canvas.toDataURL("image/jpeg");
          var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();

          const marginX = (pageWidth - canvas_image_width) / 2;
          const marginY = (pageHeight - canvas_image_height) / 2;

          pdf.addImage(imgData, 'JPG', marginX, marginY, canvas_image_width, canvas_image_height);
          pdf.addPage();
          html2canvas(document.querySelector(".second_page")).then(canvas1 => {

            var imgData1 = canvas1.toDataURL("image/jpeg");
            var report_card1 = document.querySelector(".second_page")
            var HTML_Height1 = report_card1.getBoundingClientRect().height;
            var totalPDFPages = Math.ceil(HTML_Height1 / PDF_Height) - 1;
            pdf.addImage(imgData1, 'JPG', marginX, marginY, canvas_image_width, canvas_image_height);
            for (var i = 1; i < totalPDFPages; i++) {
              pdf.addPage();
              pdf.addImage(imgData1, 'JPG', marginX, marginY, canvas_image_width, canvas_image_height);
            }

            const fileName = `UNIT_REPORT_${new Date().toISOString().replace(/[-:.]/g, "")}.pdf`;
            var pdfFile = pdf.output('datauristring');

            let body = {
              class_id,
              ...formData,
              class_learning,
              is_report_card,
              file: pdfFile,
              fileName: fileName,
              studentPhone: this.state.class_session.account.phone_number,
              studentName: this.state.class_session.account.profile.fullname,
            }
            
            this.props.saveClassRoomReportRequest(body);

            this._scrollTop();
            this.setState({
              form_errors: {},
              error: "",
            });
          })
        });


      });
    }
  };

  _validateFormField = (field, value) => {
    if (field === "feedback") {
      const errVal = _.isEmpty(value) ? MSG_INVALID_FIELD : "";
      return errVal;
    }
    else if (field === "sugestion") {
      const count = this._getSugestionCount();

      if (count === 0 || count > 3) {
        return "Select min 1 or max 3 sugestions";
      }
      else {
        return "";
      }
    }
    else if (field === "number_of_stars_rewarded_to_student") {
      if (value === 0 || value > 5) {
        return "Select more than 0 stars for the students"
      }
    }
  };

  _validateForm = (fields) => {
    const { form_data, form_errors } = this.state;
    let errors = { ...form_errors };

    _.map(fields, (name) => {
      errors = {
        ...errors,
        [name]: this._validateFormField(name, form_data[name]),
      };
      return name;
    });

    return errors;
  };

  _scrollTop = () => {
    window.scrollTo(0, 0);
  };

  _handleCheckTask = (subItem) => {
    const updatedTaskState = [];

    for (var i = 0; i < this.state.csv_data.length; i++) {
      const updatedItem = this.state.csv_data[i].map((innerItem) => {
        if (Boolean(innerItem)) {
          if (subItem.task === innerItem.task) {
            return { ...innerItem, isChecked: !subItem.isChecked }
          }
          else {
            return innerItem;
          }
        }
      });

      updatedTaskState.push(updatedItem);
    }
    this.setState({ csv_data: updatedTaskState });
  }

  _handleSetSugestions = async (sugestItem) => {

    const updatedItem = await Promise.all(this.state.form_data.teacher_suggested_area.map(async (item) => {
      if (Boolean(item)) {
        if (sugestItem.sugestion === item.sugestion) {
          return { ...item, isSelected: !sugestItem.isSelected }
        }
        else {
          return item;
        }
      }
    }));

    this.setState({ form_data: { ...this.state.form_data, teacher_suggested_area: updatedItem } }, () => {
      if (this._getSugestionCount() > 0) {
        if (this.state.form_errors.sugestion) {
          this.setState({ form_errors: { ...this.state.form_errors, sugestion: "" } });
        }
      }
    });
  }

  _getSugestionCount = () => {
    let counter = 0;
    for (const sugestion of this.state.form_data.teacher_suggested_area) {
      if (sugestion.isSelected === true) counter += 1;
    }
    return counter;
  }

  _render_student_report_card = () => {
    const { form_errors, class_session } = this.state;

    return (
      <div>
        <section className="section report-section pb0 report-card-title">
          <div className="columns">
            <div className="column pg-title">Student Report Card</div>
          </div>
        </section>

        <section className="section report-section pt10 pb0 student-unit-report-card">

          <div className="box report-box main-container-holder">


            <form>
              <div className="first_page">
                {!this.state.is_report_generated && <div className="line">
                  <div className="columns">
                    <div className="column">
                      <div className="title first is-spaced">
                        <img
                          className="image is-32x32"
                          src={ClassColorIcon}
                          alt="Class"
                        />
                        <span>
                          {`Level ${_.get(
                            class_session,
                            "course.level",
                            ""
                          )} - Unit ${_.get(
                            class_session,
                            "course.unit",
                            ""
                          )} - Lesson ${_.get(
                            class_session,
                            "course.lesson",
                            ""
                          )} - ${_.get(
                            class_session,
                            "course.name",
                            ""
                          )}`}
                        </span>
                      </div>
                      <div className="subtitle is-4 time">{`${moment(
                        class_session.start_at
                      )
                        .tz(_CONST.TIMEZONE)
                        .format("YYYY - M - D dddd h:mmA")} - ${moment(
                          class_session.end_at
                        )
                          .tz(_CONST.TIMEZONE)
                          .format("h:mmA")}`}</div>
                      <div className="columns user-details">
                        <div className="column is-4">
                          <img
                            className="image is-16x16"
                            src={UserIcon}
                            alt="User"
                          />
                          <span className="subtitle ml10">
                            Teacher:{" "}
                            {_.get(
                              class_session,
                              "teacher_account.profile.fullname",
                              ""
                            )}
                          </span>
                        </div>
                        <div className="column is-4">
                          <img
                            className="image is-16x16"
                            src={UserIcon}
                            alt="User"
                          />
                          <span className="subtitle ml10">
                            Student:{" "}
                            {_.get(
                              class_session,
                              "account.profile.fullname",
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}

                {this.state.is_report_generated && <div className="report-frame">

                  <div className="report-frame-header">
                    <div className="report-frame-logo">
                      <img src={starkid_logo} />
                    </div>
                    <div className="report-frame-grade-container">
                      <span className="grade">{this.state.student_grade.grade}</span>
                      <span className="avg">{this.state.student_grade.average}/100</span>
                    </div>
                  </div>

                  <div className="report-frame-body">
                    <p className="presented">This Report Card is Presented To</p>
                    <p className="student-name">{this.state.class_session.account.profile.fullname}</p>
                    <p className="desc">Congratulations on completing Level {this.state.class_session.course.level} Unit {this.state.class_session.course.unit} Lesson {this.state.class_session.course.lesson}.<br /> This report card is created for parents to
                      understand deeply about what the student has learned in Starkid. It includes the
                      strengths and weaknesses of student identified by the teacher,
                      and some areas of improvement that suggested by teacher. Parents also have a chance to coomunicate
                      back to teacher to align with the best learning objectives for student.</p>
                  </div>

                  <div className="report-frame-footer">
                    <div className="left">
                      <p className="teacher-name">{this.state.class_session.teacher_account.profile.fullname}</p>
                      <p>Starkid Instructor</p>
                    </div>
                    <div className="right">
                      <p className="date">{moment(this.state.class_session.assigned_at).format("YYYY-MM-DD")}</p>
                      <p>Date</p>
                    </div>
                  </div>

                </div>}

                <div className="line">
                  <div className="title is-spaced">
                    Strengths & Weaknesses
                  </div>
                  <span className="subtitle">
                    Learning Progress Summary
                  </span>
                </div>

                {
                  this.state.is_report_generated === true ? this._render_student_graph_strenth() : this._render_student_strenth_weakness()
                }

                <div className="line">
                  <div className="title is-spaced">
                    Recommendations for {" "}
                    {_.get(
                      class_session,
                      "account.profile.fullname",
                      ""
                    )}
                  </div>
                  <div className="field">
                    <div className="control">
                      {
                        this.state.is_report_generated === true ? (
                          <span className="subtitle">
                            {this.state.form_data.feedback}
                          </span>
                        ) : (
                          <textarea
                            className={`textarea ${!_.isEmpty(_.get(form_errors, "feedback", ""))
                              ? `is-danger`
                              : ``
                              }`}
                            placeholder={`Write comments for ${_.get(
                              class_session,
                              "account.profile.nickname",
                              ""
                            )} here`}
                            row="5"
                            onChange={({ target: { value } }) =>
                              this._handleChange({ field: "feedback", value })
                            }
                          ></textarea>)
                      }
                    </div>
                    <p className="help is-danger">
                      {_.get(form_errors, "feedback", "")}
                    </p>
                  </div>
                </div>

                <div className="line">
                  <div className="title is-spaced">
                    Suggested Areas to Practice
                  </div>
                  <div className="field">
                    {
                      this.state.is_report_generated === true ? this._display_teacher_suggested_area() : this._render_teacher_suggested_area()
                    }
                    <p className="help is-danger">
                      {_.get(form_errors, "sugestion", "")}
                    </p>
                  </div>
                </div>

                {this.state.is_report_generated === true && (
                  <div className="line">
                    <div className="title is-spaced">
                      Parents' Feedback
                    </div>
                    <div className="field">
                      <div className="control">

                        <span className="subtitle">
                          {Boolean(this.state.parents_feedback) ? this.state.parents_feedback : "No feedback from parents yet"}
                        </span>
                      </div>
                      <p className="help is-danger">
                        {this.state.parents_feedback_error}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="second_page">
                {
                  this.state.is_report_generated === true && this._render_parents_suggested_area()
                }

                {
                  this.state.is_report_generated && <div className="class-learning">
                    <div className="title-learning">
                      Class Learning
                    </div>

                    <div className="progress-container">
                      <div className="progress-report-advanced">
                        <div className="progress-bar-advanced" style={{ width: `${this.state.class_learning}%` }}></div>
                        <span className="percentage" style={{ color: 'green' }}>{this.state.class_learning}% Mastered</span>
                      </div>

                    </div>
                  </div>
                }
                <div className="line">
                  {
                    this.state.csv_data.map((item, mainIndex) => {
                      return (
                        <div className="report_cell" key={`key-${mainIndex}`}>
                          <div className="report_cell_header">
                            {item[0].task}
                          </div>
                          <div className="column_data_holder">
                            <ul className="unstyled centered">
                              {item.slice(1).map((subItem, index) => {
                                let checkboxId = `styled-checkbox-key-${Math.random().toString(16).slice(2)}-${index}`;
                                if (Boolean(subItem)) {
                                  return (
                                    <li key={`sub-item-${index}`}>
                                      <div className="report-text-holder">
                                        {mainIndex !== 0 ? (<>
                                          <input className="styled-checkbox" id={checkboxId} type="checkbox" value={subItem.task} defaultChecked={subItem.isChecked} onClick={() => {
                                            this._handleCheckTask(subItem);
                                          }} />
                                          <label htmlFor={checkboxId}>{subItem.task}</label>
                                        </>) : (<label>{subItem.task}</label>)}
                                      </div>
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>

                <div className="button-holder">
                  {this.state.is_report_generated === false && ((<button
                    className="button is-large"
                    onClick={this._handleSaveReportCard}
                  >
                    Submit Report
                  </button>))
                  }

                  {
                    this.state.is_acknowledge === false &&
                    (
                      <div className="button-holder">
                        <button
                          className="button is-large"
                          onClick={(e) => {
                            e.preventDefault();
                            const payload = {
                              uuid: this.state.class_session.class_report_card.uuid,
                              is_acknowledge: true
                            }
                            this.props.updateParentsSuggestions(payload);
                          }}
                        >
                          Acknowledge Report
                        </button>
                      </div>
                    )
                  }
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }

  _render_student_report = () => {
    const { form_errors, form_data, class_session } = this.state;

    return (
      <div>
        <section className="section report-section pb0">
          <div className="columns">
            <div className="column pg-title">Write Class Report</div>
          </div>
        </section>

        <section className="section report-section pt10 pb0">
          <div className="box report-box">
            <form>

              <div className="line">
                <div className="columns">
                  <div className="column">
                    <div className="title first is-spaced">
                      <img
                        className="image is-32x32"
                        src={ClassColorIcon}
                        alt="Class"
                      />
                      <span>
                        {`Level ${_.get(
                          class_session,
                          "course.level",
                          ""
                        )} - Unit ${_.get(
                          class_session,
                          "course.unit",
                          ""
                        )} - Lesson ${_.get(
                          class_session,
                          "course.lesson",
                          ""
                        )} - ${_.get(
                          class_session,
                          "course.name",
                          ""
                        )}`}
                      </span>
                    </div>
                    <div className="subtitle is-4 time">{`${moment(
                      class_session.start_at
                    )
                      .tz(_CONST.TIMEZONE)
                      .format("YYYY - M - D dddd h:mmA")} - ${moment(
                        class_session.end_at
                      )
                        .tz(_CONST.TIMEZONE)
                        .format("h:mmA")}`}</div>
                    <div className="columns user-details">
                      <div className="column is-4">
                        <img
                          className="image is-16x16"
                          src={UserIcon}
                          alt="User"
                        />
                        <span className="subtitle ml10">
                          Teacher:{" "}
                          {_.get(
                            class_session,
                            "teacher_account.profile.fullname",
                            ""
                          )}
                        </span>
                      </div>
                      <div className="column is-4">
                        <img
                          className="image is-16x16"
                          src={UserIcon}
                          alt="User"
                        />
                        <span className="subtitle ml10">
                          Student:{" "}
                          {_.get(
                            class_session,
                            "account.profile.fullname",
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="line">
                <div className="title is-spaced">
                  How many stars given to{" "}
                  {_.get(class_session, "account.profile.nickname", "")}
                </div>
                <div className="field">
                  <fieldset>
                    <span
                      className="c-rating c-rating__big"
                      data-rating-value={
                        form_data.number_of_stars_rewarded_to_student
                      }
                    >
                      <input
                        type="text"
                        defaultValue="1"
                        onClick={({ target: { value } }) =>
                          this._handleChange({
                            field: "number_of_stars_rewarded_to_student",
                            value: parseInt(value),
                          })
                        }
                      />
                      <input
                        type="text"
                        defaultValue="2"
                        onClick={({ target: { value } }) =>
                          this._handleChange({
                            field: "number_of_stars_rewarded_to_student",
                            value: parseInt(value),
                          })
                        }
                      />
                      <input
                        type="text"
                        defaultValue="3"
                        onClick={({ target: { value } }) =>
                          this._handleChange({
                            field: "number_of_stars_rewarded_to_student",
                            value: parseInt(value),
                          })
                        }
                      />
                      <input
                        type="text"
                        defaultValue="4"
                        onClick={({ target: { value } }) =>
                          this._handleChange({
                            field: "number_of_stars_rewarded_to_student",
                            value: parseInt(value),
                          })
                        }
                      />
                      <input
                        type="text"
                        defaultValue="5"
                        onClick={({ target: { value } }) =>
                          this._handleChange({
                            field: "number_of_stars_rewarded_to_student",
                            value: parseInt(value),
                          })
                        }
                      />
                    </span>
                  </fieldset>
                  <p className="help is-danger">
                    {_.get(
                      form_errors,
                      "number_of_stars_rewarded_to_student",
                      ""
                    )}
                  </p>
                </div>
              </div>

              <div className="line">
                <div className="title is-spaced">Message</div>
                <div className="field">
                  <div className="control">
                    <textarea
                      className={`textarea ${!_.isEmpty(_.get(form_errors, "feedback", ""))
                        ? `is-danger`
                        : ``
                        }`}
                      placeholder={`Write comments for ${_.get(
                        class_session,
                        "account.profile.nickname",
                        ""
                      )} here`}
                      row="5"
                      onChange={({ target: { value } }) =>
                        this._handleChange({ field: "feedback", value })
                      }
                    ></textarea>
                  </div>
                  <p className="help is-danger">
                    {_.get(form_errors, "feedback", "")}
                  </p>
                </div>
              </div>

              <div className="line">
                <h4 className="title is-spaced">
                  How is the learning progress for{" "}
                  {_.get(class_session, "account.profile.nickname", "")}
                </h4>
                <div className="columns mb0">
                  <div className="column is-half">
                    <span className="subtitle">
                      Social Studies and Science
                    </span>
                  </div>
                  <div className="column is-half">
                    <div className="control">
                      <label className="radio">
                        <input
                          id="sss_level_1"
                          name="report[sss_level]"
                          type="radio"
                          value="1"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "social_studies_and_science_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Poor
                      </label>
                      <label className="radio">
                        <input
                          id="sss_level_2"
                          name="report[sss_level]"
                          type="radio"
                          value="2"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "social_studies_and_science_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Fair
                      </label>
                      <label className="radio">
                        <input
                          id="sss_level_3"
                          name="report[sss_level]"
                          type="radio"
                          value="3"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "social_studies_and_science_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Good
                      </label>
                      <label className="radio">
                        <input
                          id="sss_level_4"
                          name="report[sss_level]"
                          type="radio"
                          value="4"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "social_studies_and_science_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Great
                      </label>
                      <label className="radio">
                        <input
                          id="sss_level_5"
                          name="report[sss_level]"
                          type="radio"
                          value="5"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "social_studies_and_science_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Excellent
                      </label>
                    </div>
                  </div>
                </div>
                <p className="help is-danger">
                  {_.get(
                    form_errors,
                    "social_studies_and_science_level",
                    ""
                  )}
                </p>

                <div className="columns mb0">
                  <div className="column is-half">
                    <span className="subtitle">
                      Speaking and Listening
                    </span>
                  </div>
                  <div className="column is-half">
                    <div className="control">
                      <label className="radio">
                        <input
                          id="sl_level_1"
                          name="report[sl_level]"
                          type="radio"
                          value="1"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "speaking_and_listening_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Poor
                      </label>
                      <label className="radio">
                        <input
                          id="sl_level_2"
                          name="report[sl_level]"
                          type="radio"
                          value="2"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "speaking_and_listening_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Fair
                      </label>
                      <label className="radio">
                        <input
                          id="sl_level_3"
                          name="report[sl_level]"
                          type="radio"
                          value="3"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "speaking_and_listening_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Good
                      </label>
                      <label className="radio">
                        <input
                          id="sl_level_4"
                          name="report[sl_level]"
                          type="radio"
                          value="4"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "speaking_and_listening_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Great
                      </label>
                      <label className="radio">
                        <input
                          id="sl_level_5"
                          name="report[sl_level]"
                          type="radio"
                          value="5"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "speaking_and_listening_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Excellent
                      </label>
                    </div>
                  </div>
                </div>
                <p className="help is-danger">
                  {_.get(form_errors, "speaking_and_listening_level", "")}
                </p>

                <div className="columns mb0">
                  <div className="column is-half">
                    <span className="subtitle">Reading and Writing</span>
                  </div>
                  <div className="column is-half">
                    <div className="control">
                      <label className="radio">
                        <input
                          id="rw_level_1"
                          name="report[rw_level]"
                          type="radio"
                          value="1"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "reading_and_writing_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Poor
                      </label>
                      <label className="radio">
                        <input
                          id="rw_level_2"
                          name="report[rw_level]"
                          type="radio"
                          value="2"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "reading_and_writing_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Fair
                      </label>
                      <label className="radio">
                        <input
                          id="rw_level_3"
                          name="report[rw_level]"
                          type="radio"
                          value="3"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "reading_and_writing_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Good
                      </label>
                      <label className="radio">
                        <input
                          id="rw_level_4"
                          name="report[rw_level]"
                          type="radio"
                          value="4"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "reading_and_writing_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Great
                      </label>
                      <label className="radio">
                        <input
                          id="rw_level_5"
                          name="report[rw_level]"
                          type="radio"
                          value="5"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "reading_and_writing_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Excellent
                      </label>
                    </div>
                  </div>
                </div>
                <p className="help is-danger">
                  {_.get(form_errors, "reading_and_writing_level", "")}
                </p>

                <div className="columns mb0">
                  <div className="column is-half">
                    <span className="subtitle">Grammar</span>
                  </div>
                  <div className="column is-half">
                    <div className="control">
                      <label className="radio">
                        <input
                          id="grammar_level_1"
                          name="report[grammar_level]"
                          type="radio"
                          value="1"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "grammar_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Poor
                      </label>
                      <label className="radio">
                        <input
                          id="grammar_level_2"
                          name="report[grammar_level]"
                          type="radio"
                          value="2"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "grammar_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Fair
                      </label>
                      <label className="radio">
                        <input
                          id="grammar_level_3"
                          name="report[grammar_level]"
                          type="radio"
                          value="3"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "grammar_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Good
                      </label>
                      <label className="radio">
                        <input
                          id="grammar_level_4"
                          name="report[grammar_level]"
                          type="radio"
                          value="4"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "grammar_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Great
                      </label>
                      <label className="radio">
                        <input
                          id="grammar_level_5"
                          name="report[grammar_level]"
                          type="radio"
                          value="5"
                          onChange={({ target: { value } }) =>
                            this._handleChange({
                              field: "grammar_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        &nbsp;Excellent
                      </label>
                    </div>
                  </div>
                </div>
                <p className="help is-danger">
                  {_.get(form_errors, "grammar_level", "")}
                </p>
              </div>

              <div className="line">
                <h4 className="title is-spaced">Class Performance</h4>
                <div className="columns mb0">
                  <div className="column is-half">
                    <span className="subtitle">Concentration</span>
                  </div>
                  <div className="column is-half">
                    <fieldset>
                      <span
                        className="c-rating c-rating__small"
                        data-rating-value={form_data.concentration_level}
                      >
                        <input
                          type="text"
                          defaultValue="1"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "concentration_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="2"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "concentration_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="3"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "concentration_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="4"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "concentration_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="5"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "concentration_level",
                              value: parseInt(value),
                            })
                          }
                        />
                      </span>
                    </fieldset>
                  </div>
                </div>
                <p className="help is-danger">
                  {_.get(form_errors, "concentration_level", "")}
                </p>

                <div className="columns mb0">
                  <div className="column is-half">
                    <span className="subtitle">Reaction</span>
                  </div>
                  <div className="column is-half">
                    <fieldset>
                      <span
                        className="c-rating c-rating__small"
                        data-rating-value={form_data.reaction_level}
                      >
                        <input
                          type="text"
                          defaultValue="1"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "reaction_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="2"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "reaction_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="3"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "reaction_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="4"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "reaction_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="5"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "reaction_level",
                              value: parseInt(value),
                            })
                          }
                        />
                      </span>
                    </fieldset>
                  </div>
                </div>
                <p className="help is-danger">
                  {_.get(form_errors, "reaction_level", "")}
                </p>

                <div className="columns mb0">
                  <div className="column is-half">
                    <span className="subtitle">Expression</span>
                  </div>
                  <div className="column is-half">
                    <fieldset>
                      <span
                        className="c-rating c-rating__small"
                        data-rating-value={form_data.expression_level}
                      >
                        <input
                          type="text"
                          defaultValue="1"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "expression_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="2"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "expression_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="3"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "expression_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="4"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "expression_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="5"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "expression_level",
                              value: parseInt(value),
                            })
                          }
                        />
                      </span>
                    </fieldset>
                  </div>
                </div>
                <p className="help is-danger">
                  {_.get(form_errors, "expression_level", "")}
                </p>

                <div className="columns mb0">
                  <div className="column is-half">
                    <span className="subtitle">Interaction</span>
                  </div>
                  <div className="column is-half">
                    <fieldset>
                      <span
                        className="c-rating c-rating__small"
                        data-rating-value={form_data.interaction_level}
                      >
                        <input
                          type="text"
                          defaultValue="1"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "interaction_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="2"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "interaction_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="3"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "interaction_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="4"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "interaction_level",
                              value: parseInt(value),
                            })
                          }
                        />
                        <input
                          type="text"
                          defaultValue="5"
                          onClick={({ target: { value } }) =>
                            this._handleChange({
                              field: "interaction_level",
                              value: parseInt(value),
                            })
                          }
                        />
                      </span>
                    </fieldset>
                  </div>
                </div>
                <p className="help is-danger">
                  {_.get(form_errors, "interaction_level", "")}
                </p>
              </div>

              <div>
                <button
                  className="button is-large is-primary"
                  onClick={this._handleSaveReport}
                >
                  Save Report
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }

  render() {
    const { render, error, is_report_card } = this.state;
    return (
      <div>
        <div className="container">
          {!_.isEmpty(error) && (
            <section className="section pb0 pt0">
              <div
                className="notification"
                dangerouslySetInnerHTML={{ __html: error }}
              ></div>
            </section>
          )}
          {render === true && is_report_card === true ? this._render_student_report_card() : this._render_student_report()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    class_session: _.get(state, "room.class_session", {}),
    save_report: _.get(state, "room.save_report", {}),
    user: _.get(state, "auth.login.result.response.data", {}),
    update_parents_suggestions: _.get(state, "room.update_parents_suggestions", {}),
    upload_pdf_report: _.get(state, "room.upload_pdf_send_sms", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClassRoomSessionRequest: (payload) =>
    dispatch({ type: reduxActions.GET_CLASS_ROOM_SESSION_REQUEST, payload }),
  saveClassRoomReportRequest: (payload) =>
    dispatch({ type: reduxActions.SAVE_CLASS_ROOM_REPORT_REQUEST, payload }),
  storeNotificationRequest: (payload) =>
    dispatch({ type: reduxActions.STORE_NOTIFICATION_REQUEST, payload }),
  updateParentsSuggestions: (payload) =>
    dispatch({ type: reduxActions.PARENTS_SUGGESTIONS_REQUEST, payload }),
  uploadPdfUnitReport: (payload) =>
    dispatch({ type: reduxActions.UPLOAD_PDF_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WriteReport);


