import React, { Component } from 'react';
class VideoRecordingNote extends Component {
    render() {
        return (
            <div className='browserNote'>
                <div><p className='headNote'>NOTE</p>
                    <p>This lesson is marked to record the video and video recording has not started yet. Please record video by clicking on RECORD VIDEO button</p>
                </div>
            </div>
        );
    }
}

export default VideoRecordingNote;