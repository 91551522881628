import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class Popup extends Component {
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };
    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div>
                <div className="jw-modal">
                    <div className="jw-modal-body">
                        {this.props.children}
                        <div className="modal-button">
                            <button className="toggle-button" onClick={this.onClose}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
                <div className="jw-modal-background"></div>
            </div>
        );
    }
}
Popup.propTypes = {
    onClose: PropTypes.func,
    show: PropTypes.bool
};