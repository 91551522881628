import { all, fork, put, takeLatest } from "redux-saga/effects";
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* storeNotificationWorker(action) {
  try {
    yield put({
      type: reduxActions.STORE_NOTIFICATION_SUCCESS,
      payload: action.payload
    });
  } catch {
    yield put({
      type: reduxActions.STORE_NOTIFICATION_FAILURE,
      payload: ''
    });
  }
}

/* WATCHERS SAGA */

// Watcher for storing notification message
export function* storeNotificationWatcher() {
  yield takeLatest(reduxActions.STORE_NOTIFICATION_REQUEST, storeNotificationWorker);
}

export default function* rootSaga() {
  yield all([
    fork(storeNotificationWatcher),
  ]);
}
