import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  getTwilioToken,
  getClassRoomSlides,
  getClassRoomSession,
  getAllClassRoomSession,
  getClassRoomDrawing,
  saveClassRoomDrawing,
  removeClassRoomDrawing,
  saveClassRoomReport,
  getClassRoomReport,
  getAllPets,
  getAPet,
  getWalletDetail,
  getClassRoomSliderSlides,
  updateClassroom,
  getAllRoomSessionsByTeacher,
  callClassStudent,
  updateParentsSuggestions,
  uploadPdfUnitReport,
  restricTextForStudent,
  screenSharedByTeacher,
  saveClassRoomVideo,
  getClassRoomVideos,
  updateTrackingTime,
  getChineseFeedback
} from "../../services/classroom.service";
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* getTwilioTokenWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getTwilioToken, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_TWILIO_TOKEN_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_TWILIO_TOKEN_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getClassRoomSlidesWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getClassRoomSlides, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_CLASS_ROOM_SLIDES_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_CLASS_ROOM_SLIDES_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getClassRoomVideosWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getClassRoomVideos, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_CLASS_ROOM_VIDEOS_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_CLASS_ROOM_VIDEOS_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getClassRoomSliderSlidesWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getClassRoomSliderSlides, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_CLASS_ROOM_SLIDER_SLIDES_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_CLASS_ROOM_SLIDER_SLIDES_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getClassRoomSessionWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getClassRoomSession, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_CLASS_ROOM_SESSION_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_CLASS_ROOM_SESSION_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getRoomSessionsByTeacherWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllRoomSessionsByTeacher, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_ROOM_SESSIONS_SUCCESS_BY_TEACHER,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_ROOM_SESSIONS_FAILURE_BY_TEACHER,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* callClassStudentsWorker(action){
  try {
    yield put({type: reduxActions.SHOW_PAGE_LOADER});
    const resp = yield call(callClassStudent, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.CALL_CLASS_STUDENT_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield({
    type: reduxActions.CALL_CLASS_STUDENT_FAILURE,
    payload: error,
  });
  yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getClassRoomDrawingWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getClassRoomDrawing, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_CLASS_ROOM_DRAWING_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_CLASS_ROOM_DRAWING_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* saveClassRoomVideoWorker(action) {
  try {
    const resp = yield call(saveClassRoomVideo, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.SAVE_CLASS_ROOM_VIDEO_SUCCESS,
        payload: resp,
      });
    }
  } catch (error) {
    yield put({
      type: reduxActions.SAVE_CLASS_ROOM_VIDEO_FAILURE,
      payload: error,
    });
  }
}

function* saveClassRoomDrawingWorker(action) {
  try {
    const resp = yield call(saveClassRoomDrawing, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.SAVE_CLASS_ROOM_DRAWING_SUCCESS,
        payload: resp,
      });
    }
  } catch (error) {
    yield put({
      type: reduxActions.SAVE_CLASS_ROOM_DRAWING_FAILURE,
      payload: error,
    });
  }
}

function* restrictTextWorker(action) {
  try {
    const resp = yield call(restricTextForStudent, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.RESTRICT_TEXT_FOR_STUDENT_SUCCESS,
        payload: resp,
      });
    }
  } catch (error) {
    yield put({
      type: reduxActions.RESTRICT_TEXT_FOR_STUDENT_FAILURE,
      payload: error,
    });
  }
}

function* screenSharingWorker(action) {
  try {
    const resp = yield call(screenSharedByTeacher, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.SCREEN_SHARED_BY_TEACHER_SUCCESS,
        payload: resp,
      });
    }
  } catch (error) {
    yield put({
      type: reduxActions.SCREEN_SHARED_BY_TEACHER_FAILURE,
      payload: error,
    });
  }
}

function* removeClassRoomDrawingWorker(action) {
  try {
    const resp = yield call(removeClassRoomDrawing, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.REMOVE_CLASS_ROOM_DRAWING_SUCCESS,
        payload: resp,
      });
    }
  } catch (error) {
    yield put({
      type: reduxActions.REMOVE_CLASS_ROOM_DRAWING_FAILURE,
      payload: error,
    });
  }
}

function* getAllClassRoomSessionWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllClassRoomSession, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_CLASS_ROOM_SESSION_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_CLASS_ROOM_SESSION_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* saveClassRoomReportWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(saveClassRoomReport, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.SAVE_CLASS_ROOM_REPORT_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.SAVE_CLASS_ROOM_REPORT_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getClassRoomReportWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getClassRoomReport, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_CLASS_ROOM_REPORT_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_CLASS_ROOM_REPORT_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getAllPetsWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllPets, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_PETS_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_PETS_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getAPetWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAPet, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_A_PET_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_A_PET_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getWalletDetailWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getWalletDetail, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_WALLET_DETAIL_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_WALLET_DETAIL_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* updateClassroomSessionWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(updateClassroom, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_CLASS_ROOM_SESSION_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_CLASS_ROOM_SESSION_SUCCESS,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* updateTrackingTimeWorker(action) {
  try {
    const resp = yield call(updateTrackingTime, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.UPDATE_TRACKING_TIME_SUCCESS,
        payload: resp,
      });
    }
  } catch (error) {
    yield put({
      type: reduxActions.UPDATE_TRACKING_TIME_FAILURE,
      payload: error,
    });
  }
}

function* updateParentsSugestionsWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(updateParentsSuggestions, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.PARENTS_SUGGESTIONS_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.PARENTS_SUGGESTIONS_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getChineseFeedbackWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getChineseFeedback, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_CHINESE_FEEDBACK_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_CHINESE_FEEDBACK_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

/* WATCHERS SAGA */


export function* updateParentsSugestionWatcher() {
  yield takeLatest(
    reduxActions.PARENTS_SUGGESTIONS_REQUEST,
    updateParentsSugestionsWorker
  );
}

export function* getRoomSessionsByTeacherWatcher() {
  yield takeLatest(
    reduxActions.GET_ALL_ROOM_SESSIONS_REQUEST_BY_TEACHER,
    getRoomSessionsByTeacherWorker
  );
}

export function* callClassStudents(){
  yield takeLatest(
    reduxActions.CALL_CLASS_STUDENT_REQUEST,
    callClassStudentsWorker
  )
}

// Watcher to get twilio token
export function* getTwilioTokenWatcher() {
  yield takeLatest(reduxActions.GET_TWILIO_TOKEN_REQUEST, getTwilioTokenWorker);
}

// Watcher to get class room slides
export function* getClassRoomSlidesWatcher() {
  yield takeLatest(
    reduxActions.GET_CLASS_ROOM_SLIDES_REQUEST,
    getClassRoomSlidesWorker
  );
}

// Watcher to get class room videos
export function* getClassRoomVideoWatcher() {
  yield takeLatest(
    reduxActions.GET_CLASS_ROOM_VIDEOS_REQUEST,
    getClassRoomVideosWorker
  );
}

// Watcher to get class room slider page slides
export function* getClassRoomSliderSlidesWatcher() {
  yield takeLatest(
    reduxActions.GET_CLASS_ROOM_SLIDER_SLIDES_REQUEST,
    getClassRoomSliderSlidesWorker
  );
}

// Watcher to get class room session details
export function* getClassRoomSessionWatcher() {
  yield takeLatest(
    reduxActions.GET_CLASS_ROOM_SESSION_REQUEST,
    getClassRoomSessionWorker
  );
}

// Watcher to get class room slides drawing
export function* getClassRoomDrawingWatcher() {
  yield takeLatest(
    reduxActions.GET_CLASS_ROOM_DRAWING_REQUEST,
    getClassRoomDrawingWorker
  );
}

// Watcher to save class room slides drawing
export function* saveClassRoomVideoWatcher() {
  yield takeLatest(
    reduxActions.SAVE_CLASS_ROOM_VIDEO_REQUEST,
    saveClassRoomVideoWorker
  );
}

// Watcher to save class room slides drawing
export function* saveClassRoomDrawingWatcher() {
  yield takeLatest(
    reduxActions.SAVE_CLASS_ROOM_DRAWING_REQUEST,
    saveClassRoomDrawingWorker
  );
}

export function* restrictTextWatcher() {
  yield takeLatest(
    reduxActions.RESTRICT_TEXT_FOR_STUDENT_REQUEST,
    restrictTextWorker
  );
}

// Watcher to save screen sharing detail
export function* screenSharingWatcher() {
  yield takeLatest(
    reduxActions.SCREEN_SHARED_BY_TEACHER_REQUEST,
    screenSharingWorker
  );
}

// Watcher to remove class room slides drawing
export function* removeClassRoomDrawingWatcher() {
  yield takeLatest(
    reduxActions.REMOVE_CLASS_ROOM_DRAWING_REQUEST,
    removeClassRoomDrawingWorker
  );
}

// Watcher to get all class room sessions
export function* getAllClassRoomSessionWatcher() {
  yield takeLatest(
    reduxActions.GET_ALL_CLASS_ROOM_SESSION_REQUEST,
    getAllClassRoomSessionWorker
  );
}

// Watcher to save class report
export function* saveClassRoomReportWatcher() {
  yield takeLatest(
    reduxActions.SAVE_CLASS_ROOM_REPORT_REQUEST,
    saveClassRoomReportWorker
  );
}

// Watcher to get class report
export function* getClassRoomReportWatcher() {
  yield takeLatest(
    reduxActions.GET_CLASS_ROOM_REPORT_REQUEST,
    getClassRoomReportWorker
  );
}

// Watcher to get all pets
export function* getAllPetsWatcher() {
  yield takeLatest(reduxActions.GET_ALL_PETS_REQUEST, getAllPetsWorker);
}

// Watcher to get a pet
export function* getAPetWatcher() {
  yield takeLatest(reduxActions.GET_A_PET_REQUEST, getAPetWorker);
}

// Watcher to get wallet detail
export function* getWalletDetailWatcher() {
  yield takeLatest(
    reduxActions.GET_WALLET_DETAIL_REQUEST,
    getWalletDetailWorker
  );
}

// Watcher to update class room status
export function* updateClassroomSession() {
  yield takeLatest(
    reduxActions.UPDATE_CLASS_ROOM_SESSION_REQUEST,
    updateClassroomSessionWorker
  );
}

// Watcher to update tracking time in room
export function* updateClassroomTrackingTime() {
  yield takeLatest(
    reduxActions.UPDATE_TRACKING_TIME_REQUEST,
    updateTrackingTimeWorker
  );
}

// Watcher to get chinese feedback
export function* getChineseFeedbackWatcher() {
  yield takeLatest(
    reduxActions.GET_CHINESE_FEEDBACK_REQUEST,
    getChineseFeedbackWorker
  );
}

export default function* rootSaga() {
  yield all([
    fork(getTwilioTokenWatcher),
    fork(getClassRoomSlidesWatcher),
    fork(getClassRoomSliderSlidesWatcher),
    fork(getClassRoomSessionWatcher),
    fork(getClassRoomDrawingWatcher),
    fork(saveClassRoomDrawingWatcher),
    fork(removeClassRoomDrawingWatcher),
    fork(getAllClassRoomSessionWatcher),
    fork(saveClassRoomReportWatcher),
    fork(getClassRoomReportWatcher),
    fork(getAllPetsWatcher),
    fork(getAPetWatcher),
    fork(getWalletDetailWatcher),
    fork(updateClassroomSession),
    fork(updateClassroomTrackingTime),
    fork(getRoomSessionsByTeacherWatcher),
    fork(callClassStudents),
    fork(updateParentsSugestionWatcher),
    fork(restrictTextWatcher),
    fork(screenSharingWatcher),
    fork(saveClassRoomVideoWatcher),
    fork(getClassRoomVideoWatcher),
    fork(getChineseFeedbackWatcher)
  ]);
}
