export const SHOW_PAGE_LOADER = "SHOW_PAGE_LOADER";
export const HIDE_PAGE_LOADER = "HIDE_PAGE_LOADER";

export const STORE_NOTIFICATION_REQUEST = "STORE_NOTIFICATION_REQUEST";
export const STORE_NOTIFICATION_SUCCESS = "STORE_NOTIFICATION_SUCCESS";
export const STORE_NOTIFICATION_FAILURE = "STORE_NOTIFICATION_FAILURE";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const STORE_AUTO_LOGIN_DATA_REQUEST = "STORE_AUTO_LOGIN_DATA_REQUEST";
export const STORE_AUTO_LOGIN_DATA_SUCCESS = "STORE_AUTO_LOGIN_DATA_SUCCESS";
export const STORE_AUTO_LOGIN_DATA_FAILURE = "STORE_AUTO_LOGIN_DATA_FAILURE";

export const GET_TWILIO_TOKEN_REQUEST = "GET_TWILIO_TOKEN_REQUEST";
export const GET_TWILIO_TOKEN_SUCCESS = "GET_TWILIO_TOKEN_SUCCESS";
export const GET_TWILIO_TOKEN_FAILURE = "GET_TWILIO_TOKEN_FAILURE";

export const GET_CLASS_ROOM_SLIDES_REQUEST = "GET_CLASS_ROOM_SLIDES_REQUEST";
export const GET_CLASS_ROOM_SLIDES_SUCCESS = "GET_CLASS_ROOM_SLIDES_SUCCESS";
export const GET_CLASS_ROOM_SLIDES_FAILURE = "GET_CLASS_ROOM_SLIDES_FAILURE";

export const CALL_CLASS_STUDENT_REQUEST = "CALL_CLASS_STUDENT_REQUEST";
export const CALL_CLASS_STUDENT_SUCCESS = "CALL_CLASS_STUDENT_SUCCESS";
export const CALL_CLASS_STUDENT_FAILURE = "CALL_CLASS_STUDENT_FAILURE";

export const GET_CLASS_ROOM_VIDEOS_REQUEST = "GET_CLASS_ROOM_VIDEOS_REQUEST";
export const GET_CLASS_ROOM_VIDEOS_SUCCESS = "GET_CLASS_ROOM_VIDEOS_SUCCESS";
export const GET_CLASS_ROOM_VIDEOS_FAILURE = "GET_CLASS_ROOM_VIDEOS_FAILURE";

export const GET_CLASS_ROOM_SLIDER_SLIDES_REQUEST =
  "GET_CLASS_ROOM_SLIDER_SLIDES_REQUEST";
export const GET_CLASS_ROOM_SLIDER_SLIDES_SUCCESS =
  "GET_CLASS_ROOM_SLIDER_SLIDES_SUCCESS";
export const GET_CLASS_ROOM_SLIDER_SLIDES_FAILURE =
  "GET_CLASS_ROOM_SLIDER_SLIDES_FAILURE";

export const GET_CLASS_ROOM_SESSION_REQUEST = "GET_CLASS_ROOM_SESSION_REQUEST";
export const GET_CLASS_ROOM_SESSION_SUCCESS = "GET_CLASS_ROOM_SESSION_SUCCESS";
export const GET_CLASS_ROOM_SESSION_FAILURE = "GET_CLASS_ROOM_SESSION_FAILURE";

export const SAVE_CLASS_ROOM_DRAWING_REQUEST =
  "SAVE_CLASS_ROOM_DRAWING_REQUEST";
export const SAVE_CLASS_ROOM_DRAWING_SUCCESS =
  "SAVE_CLASS_ROOM_DRAWING_SUCCESS";
export const SAVE_CLASS_ROOM_DRAWING_FAILURE =
  "SAVE_CLASS_ROOM_DRAWING_FAILURE";
export const UPDATE_CLASS_ROOM_SESSION_REQUEST =
  "UPDATE_CLASS_ROOM_SESSION_REQUEST";

export const UPDATE_TRACKING_TIME_REQUEST = "UPDATE_TRACKING_TIME_REQUEST";
export const UPDATE_TRACKING_TIME_SUCCESS = "UPDATE_TRACKING_TIME_SUCCESS";
export const UPDATE_TRACKING_TIME_FAILURE = "UPDATE_TRACKING_TIME_FAILURE";

export const SAVE_CLASS_ROOM_VIDEO_REQUEST = "SAVE_CLASS_ROOM_VIDEO_REQUEST";
export const SAVE_CLASS_ROOM_VIDEO_SUCCESS = "SAVE_CLASS_ROOM_VIDEO_SUCCESS";
export const SAVE_CLASS_ROOM_VIDEO_FAILURE = "SAVE_CLASS_ROOM_VIDEO_FAILURE";

export const REMOVE_CLASS_ROOM_DRAWING_REQUEST =
  "REMOVE_CLASS_ROOM_DRAWING_REQUEST";
export const REMOVE_CLASS_ROOM_DRAWING_SUCCESS =
  "REMOVE_CLASS_ROOM_DRAWING_SUCCESS";
export const REMOVE_CLASS_ROOM_DRAWING_FAILURE =
  "REMOVE_CLASS_ROOM_DRAWING_FAILURE";

export const GET_CLASS_ROOM_DRAWING_REQUEST = "GET_CLASS_ROOM_DRAWING_REQUEST";
export const GET_CLASS_ROOM_DRAWING_SUCCESS = "GET_CLASS_ROOM_DRAWING_SUCCESS";
export const GET_CLASS_ROOM_DRAWING_FAILURE = "GET_CLASS_ROOM_DRAWING_FAILURE";

export const GET_ALL_CLASS_ROOM_SESSION_REQUEST =
  "GET_ALL_CLASS_ROOM_SESSION_REQUEST";
export const GET_ALL_CLASS_ROOM_SESSION_SUCCESS =
  "GET_ALL_CLASS_ROOM_SESSION_SUCCESS";
export const GET_ALL_CLASS_ROOM_SESSION_FAILURE =
  "GET_ALL_CLASS_ROOM_SESSION_FAILURE";

export const SAVE_CLASS_ROOM_REPORT_REQUEST = "SAVE_CLASS_ROOM_REPORT_REQUEST";
export const SAVE_CLASS_ROOM_REPORT_SUCCESS = "SAVE_CLASS_ROOM_REPORT_SUCCESS";
export const SAVE_CLASS_ROOM_REPORT_FAILURE = "SAVE_CLASS_ROOM_REPORT_FAILURE";

export const GET_CLASS_ROOM_REPORT_REQUEST = "GET_CLASS_ROOM_REPORT_REQUEST";
export const GET_CLASS_ROOM_REPORT_SUCCESS = "GET_CLASS_ROOM_REPORT_SUCCESS";
export const GET_CLASS_ROOM_REPORT_FAILURE = "GET_CLASS_ROOM_REPORT_FAILURE";

export const GET_ALL_PETS_REQUEST = "GET_ALL_PETS_REQUEST";
export const GET_ALL_PETS_SUCCESS = "GET_ALL_PETS_SUCCESS";
export const GET_ALL_PETS_FAILURE = "GET_ALL_PETS_FAILURE";

export const GET_A_PET_REQUEST = "GET_A_PET_REQUEST";
export const GET_A_PET_SUCCESS = "GET_A_PET_SUCCESS";
export const GET_A_PET_FAILURE = "GET_A_PET_FAILURE";

export const GET_WALLET_DETAIL_REQUEST = "GET_WALLET_DETAIL_REQUEST";
export const GET_WALLET_DETAIL_SUCCESS = "GET_WALLET_DETAIL_SUCCESS";
export const GET_WALLET_DETAIL_FAILURE = "GET_WALLET_DETAIL_FAILURE";

export const GET_ALL_COURSE_REQUEST = "GET_ALL_COURSE_REQUEST";
export const GET_ALL_COURSE_SUCCESS = "GET_ALL_COURSE_SUCCESS";
export const GET_ALL_COURSE_FAILURE = "GET_ALL_COURSE_FAILURE";

export const GET_ALL_USER_EVENT_REQUEST = "GET_ALL_USER_EVENT_REQUEST";
export const GET_ALL_USER_EVENT_SUCCESS = "GET_ALL_USER_EVENT_SUCCESS";
export const GET_ALL_USER_EVENT_FAILURE = "GET_ALL_USER_EVENT_FAILURE";

export const GET_ALL_STUDENT_REQUEST = "GET_ALL_STUDENT_REQUEST";
export const GET_ALL_STUDENT_SUCCESS = "GET_ALL_STUDENT_SUCCESS";
export const GET_ALL_STUDENT_FAILURE = "GET_ALL_STUDENT_FAILURE";

export const GET_ALL_CARDS_REQUEST = "GET_ALL_CARDS_REQUEST";
export const GET_ALL_CARDS_SUCCESS = "GET_ALL_CARDS_SUCCESS";
export const GET_ALL_CARDS_FAILURE = "GET_ALL_CARDS_FAILURE";

export const ADD_CARD_REQUEST = "ADD_CARD_REQUEST";
export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS";
export const ADD_CARD_FAILURE = "ADD_CARD_FAILURE";

export const ADD_EVENT_REQUEST = "ADD_EVENT_REQUEST";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAILURE = "ADD_EVENT_FAILURE";

export const REMOVE_CARD_REQUEST = "REMOVE_CARD_REQUEST";
export const REMOVE_CARD_SUCCESS = "REMOVE_CARD_SUCCESS";
export const REMOVE_CARD_FAILURE = "REMOVE_CARD_FAILURE";

export const REMOVE_EVENT_REQUEST = "REMOVE_EVENT_REQUEST";
export const REMOVE_EVENT_SUCCESS = "REMOVE_EVENT_SUCCESS";
export const REMOVE_EVENT_FAILURE = "REMOVE_EVENT_FAILURE";

export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";

export const CHARGE_CARD_REQUEST = "CHARGE_CARD_REQUEST";
export const CHARGE_CARD_SUCCESS = "CHARGE_CARD_SUCCESS";
export const CHARGE_CARD_FAILURE = "CHARGE_CARD_FAILURE";

export const USER_REGISTRATION_REQUEST = "USER_REGISTRATION_REQUEST";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_REGISTRATION_FAILURE = "USER_REGISTRATION_FAILURE";

export const GET_ALL_ROOM_SESSIONS_REQUEST_BY_TEACHER =
  "GET_ALL_ROOM_SESSIONS_REQUEST_BY_TEACHER";
export const GET_ALL_ROOM_SESSIONS_SUCCESS_BY_TEACHER =
  "GET_ALL_ROOM_SESSIONS_SUCCESS_BY_TEACHER";
export const GET_ALL_ROOM_SESSIONS_FAILURE_BY_TEACHER =
  "GET_ALL_ROOM_SESSIONS_FAILURE_BY_TEACHER";

export const PARENTS_SUGGESTIONS_REQUEST = "PARENTS_SUGGESTIONS_REQUEST";
export const PARENTS_SUGGESTIONS_SUCCESS = "PARENTS_SUGGESTIONS_SUCCESS";
export const PARENTS_SUGGESTIONS_FAILURE = "PARENTS_SUGGESTIONS_FAILURE";

export const UPLOAD_PDF_REQUEST = "UPLOAD_PDF_REQUEST";
export const UPLOAD_PDF_SUCCESS = "UPLOAD_PDF_SUCCESS";
export const UPLOAD_PDF_FAILURE = "UPLOAD_PDF_FAILURE";

export const RESTRICT_TEXT_FOR_STUDENT_REQUEST = "RESTRICT_TEXT_FOR_STUDENT_REQUEST";
export const RESTRICT_TEXT_FOR_STUDENT_SUCCESS = "RESTRICT_TEXT_FOR_STUDENT_SUCCESS";
export const RESTRICT_TEXT_FOR_STUDENT_FAILURE = "RESTRICT_TEXT_FOR_STUDENT_FAILURE";

export const SCREEN_SHARED_BY_TEACHER_REQUEST = "SCREEN_SHARED_BY_TEACHER_REQUEST";
export const SCREEN_SHARED_BY_TEACHER_SUCCESS = "SCREEN_SHARED_BY_TEACHER_SUCCESS";
export const SCREEN_SHARED_BY_TEACHER_FAILURE = "SCREEN_SHARED_BY_TEACHER_FAILURE";

export const GET_CHINESE_FEEDBACK_REQUEST = "GET_CHINESE_FEEDBACK_REQUEST";
export const GET_CHINESE_FEEDBACK_SUCCESS = "GET_CHINESE_FEEDBACK_REQUEST_SUCCESS";
export const GET_CHINESE_FEEDBACK_FAILURE = "GET_CHINESE_FEEDBACK_FAILURE";