import { API } from "./API";
import _ from "lodash";

// get twilio token
export function getTwilioToken(data) {
  return new Promise((resolve, reject) => {
    return API("POST", `twilio/video/token`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get class room slides
export function getClassRoomSlides(data) {
  return new Promise((resolve, reject) => {
    return API("GET", `course-material?classroom_uuid=${data.id}`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get class room videos
export function getClassRoomVideos(data) {
  return new Promise((resolve, reject) => {
    return API("GET", `course-material/recordedvideos/${data.id}`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get class room slider slides
export function getClassRoomSliderSlides(data) {
  return new Promise((resolve, reject) => {
    return API("GET", `course-material/slider?classroom_uuid=${data.id}`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get class room slide
export function getClassRoomSlide(data) {
  return new Promise((resolve, reject) => {
    return API("GET", `course-material/${data.id}`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get class room session details
export function getClassRoomSession(data) {
  return new Promise((resolve, reject) => {
    return API("GET", `classroom-session/${data.id}`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get all room sessions by teacher
export function getAllRoomSessionsByTeacher(req) {
  return new Promise((resolve, reject) => {
    return API("GET", `classroom-session/${req.id}/sessionsbyteacher`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get class room session slide drawing details
export function getClassRoomDrawing(data) {
  return new Promise((resolve, reject) => {
    return API("GET", `course-material-slide-drawing/classroom/${data.id}`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function callClassStudent(payload) {
  return new Promise((resolve, reject) => {
    return API("POST", `classroom-session/call-student`, {
      student_account_uuid: payload.student_account_uuid,
      class_id: payload.class_id,
    })
    .then((response) => {
      response.success ? resolve(response) : reject(response);
    })
    .catch((error) => {
      reject(error);
    });
});
}

// save class room video recording
export function saveClassRoomVideo(data) {
  return new Promise((resolve, reject) => {
    return API("POST", `course-material-slide-drawing/upload/videofile`, data, {
      "Content-Type": "multipart/form-data",
    })
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// save class room session slide drawing details
export function saveClassRoomDrawing(data) {
  return new Promise((resolve, reject) => {
    return API("POST", `course-material-slide-drawing`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// save class room session restrict text details
export function restricTextForStudent(data) {
  return new Promise((resolve, reject) => {
    return API("POST", `classroom-session/${data.id}/restrict-text`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// save class room session screen sharing details
export function screenSharedByTeacher(data) {
  return new Promise((resolve, reject) => {
    return API("POST", `classroom-session/${data.id}/screen-sharing`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// remove class room session slide drawing details
export function removeClassRoomDrawing(data) {
  return new Promise((resolve, reject) => {
    return API("POST", `course-material-slide-drawing`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get all classroom session
export function getAllClassRoomSession() {
  return new Promise((resolve, reject) => {
    return API("GET", `classroom-session`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// save classroom session report
export function saveClassRoomReport(data) {
  return new Promise((resolve, reject) => {
    return API(
      "POST",
      `classroom-session/${data.class_id}/report`,
      _.omit(data, ["class_id"])
    )
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get classroom session report
export function getClassRoomReport(data) {
  return new Promise((resolve, reject) => {
    return API("GET", `classroom-session/${data.class_id}/report`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get all pets
export function getAllPets() {
  return new Promise((resolve, reject) => {
    return API("GET", `student/pets`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get a pet
export function getAPet() {
  return new Promise((resolve, reject) => {
    return API("GET", `student/pet`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get wallet detail
export function getWalletDetail() {
  return new Promise((resolve, reject) => {
    return API("GET", `student/wallet`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// update classroom status
export function updateClassroom(data) {
  return new Promise((resolve, reject) => {
    return API("PUT", `classroom-session/${data.id}`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// update Tracking Time
export function updateTrackingTime(data) {
  return new Promise((resolve, reject) => {
    return API("PUT", `classroom-session/${data.id}/tracking-time`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// update unit report
export function updateParentsSuggestions(data) {
  return new Promise((resolve, reject) => {
    return API("PUT", `classroom-session/parents-suggestions/update-unit-report`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get chinese feedback
export function getChineseFeedback(data) {
  return new Promise((resolve, reject) => {
    return API("POST", `classroom-session/${data.class_id}/report/chinese-feedback`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}