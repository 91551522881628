import React from 'react';
import _ from 'lodash';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.min.css";

let EL_HTML = null;

class SignupForm extends React.Component {
  componentDidMount() {
    try {
      EL_HTML = document.getElementsByTagName("html")[0];
      EL_HTML.classList.add("bg-page");
    } catch { }
  }

  componentWillUnmount() {
    try {
      EL_HTML.classList.remove("bg-page");
    } catch { }
  }

  render() {
    const {
      data, errors, signupResp, showErrorBlock, loading,
      hideErrorBlock, handleChange, handleSubmit, navigateLogin
    } = this.props;
    return (
      <div>
        <div className="container signup-box">
          <div className="logo">
            <p className="is-size-3-desktop is-size-4-mobile">Register your account</p>
          </div>
          {(!_.isEmpty(signupResp) && showErrorBlock) && <div className="notification is-danger">
            <button className="delete" onClick={hideErrorBlock}></button>
            {signupResp}
          </div>}

          <form onSubmit={handleSubmit} noValidate>
            <div className="field">
              <div className="control">
                <input
                  id="email"
                  type="email"
                  className={`input ${!_.isEmpty(_.get(errors, 'email', '')) ? `is-danger` : ``}`}
                  placeholder="Your Email"
                  value={data.email}
                  onChange={({ target: { value } }) => handleChange({ email: value })}
                />
              </div>
              <p className="help is-danger ml15">{_.get(errors, 'email', '')}</p>
            </div>
            <div className="field">
              <div className="control">
                <input
                  id="phone_number"
                  type="text"
                  className={`input ${!_.isEmpty(_.get(errors, 'phone_number', '')) ? `is-danger` : ``}`}
                  placeholder="Your Phone Number"
                  value={data.phone_number}
                  onChange={({ target: { value } }) => handleChange({ phone_number: value })}
                />
              </div>
              <p className="help is-danger ml15">{_.get(errors, 'phone_number', '')}</p>
            </div>
            <div className="field">
              <div className="control">
                <input
                  id="fullname"
                  type="text"
                  className={`input ${!_.isEmpty(_.get(errors, 'fullname', '')) ? `is-danger` : ``}`}
                  placeholder="Student's Full Name"
                  value={data.fullname}
                  onChange={({ target: { value } }) => handleChange({ fullname: value })}
                />
              </div>
              <p className="help is-danger ml15">{_.get(errors, 'fullname', '')}</p>
            </div>
            <div className="field">
              <div className="control">
                <input
                  id="nickname"
                  type="text"
                  className={`input ${!_.isEmpty(_.get(errors, 'nickname', '')) ? `is-danger` : ``}`}
                  placeholder="Student's English Name (e.g. John Leung)"
                  value={data.nickname}
                  onChange={({ target: { value } }) => handleChange({ nickname: value })}
                />
              </div>
              <p className="help is-danger ml15">{_.get(errors, 'nickname', '')}</p>
            </div>
            <div className="field">
              <div className="control sk-datepicker">
                <DatePicker
                  className={`input ${!_.isEmpty(_.get(errors, 'date_of_birth', '')) ? `is-danger` : ``}`}
                  selected={data.date_of_birth}
                  placeholderText="Date of birth"
                  onChange={(date) => handleChange({ date_of_birth: date })}
                  showMonthDropdown
                  showYearDropdown
                  maxDate={new Date()} // disabled future dates
                  dateFormat={'dd-MM-yyyy'}
                />
              </div>
              <p className="help is-danger ml15">{_.get(errors, 'date_of_birth', '')}</p>
            </div>

            <div className="field">
              <div className="control">
                <div className="select">
                  <select
                    id="english_exposure_level"
                    value={data.english_exposure_level}
                    className={`lang-expo input ${!_.isEmpty(_.get(errors, 'english_exposure_level', '')) ? `is-danger` : ``}`}
                    onChange={({ target: { value } }) => handleChange({ english_exposure_level: value })}>
                    <option value="">Your Kid’s English level</option>
                    <option value="none_or_minimun_english_exposure">None / Minimum English exposure</option>
                    <option value="one_year_english_exposure">1 year of English exposure</option>
                    <option value="two_or_more_years_english_exposure">2 or more years of English exposure</option>
                  </select>
                </div>
              </div>
              <p className="help is-danger ml15">{_.get(errors, 'english_exposure_level', '')}</p>
            </div>

            <div className="field">
              <p className="control">
                <input
                  id="password"
                  type="password"
                  className={`input ${!_.isEmpty(_.get(errors, 'password', '')) ? `is-danger` : ``}`}
                  placeholder="Password"
                  value={data.password}
                  onChange={({ target: { value } }) => handleChange({ password: value })}
                />
              </p>
              <p className="help is-danger ml15">{_.get(errors, 'password', '')}</p>
            </div>
            <div className="field">
              <div className="control">
                <input
                  id="confirm_password"
                  type="password"
                  className={`input ${!_.isEmpty(_.get(errors, 'confirm_password', '')) ? `is-danger` : ``}`}
                  placeholder="Confirm Password"
                  value={data.confirm_password}
                  onChange={({ target: { value } }) => handleChange({ confirm_password: value })}
                />
              </div>
              <p className="help is-danger ml15">{_.get(errors, 'confirm_password', '')}</p>
            </div>
            <div className="field buttons">
              <button className={`button btn-login-signup is-info is-fullwidth is-primary ${loading ? `is-loading` : ``}`} type="submit">Register</button>
            </div>
            <div className="field login-link-container">
              Already registered? Please <a className="a-link" onClick={navigateLogin}>click here</a> to go back to login page
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SignupForm;
