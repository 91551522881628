import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { _ROUTES } from '../../constants/GlobalConst';
import * as reduxActions from "../../constants/ActionTypes";

class Logout extends React.Component {
  componentDidMount(){
    this.props.userLogoutRequest();
  }

  componentDidUpdate(prevProps) {
    const { logout, history } = this.props;

    if (prevProps.logout !== logout) {
      const { result } = logout;
      if (result.success) {
        localStorage.removeItem('auth');
        history.push(_ROUTES.PATH_LOGIN);
      }
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  logout: _.get(state, 'auth.logout', {}),
});

const mapDispatchToProps = (dispatch) => ({
  userLogoutRequest: () => dispatch({ type: reduxActions.USER_LOGOUT_REQUEST }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
