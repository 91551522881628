import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { getAllCourse, getAllCard, addCard, chargeCard, removeCard } from '../../services/course.service';
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* getAllCourseWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllCourse, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_COURSE_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_COURSE_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getAllCardWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllCard, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_CARDS_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_CARDS_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* addCardWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(addCard, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.ADD_CARD_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.ADD_CARD_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* chargeCardWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(chargeCard, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.CHARGE_CARD_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.CHARGE_CARD_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* removeCardWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(removeCard, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.REMOVE_CARD_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.REMOVE_CARD_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

/* WATCHERS SAGA */

// Watcher for get all courses
export function* getAllCourseWatcher() {
  yield takeLatest(reduxActions.GET_ALL_COURSE_REQUEST, getAllCourseWorker);
}

// Watcher for get all cards
export function* getAllCardWatcher() {
  yield takeLatest(reduxActions.GET_ALL_CARDS_REQUEST, getAllCardWorker);
}

// Watcher for add card
export function* addCardWatcher() {
  yield takeLatest(reduxActions.ADD_CARD_REQUEST, addCardWorker);
}

// Watcher to charge card
export function* chargeCardWatcher() {
  yield takeLatest(reduxActions.CHARGE_CARD_REQUEST, chargeCardWorker);
}

// Watcher for remove card
export function* removeCardWatcher() {
  yield takeLatest(reduxActions.REMOVE_CARD_REQUEST, removeCardWorker);
}

export default function* rootSaga() {
  yield all([
    fork(getAllCourseWatcher),
    fork(getAllCardWatcher),
    fork(addCardWatcher),
    fork(chargeCardWatcher),
    fork(removeCardWatcher),
  ]);
}
