import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { APP_USER_TYPE } from "../../constants/Config";
import { _ROUTES } from "../../constants/GlobalConst";
import { validEmailRegex } from "../../helpers/Utils";
import LoginForm from './components/LoginForm';

import * as reduxActions from "../../constants/ActionTypes";

const MSG_INVALID_EMAIL = 'Email is not valid';
const MSG_INVALID_PASSWORD = 'Password cannot be empty';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login_resp: null,
      form_data: {
        email: '',
        password: '',
      },
      form_errors: {
        email: '',
        password: '',
      },
      showErrorBlock: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { login, history } = this.props;
    // Redirect user back to dashboard if token exists
    const token = _.get(login, 'result.response.token', '');
    if (!_.isEmpty(token)) {
      history.push(_ROUTES.PATH_HOME);
    }
  }

  componentDidUpdate(prevProps) {
    const { login, history } = this.props;

    if (prevProps.login !== login) {
      const { result } = login;
      if (result.success) {
        localStorage.setItem('auth', JSON.stringify(result));
        history.push(_ROUTES.PATH_HOME);
      } else {
        this.setState({
          login_resp: _.get(result, 'response.message', ''),
          showErrorBlock: true,
          loading: false,
        });
      }
    }
  }

  _validateFormField = (field, value) => {
    if (field === 'email') {
      return validEmailRegex.test(value) ? '' : MSG_INVALID_EMAIL;
    }
    if (field === 'password') {
      return value.length === 0 ? MSG_INVALID_PASSWORD : ''
    }
  }

  _validateForm = (fields) => {
    const { form_data, form_errors } = this.state;
    let errors = { ...form_errors };

    _.map(fields, name => {
      errors = {
        ...errors,
        [name]: this._validateFormField(name, form_data[name])
      };
      return name;
    });

    return errors;
  }

  _handleChange = (payload) => {
    const { form_data } = this.state;

    this.setState({
      form_data: {
        ...form_data,
        ...payload,
      },
    });
  }

  _hideErrorBlock = () => {
    this.setState({ showErrorBlock: false });
  }

  _handleLogin = (e) => {
    e.preventDefault();

    const { form_data } = this.state;

    const errors = this._validateForm(['email', 'password']);
    const found = _.find(errors, field => { return !_.isEmpty(field) });

    if (!_.isEmpty(found)) {
      this.setState({ form_errors: errors });
    } else {
      this.setState({ loading: true }, () => {
        this.props.userLoginRequest({
          email: form_data.email,
          password: form_data.password,
          role: APP_USER_TYPE,
        });
      });
    }
  }

  _handleSignup = (e) => {
    e.preventDefault();
    this.props.history.push(_ROUTES.PATH_REGISTRATION);
  }

  render() {
    const { form_data, form_errors, login_resp, showErrorBlock, loading } = this.state;

    return (
      <div>
        <LoginForm
          handleLogin={this._handleLogin}
          handleSignup={this._handleSignup}
          handleChange={this._handleChange}
          hideErrorBlock={this._hideErrorBlock}
          loginResp={login_resp}
          errors={form_errors}
          data={form_data}
          showErrorBlock={showErrorBlock}
          loading={loading}
        />
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    login: _.get(state, 'auth.login', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  userLoginRequest: payload => dispatch({ type: reduxActions.USER_LOGIN_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
