import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import WalletNotification from "../../components/layout/WalletNotification";
import * as reduxActions from "../../constants/ActionTypes";
import { FaRegPlayCircle, FaRegPauseCircle, FaRegSquare } from "react-icons/fa";
import VideoThumbnail from "react-video-thumbnail";
import { API_BASE_URL, REACT_APP_S3_URL } from "../../constants/Config";
import "./RoomVideos.css";

class ClassVideos extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { classId },
      },
    } = props;

    this.state = {
      class_id: classId,
      videos: [],
      error: "",
      render: true,
      isVideoPlaying: false,
      currentVideoUrl: "",
      videoSequence: 1,
      videoDisplay: false,
      rerender: false
    };

    this.videoRef = React.createRef();
  }
  componentDidMount() {
    this.props.getClassRoomVideos({ id: this.state.class_id });
  }

  async componentDidUpdate(prevProps) {
    try {
      const { class_videos } = this.props;
      if (prevProps.class_videos !== class_videos) {
        const { result } = class_videos;
        if (result.success) {
          const videos = _.get(result, "response.data", []);
          if (videos.length > 0) {
            const video_url = videos[0].s3_url !== null ? `${REACT_APP_S3_URL}/video/${videos[0].s3_url}` :`${API_BASE_URL}${videos[0].video_path}` 
            await fetch(video_url).then(response => {
              if(response.headers.get("content-length") == 0){
                this.setState({
                  render: true,
                  rerender: true,
                  videoDisplay: false
                })
                return 1;
              } else {
                this.setState(
                  {
                    videos,
                    render: true,
                    currentVideoUrl: video_url,
                    videoDisplay: true
                  },
                  () => {
                    this.videoRef.current.load();
                  }
                );
                return 1;
              }
            })
          } else {
            this.setState({
              error: "Recorded classroom videos are not yet available.",
              render: true,
            });
          }
        } else {
          this.setState({
            error: _.get(result, "response.message", ""),
            render: true,
          });
        }
      }
    } catch (error) {
      console.log("Error", error)
    }
    
  }

  managePlayer = () => {
    if (this.state.isVideoPlaying) {
      this.setState({ isVideoPlaying: false });
      this.videoRef.current.pause();
    } else {
      this.setState({ isVideoPlaying: true });
      this.videoRef.current.play();

      this.videoRef.current.addEventListener(
        "timeupdate",
        this.seektimeupdate,
        false
      );
    }
  };

  restartPlayer = () => {
    this.videoRef.current.pause();
    this.videoRef.current.currentTime = 0;
    this.setState({ isVideoPlaying: true });
    this.videoRef.current.play();
  };

  seektimeupdate = () => {
    /*var nt =
      this.videoRef.current.currentTime *
      (100 / this.videoRef.current.duration);*/

    var curmins = Math.floor(this.videoRef.current.currentTime / 60);
    var cursecs = Math.floor(this.videoRef.current.currentTime - curmins * 60);
    var durmins = Math.floor(this.videoRef.current.duration / 60);
    var dursecs = Math.floor(this.videoRef.current.duration - durmins * 60);
    if (cursecs < 10) {
      cursecs = "0" + cursecs;
    }
    if (dursecs < 10) {
      dursecs = "0" + dursecs;
    }
    if (curmins < 10) {
      curmins = "0" + curmins;
    }
    if (durmins < 10) {
      durmins = "0" + durmins;
    }
    document.getElementById("time-duration").innerHTML =
      curmins + ":" + cursecs;
  };

  render() {
    const { render, error } = this.state;
    const { user_type } = this.props;

    return (
      <div>
        {render && (
          <div className="video-listing-container">
            {user_type === "student" && <WalletNotification />}
            {!_.isEmpty(error) && (
              <section className="section pb0 pt10">
                <div className="notification" style={{ marginTop: "50px" }}>
                  {error}
                </div>
              </section>
            )}
            <br />
            {this.state.videos.length > 0  ? (
              <section className="section" style={{ padding: "0px" }}>
                {
                  this.state.videoDisplay == true
                  ?
                  <div className="video-wrapper">
                  <div className="container-video">
                    <video
                      id="glass"
                      width="100%"
                      height="100%"
                      ref={this.videoRef}
                      // loop
                      controls
                      disablePictureInPicture
                      controlsList="noplaybackrate"
                    >
                      <source
                        id="video-source"
                        src={this.state.currentVideoUrl}
                        type="video/webm"
                      />
                      Your browser doesn't support HTML5 video tag.
                    </video>
                    <div id="player_controls">
                      <div className="video-button-container">
                        <span
                          onClick={this.managePlayer}
                          id="button-play-pause"
                          className="btn-play-pause"
                          title="Play video"
                        >
                          {this.state.isVideoPlaying === true ? (
                            <FaRegPauseCircle />
                          ) : (
                            <FaRegPlayCircle />
                          )}
                        </span>
                        <span
                          onClick={this.restartPlayer}
                          id="button-restart"
                          className="btn-play-pause"
                          title="Replay video"
                        >
                          <FaRegSquare />
                        </span>
                      </div>
                      <div className="video-duration-volume">
                        <span id="time-duration">00:00</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="video-list-container">
                    {this.state.videos.length > 1 &&
                      this.state.videos.map((video, index) => (
                        <div
                          key={`key-${index}`}
                          className={
                            this.state.videoSequence === index + 1
                              ? "video-item active-video"
                              : "video-item"
                          }
                          onClick={() => {
                            this.setState(
                              {
                                currentVideoUrl: `${API_BASE_URL}${
                                  video.video_path
                                }`,
                                isVideoPlaying: false,
                                videoSequence: index + 1,
                              },
                              () => {
                                this.videoRef.current.load();
                              }
                            );
                          }}
                        >
                          <VideoThumbnail
                            videoUrl={`${API_BASE_URL + video.video_path}`}
                            width={1600}
                            height={1000}
                          />
                        </div>
                      ))}
                  </div> */}
                </div>
                  :
                  <div>
                    <div className="video-wrapper">
                      <div className="container-video">
                        <button>
                          Save Button
                        </button>
                      </div>
                    </div>
                  </div>
                }
              </section>
            ) : 
              <div>
                {
                  this.state.rerender === true ?
                  <div className="video-wrapper">
                    <div class="notification video_display">Video is not available yet. Please check after sometime!</div>
                  </div>
                  : 
                  ""
                }
              </div>
            }
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    class_videos: _.get(state, "room.class_videos", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClassRoomVideos: (payload) =>
    dispatch({ type: reduxActions.GET_CLASS_ROOM_VIDEOS_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassVideos);
