import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

// get all courses
const user_event = (state = {}, action) => {
    switch (action.type) {
        case reduxActions.GET_ALL_USER_EVENT_SUCCESS:
            return {
                result: action.payload,
            };
        case reduxActions.GET_ALL_USER_EVENT_FAILURE:
            return {
                result: action.payload,
            };
        default:
            return state;
    }
}

// remove Event
const remove_event = (state = {}, action) => {
    switch (action.type) {
        case reduxActions.REMOVE_EVENT_SUCCESS:
            return {
                result: action.payload,
            };
        case reduxActions.REMOVE_EVENT_FAILURE:
            return {
                result: action.payload,
            };
        default:
            return state;
    }
}

// add Event
const add_event = (state = {}, action) => {
    switch (action.type) {
        case reduxActions.ADD_EVENT_SUCCESS:
            return {
                result: action.payload,
            };
        case reduxActions.ADD_EVENT_FAILURE:
            return {
                result: action.payload,
            };
        default:
            return state;
    }
}

const update_event = (state = {}, action) => {
    switch (action.type) {
        case reduxActions.UPDATE_EVENT_SUCCESS:
            return {
                result: action.payload,
            };
        case reduxActions.UPDATE_EVENT_FAILURE:
            return {
                result: action.payload,
            };
        default:
            return state;
    }
}

export default combineReducers({
    user_event,
    remove_event,
    add_event,
    update_event
});