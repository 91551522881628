import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { zoomRange, _CONST, _ROUTES } from "../../constants/GlobalConst";
import WebcamIcon from "../../../src/assets/images/icon_webcam.png";
import EarphoneIcon from "../../../src/assets/images/icon_earphone.png";
import MicroPhoneIcon from "../../../src/assets/images/icon_microphone.png";
import MicrophoneWav from "../../../src/assets/images/microphone_wav.png";
import MicrophoneWavGif from "../../../src/assets/images/microphone_gif.gif";
import MediaNote from "../Class/components/MediaNote";
import Popup from "../../components/PopUp/Popup";
import AudioTest from "../../../src/assets/audio/audio_test.mp3";
import "./index.css";

let localStream;
class DeviceTesting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      render: false,
      showRecordingTimer: false,
      isAudio: false,
      showPermissionWarning: false,
      isAudioPlaying: false,
      isEarphonePlaying: false,
      currentAudioTime: 0,
    };
  }

  componentDidMount() {
    if(document.body.style.zoom !== zoomRange.one){
      document.body.style.zoom = zoomRange.one
    }
    this.setState({
      ...this.state,
      render: true,
      showRecordingTimer: false,
      isAudio: false,
      showPermissionWarning: false,
      isAudioPlaying: false,
      isEarphonePlaying: false,
      currentAudioTime: 0,
    });
  }

  componentWillUnmount() {
    try {
      localStream.getVideoTracks()[0].stop();
    } catch {}
  }

  showModal = () => {
    this.setState({
      showPermissionWarning: false,
    });
  };

  _detectWebcam = (callback) => {
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return callback(false);
    md.enumerateDevices().then((devices) => {
      callback(devices.some((device) => "videoinput" === device.kind));
    });
  };

  _testWebCam = () => {
    this._detectWebcam((hasWebcam) => {
      if (hasWebcam) {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: { width: 300, height: 150 } })
          .then(async (stream) => {
            //show webcam stream if available
            localStream = await stream;
            let videoIn = document.createElement("video");
            videoIn.autoplay = true;
            let videoDiv = document.getElementById("video-output");
            videoDiv.innerHTML = "";
            videoDiv.appendChild(videoIn);
            videoDiv.style.display = "block";
            videoIn.srcObject = stream;
          })
          .catch((err) => {
            this.setState({
              ...this.state,
              showPermissionWarning: true,
            });
          });
      } else {
        alert("No Devices Found");
      }
    });
  };

  _testMicroPhone = () => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia;
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        { audio: true },
        (stream) => {
          this._handleAction();
          this.setState({
            ...this.state,
            render: true,
            showRecordingTimer: true,
            isAudioPlaying: false,
          });
        },
        (err) => {
          this.setState({
            ...this.state,
            showPermissionWarning: true,
          });
        }
      );
    } else {
      alert("Not Found");
    }
  };

  _handleAction = async () => {
    const wavDiv = document.getElementById("wav");
    const wavDivGif = document.getElementById("wav-gif");
    wavDiv.innerHTML = "";
    wavDivGif.innerHTML = "";
    let displayImg = document.createElement("img");
    displayImg.src = MicrophoneWav;
    displayImg.className = "wave-img";
    let displayGif = document.createElement("img");
    displayGif.src = MicrophoneWavGif;
    wavDivGif.appendChild(displayGif);
    wavDivGif.style.display = "block";
    wavDiv.style.display = "none";

    // start recording
    const recorder = await this._recordAudio();
    recorder.start();
    await this._sleep(5000);
    const audioData = await recorder.stop();
    wavDiv.style.display = "block";
    wavDivGif.style.display = "none";
    if (document.getElementsByClassName("wave-img").length < 1) {
      wavDiv.appendChild(displayImg);
    }

    // play recording
    if (audioData && audioData.audioUrl) {
      audioData.play();
      this.setState({
        ...this.state,
        showRecordingTimer: false,
        isAudioPlaying: true,
      });
    }
    this.setState({ isAudio: audioData });
    await this._sleep(5000);
    this.setState({ isAudioPlaying: false });
  };

  _playMusic = () => {
    if (this.state.isAudio.audioUrl) {
      alert("Playing Audio");
      this.state.isAudio.play();
    }
  };

  _recordAudio = () =>
    new Promise(async (resolve) => {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      const start = () => mediaRecorder.start();

      const stop = () =>
        new Promise((resolve) => {
          mediaRecorder.addEventListener("stop", () => {
            const audioBlob = new Blob(audioChunks);
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            const play = () => audio.play();
            resolve({ audioBlob, audioUrl, play });
          });

          mediaRecorder.stop();
        });

      resolve({ start, stop });
    });

  _sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

  _testSpeakers = () => {
    // disable untill song played
    this.setState({
      ...this.state,
      isEarphonePlaying: true,
    });
    const audio_ouput = document.getElementById("default-audio");
    audio_ouput.currentTime = this.state.currentAudioTime || 0;
    audio_ouput.play();
    setTimeout(() => {
      audio_ouput.pause();
      audio_ouput.currentTime = 0;
      this.setState({
        ...this.state,
        isEarphonePlaying: false,
      });
    }, 15000);
  };

  _audioPlaying = () => {
    const audio_ouput = document.getElementById("default-audio");
    this.setState({
      ...this.state,
      isEarphonePlaying: true,
      currentAudioTime: audio_ouput.currentTime,
    });
  };

  _audioPausing = () => {
    const audio_ouput = document.getElementById("default-audio");
    this.setState({
      ...this.state,
      isEarphonePlaying: false,
      currentAudioTime: audio_ouput.currentTime,
    });
  };

  render() {
    const {
      render,
      showRecordingTimer,
      showPermissionWarning,
      isAudioPlaying,
      isEarphonePlaying,
    } = this.state;

    return (
      <div className="container" style={{ padding: "25px" }}>
        {render && (
          <>
            {showPermissionWarning && (
              <Popup show={showPermissionWarning} onClose={this.showModal}>
                {<MediaNote />}
              </Popup>
            )}

            <h2
              className="title"
              style={{ color: "#fff", fontWeight: "normal" }}
            >
              Device Testing
            </h2>
            <div className="box">
              <section className="section pt10 pb5 pl5">
                <h2
                  className="title"
                  style={{ color: "#000", fontWeight: "normal" }}
                >
                  Test Your Audio & Video
                </h2>
              </section>
              <section className="section pt5">
                <div className="columns col-overflow">
                  <div className="column is-7">
                    <div className="columns" style={{ display: "flex" }}>
                      <div className="column is-2">
                        <img
                          className="image"
                          src={WebcamIcon}
                          alt="Webcam"
                          height={100}
                          width={100}
                        />
                      </div>
                      <div
                        className="column is-5"
                        style={{ alignSelf: "center" }}
                      >
                        <h4
                          className="title"
                          style={{ color: "#000", fontWeight: "normal" }}
                        >
                          Webcam
                        </h4>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column is-8">
                        <button
                          className="button is-fullwidth is-primary"
                          onClick={this._testWebCam.bind(this)}
                        >
                          Test Webcam
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4">
                    <div className="columns">
                      <div className="column is-12">
                        <div
                          id="video-output"
                          style={{
                            height: "165px",
                            width: "330px",
                            background: "black",
                          }}
                        ></div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns col-overflow">
                  <div className="column is-7">
                    <div className="columns" style={{ display: "flex" }}>
                      <div className="column is-2">
                        <img
                          className="image"
                          src={MicroPhoneIcon}
                          alt="Webcam"
                          height={100}
                          width={100}
                        />
                      </div>
                      <div
                        className="column is-5"
                        style={{ alignSelf: "center" }}
                      >
                        <h4
                          className="title"
                          style={{ color: "#000", fontWeight: "normal" }}
                        >
                          Microphone
                        </h4>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column is-8">
                        <button
                          className="button is-fullwidth is-primary"
                          onClick={this._testMicroPhone.bind(this)}
                          disabled={
                            showRecordingTimer || isAudioPlaying ? true : false
                          }
                        >
                          {showRecordingTimer ? (
                            <Timer />
                          ) : isAudioPlaying ? (
                            "Playing Recorded Audio ..."
                          ) : (
                            "Test Microphone"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4">
                    <div style={{ display: "flex" }}>
                      <div>
                        <div
                          id="wav"
                          style={{
                            height: "165px",
                            width: "330px",
                            display: "block",
                          }}
                        >
                          <img src={MicrophoneWav} className="wave-img" />
                        </div>
                        <div
                          id="wav-gif"
                          style={{
                            height: "165px",
                            width: "330px",
                            display: "none",
                          }}
                        >
                          <img src={MicrophoneWavGif} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns col-overflow">
                  <div className="column is-7">
                    <div className="columns" style={{ display: "flex" }}>
                      <div className="column is-2">
                        <img
                          className="image"
                          src={EarphoneIcon}
                          alt="Webcam"
                          height={100}
                          width={100}
                        />
                      </div>
                      <div
                        className="column is-10"
                        style={{ alignSelf: "center" }}
                      >
                        <h4
                          className="title"
                          style={{ color: "#000", fontWeight: "normal" }}
                        >
                          Speaker / Earphone
                        </h4>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column is-8">
                        <button
                          className="button is-fullwidth is-primary"
                          onClick={() => this._testSpeakers()}
                          disabled={isEarphonePlaying ? true : false}
                        >
                          Test Speaker / Earphone
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4" style={{ alignSelf: "center" }}>
                    <div
                      className="audioTest"
                      id="audio-output"
                      style={{
                        display: "block",
                      }}
                    >
                      <audio
                        src={AudioTest}
                        id="default-audio"
                        controls
                        onPlay={() => this._audioPlaying()}
                        onPause={() => this._audioPausing()}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    );
  }
}

class Timer extends React.Component {
  state = {
    seconds: 5,
  };

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    const { seconds } = this.state;
    return (
      <div>
        <h1>Recording your audio for: {seconds} seconds</h1>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_type: _.get(state, "auth.login.result.response.role", ""),
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTesting);
