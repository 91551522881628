import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import { _ROUTES } from "./constants/GlobalConst";
import { APP_USER_TYPE } from "./constants/Config";
import Layout from "./components/layout";
import PrivateRoute from "./components/hoc/PrivateRoute";
import ClassList from "./Routes/Class/List";
import OnlineRoom from "./Routes/Class/Room";
import RoomSlides from "./Routes/Class/RoomSlides";
import RoomVideos from "./Routes/Class/RoomVideos";
import WriteReport from "./Routes/Report";
import ViewReport from "./Routes/Report/View";
import Course from "./Routes/Course";
import DeviceTesting from "./Routes/DeviceTesting";
import UserCalendar from "./Routes/Calendar";

class App extends Component {
  componentDidMount() {
    if (APP_USER_TYPE === "teacher") document.title = "Starkid Teacher";
  }

  render() {
    const { history } = this.props;

    return (
      <div>
        <Layout history={history} />
        <Switch>
          <PrivateRoute
            exact
            path={_ROUTES.PATH_DASHBOARD}
            component={ClassList}
          />
          <PrivateRoute
            exact
            path={_ROUTES.PATH_CLASSROOM_SESSION}
            component={OnlineRoom}
          />
          <PrivateRoute
            exact
            path={_ROUTES.PATH_CLASSROOM_SLIDES}
            component={RoomSlides}
          />
           <PrivateRoute
            exact
            path={_ROUTES.PATH_CLASSROOM_VIDEOS}
            component={RoomVideos}
          />
          <PrivateRoute
            exact
            path={_ROUTES.PATH_WRITE_REPORT}
            component={WriteReport}
          />
          <PrivateRoute
            exact
            path={_ROUTES.PATH_VIEW_REPORT}
            component={ViewReport}
          />
          <PrivateRoute
            exact
            path={_ROUTES.PATH_GET_COURSES}
            component={Course}
          />
          <PrivateRoute
            exact
            path={_ROUTES.PATH_DEVICE_TESTING}
            component={DeviceTesting}
          />
          <PrivateRoute
            exact
            path={_ROUTES.PATH_CALENDAR}
            component={UserCalendar}
          />

          <PrivateRoute exact path={"/"} component={ClassList} />

          {/* Redirect to default page if no url matched */}
          <Redirect to={`/`} />
        </Switch>
      </div>
    );
  }
}


export default App;
