import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { Carousel } from 'react-responsive-carousel';
import WalletNotification from '../../components/layout/WalletNotification';
import * as reduxActions from "../../constants/ActionTypes";
import playButton from "../../assets/images/play_audio.png";
import pauseButton from "../../assets/images/pause_audio.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";

class ClassList extends React.Component {
  constructor(props) {
    super(props);

    const { match: { params: { classId } } } = props;

    this.state = {
      class_id: classId,
      slides: [],
      error: '',
      render: false,
      isAudioPlaying: false
    };
  }
  componentDidMount() {
    this.props.getClassRoomSlidesRequest({ id: this.state.class_id });
  }

  componentDidUpdate(prevProps) {
    const { class_slides, user_type } = this.props;

    if (prevProps.class_slides !== class_slides) {
      const { result } = class_slides;
      if (result.success) {
        const slides = _.map(_.get(result, 'response.data', []), img => {
          return {
            id: img.uuid,
            name: img.name,
            path: `data:${img.content_type};base64,${img.encoded_base64_data}`,
            tips: user_type === 'teacher' ? img.tips : '',
            tip_visible: false,
            audio_path: !_.isEmpty(img.course_audio) ? `data:audio/mp3; base64,${img.course_audio.audio_data}` : null,
            isAudioPlaying: false,
            isAudioAvailable: img.course_audio ? true : false
          }
        });

        if (slides.length > 0) {
          this.setState({
            slides,
            render: true,
          });
        } else {
          this.setState({
            error: 'Course material slides are not yet available.',
            render: true,
          });
        }
      } else {
        this.setState({
          error: _.get(result, 'response.message', ''),
          render: true,
        });
      }
    }
  }

  _handleShowTips = (key) => {
    const { slides } = this.state;
    let all_slides = [...slides];

    all_slides[key] = {
      ...all_slides[key],
      tip_visible: !all_slides[key].tip_visible,
    };

    this.setState({ slides: all_slides });
  }

  _playAudioButton = (key, id) => {
    const { slides } = this.state;
    let all_slides = [...slides];

    all_slides[key] = {
      ...all_slides[key],
      isAudioPlaying: !all_slides[key].isAudioPlaying,
    };

    all_slides = all_slides.map(slide => ({ ...slide, isAudioPlaying: slide.id === id ? true : false }));

    const audio = document.getElementById(`audio${id}`);
    if (audio.paused) {
      this.setState({...this.state, isAudioPlaying: true, slides: all_slides }); audio.play();
    }
    else {
      this.setState({...this.state, isAudioPlaying: false, slides: all_slides }); audio.pause();
    };
  }

  onEndAudio = () => {
    this.setState({ ...this.state, isAudioPlaying: false })
  }

  render() {
    const { render, error, slides, isAudioPlaying } = this.state;
    const { user_type } = this.props;

    return (
      <div>
        {render && <div className="container">
          {user_type === 'student' && <WalletNotification />}
          {!_.isEmpty(error) && <section className="section pb0 pt10"><div className="notification">{error}</div></section>}
          <br />
          {_.isEmpty(error) && <section className="section">
            <div className="container slider-container">
              <div className="slider">
                <Carousel
                  showThumbs={false}
                  showStatus={false}
                  useKeyboardArrows={true}>
                  {_.map(slides, (slide, key) => {
                    return (
                      <div key={key}>
                        <div className="slider-tip-section">
                          {(!slide.tip_visible && user_type === 'teacher') && <button className="button" onClick={() => this._handleShowTips(key)}>Show Tips</button>}
                          {(!_.isEmpty(slide.tips) && slide.tip_visible) && <p
                            className="tips"
                            style={{ width: `unset` }}
                            onClick={() => this._handleShowTips(key)}
                          >{slide.tips}</p>}
                        </div>
                        <div className="slider-audio-section">
                          <audio id={`audio${slide.id}`} onEnded={this.onEndAudio} controlsList="nodownload" src={slide.audio_path ? slide.audio_path : '#'}></audio>
                          <button
                            onClick={() => this._playAudioButton(key, slide.id)}
                            disabled={slide.isAudioAvailable || !_.isEmpty(slide.audio_path) ? false : true}
                            className="slider-audio-section button"
                          >
                            <img className="audio-icons" src={isAudioPlaying && slide.isAudioPlaying ? pauseButton : playButton}/>
                          </button>
                        </div>
                        <img className="unselectable" src={slide.path} alt={slide.name} />
                      </div>
                    )
                  })}
                </Carousel>
              </div>
            </div>
          </section>}
        </div>}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    class_slides: _.get(state, 'room.slider_slides', {}),
    user_type: _.get(state, "auth.login.result.response.role", ""),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClassRoomSlidesRequest: payload => dispatch({ type: reduxActions.GET_CLASS_ROOM_SLIDER_SLIDES_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassList);
