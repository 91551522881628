import React from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import _ from "lodash";
import moment from "moment-timezone";
import Twilio from "./components/Twilio";
import Popup from "../../components/PopUp/Popup";
import UpdateStatusPopUp from "../../components/PopUp/UpdateStatusPopup";
import WalletNotification from "../../components/layout/WalletNotification";
import * as reduxActions from "../../constants/ActionTypes";
import { API_BASE_URL } from "../../constants/Config";
import { zoomRange, _CONST } from "../../constants/GlobalConst";
import { FaAngleRight, FaPlusCircle, FaMinusCircle, FaPen, FaTextHeight, FaTrash } from "react-icons/fa";
import {
  getClassRoomDrawing,
  getClassRoomSlide,
  restrictText,
} from "../../services/classroom.service";
let isChrome =
  !!window.chrome &&
  // (!!window.chrome.webstore || !!window.chrome.runtime) &&
  !/edg/i.test(window.navigator.userAgent.toLowerCase());
import BrowserNote from "./components/BrowserNote";
import MediaNote from "./components/MediaNote";
import VideoRecordingNote from "./components/VideoRecordingNote";
import "./RoomStyle.css";
import playButton from "../../assets/images/play_audio.png";
import pauseButton from "../../assets/images/pause_audio.png";
import * as Toastr from 'toastr';
import '../../../node_modules/toastr/build/toastr.css';
import { fabric } from "fabric";
import Switch from "react-switch";
import TimerClock from "./components/TimerClock";

let current = { color: "black" };
let drawing = false;
let EL_HTML = null;
let local_slides = [];
let modelContent;

var blobs = [];
let blob;
let rec;
let stream;
let voiceStream;
let desktopStream;
var reconnect = false;
let timeOutId;



class Room extends React.Component {
  showModal = (e) => {
    this.setState({
      show: !this.state.show,
    });
  };
  constructor(props) {
    super(props);

    const {
      match: {
        params: { classId },
      },
    } = props;

    this.state = {
      class_id: classId,
      tips_visible: false,
      current_slide: {},
      slides: [],
      class_session: {},
      class_drawing: [],
      error: "",
      render: false,
      disabled_navigation: false,
      isAudioPlaying: false,
      show: false,
      classroomStatus: "",
      isStatusChanged: false,
      classroomSubstatus: "",
      classroomReason: "",
      sub_status_error: "",
      class_session_data: null,
      isReminderSent: 0,
      lastReminder: undefined,
      current_zoom_value: zoomRange.one,
      textColorForStudent: 'blue',
      textColorForTeacher: 'blue',
      textCanvas: null,
      isStudentAllowedToWrite: false,
      isDrawingMode: true,
      isDrawingModeForTeacher: true,
      isDrawingModeForStudent: true,
      isStartRecord: false,
      isStopRecord: true,
      isRecording: false,
      timerStatus: false,
      timerTime: 0,
      lastTextCanvasChange: {},
      isScreenSharedByTeacher: false,
      isScreenSharingAllowed: false
    };
    this.intervalId = null;
    this.canvasRef = React.createRef();
    this.socketRef = React.createRef();
    this.myVideoRef = React.createRef();
    this.buttonContainer = React.createRef();
    this.socketVideoRecordRef = React.createRef();
  }

  setIsScreenSharedByTeacher = (value) => this.setState({ isScreenSharedByTeacher: value });

  initCanvas = () => (
    new fabric.Canvas('canvas', {
      height: 800,
      width: 595,
      backgroundColor: 'pink'
    })
  )

  handlePageLoad = (e) => {
    /*
      Save video wheile reloading the page
    */
    e.preventDefault();
  };

  checkTimePassed = (targetTime) => {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - new Date(targetTime);

    return elapsedTime < 5 * 60 * 1000;
  };
  async componentDidMount() {
    // Check after 5 minutes and remind user to record video if lession is marked as recording
    setTimeout(() => {
      if (this.state.isRecording === false && this.state.class_session.allow_video_recording === "Allowed" && this.props.user_type === "teacher") {
        modelContent = <VideoRecordingNote />;
        this.showModal();
      }
    }, 300000);

    window.addEventListener("beforeunload", this.handlePageLoad, false);

    window.onbeforeunload = (e) => {
      e.preventDefault();
      e = e || window.event;
      e.returnValue = "Video is being recorded, sure you want to leave?";
    };

    const { class_id } = this.state;
    const {
      user: { uuid: user_id },
      user_type,
      getClassRoomDrawingRequest,
    } = this.props;

    if (isChrome) {
      let microphonePermission = await navigator.permissions.query({
        name: "microphone",
      });
      let cameraPermission = await navigator.permissions.query({
        name: "camera",
      });
      if (
        microphonePermission.state == "granted" &&
        cameraPermission.state == "granted"
      ) {
      } else {
        modelContent = <MediaNote />;
        this.showModal();
      }
    } else {
      modelContent = <BrowserNote />;
      this.showModal();
    }

    // Adjust the styling for room page
    this._adjustTheme();

    if (_.isEmpty(class_id) || _.isEmpty(user_id)) {
      this.setState({ error: "Invalid URL" });
    } else {
      // Connection socket.io
      if (!_.isEmpty(class_id) && !_.isEmpty(user_id)) {
        this.socketRef.current = io.connect(API_BASE_URL, {
          transports: ["websocket"],
          secure: true,
        });
        this.socketRef.current.emit("join", { class_id });
      }

      // Set the pen color based on user type
      current = {
        ...current,
        color: user_type === "teacher" ? "red" : "blue",
      };

      user_type === "teacher" ? this.setState({ textColorForTeacher: 'red' }) : this.setState({ textColorForTeacher: 'blue' })
      if (this.props.user_type === "teacher") {
        this.handleTimerStart();
      }

      // Get preserved session drawing
      getClassRoomDrawingRequest({ id: class_id });
    }
    if (this.props.user_type === "student") {
      this.socketRef.current.on("screen_sharing", ({ isScreenSharing }) => this.setState({ isScreenSharedByTeacher: isScreenSharing }));
    }

    const { lastReminder } = this.state;

    // Set up an interval to check every 30 seconds
    this.intervalId = setInterval(() => {
      if (!this.checkTimePassed(lastReminder)) {
      }
    }, 30000);

  }

  componentDidUpdate(prevProps) {
    const {
      class_slides,
      class_session,
      class_drawing,
      save_drawing,
      remove_drawing,
      getClassRoomSlidesRequest,
      getClassRoomSessionRequest,
      call_parents,
      save_video
    } = this.props;
    const { class_id } = this.state;

    if (prevProps.class_session !== class_session) {
      const { result } = class_session;
      if (result.success) {
        const cs = _.get(result, "response.data", {});
        if (this._validClassSession(cs)) {
          this.setState(
            {
              class_session: cs,
              isReminderSent: cs.reminder_status,
              lastReminder: moment(cs.last_reminder).tz(_CONST.TIMEZONE).format(),
              isStudentAllowedToWrite: !cs.restrict_text_for_student,
              isScreenSharedByTeacher: cs.screen_shared_by_teacher,
              isScreenSharingAllowed: !_.isNil(cs.account.screen_sharing_allowed) ? cs.account.screen_sharing_allowed : false,
              timerTime: this.state.timerTime && this.state.timerTime > cs.tracking_time ? this.state.timerTime : cs.tracking_time
            },
            () => getClassRoomSlidesRequest({ id: class_id })
          );

        } else {
          this.setState({
            error:
              "You're trying to enter in wrong class room or timing is incorrect.",
          });
        }
      } else {
        // class session is not found
        this.setState({ error: _.get(result, "response.message", "") });
      }

    }

    const { lastReminder } = this.state;

    // Set up an interval to check every 30 seconds
    this.intervalId = setInterval(() => {
      if (!this.checkTimePassed(lastReminder)) {
        // Disable the button or take any other action based on the result
      }
    }, 30000);

    if (prevProps.class_slides !== class_slides) {
      const { result } = class_slides;
      if (result.success) {
        const slides = _.map(_.get(result, "response.data", []), (img) => {
          return {
            id: img.uuid,
            name: img.name,
            drawing: this._getSlideDrawing(img.uuid),
            tips: img.tips,
          };
        });

        let _state = { slides, current_slide: {} };
        if (slides.length > 0) {
          // Load the initial slide by separate API call to have the image binary encoded data
          // Move the init functiona call after getting the slide image data
          this._getCurrentSlide(slides); // TODO: change the name

        } else {
          this.setState({
            ..._state,
            error: "Course material slides are not yet available.",
          });
        }
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.class_drawing !== class_drawing) {
      const { result } = class_drawing;
      if (result.success) {
        this.setState(
          {
            class_drawing: _.get(result, "response.data", {}),
          },
          () => {
            // Get classroom session details
            getClassRoomSessionRequest({ id: class_id });
          }
        );
      } else {
        // class id is not found
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.save_drawing !== save_drawing) {
      const { result } = save_drawing;
      if (!result.success) {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.call_parents !== call_parents) {
      const { result } = call_parents;
      if (!result.success) {
        this.setState({ error: _.get(result, "response.message", "") });
        Toastr.error(result.response.message);
      } else {
        Toastr.success(result.response.message);
        this.setState({
          isReminderSent: (this.isReminderSent || 0) + 1,
          lastReminder: moment().tz(_CONST.TIMEZONE).format(),
        });
      }
    }

    if (prevProps.save_video !== save_video) {
      const { result } = save_video;
      if (!result.success) {
        this.setState({ error: _.get(result, "response.message", "") });
      } else {
        document.getElementById("notifyHolder").style.display = "block";
        setTimeout(function () {
          document.getElementById("notifyHolder").style.display = "none";
        }, 5000);
      }
    }

    if (prevProps.remove_drawing !== remove_drawing) {
      const { result } = remove_drawing;
      if (result.success) {
        const { current_slide, slides, class_id } = this.state;
        const current_index = _.findIndex(slides, function (s) {
          return s.id === current_slide.id;
        });

        // socket call after clearing the draw
        this.socketRef.current.emit("slide_change", {
          current_index,
          current_course_material_slide_uuid: slides[current_index].id,
          class_id,
        });
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

  }


  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handlePageLoad, false);
    clearTimeout(this.timeOutId);
    try {
      // Commenting because client wants blue background
      // EL_HTML.classList.remove("join-room");
      const { class_id } = this.state
      const { user_type } = this.props;
      if (user_type === "teacher") {
        this.socketRef.current.emit("screen_sharing", { class_id: class_id, isScreenSharing: false });
        this.props.screenSharedByteacher({
          id: class_id,
          screen_shared_by_teacher: false
        });
      }
      navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(
        function (stream) {
          stream.getTracks().forEach(function (track) {
            track.stop();
          });
        });
      clearInterval(this.intervalId);
    } catch { }
  }

  addStreamStopListener = (stream, callback) => {
    stream.addEventListener(
      "ended",
      function () {
        callback();
        callback = function () { };
      },
      false
    );
    stream.addEventListener(
      "inactive",
      function () {
        callback();
        callback = function () { };
      },
      false
    );
    stream.getTracks().forEach(function (track) {
      track.addEventListener(
        "ended",
        function () {
          callback();
          callback = function () { };
        },
        false
      );
      track.addEventListener(
        "inactive",
        function () {
          callback();
          callback = function () { };
        },
        false
      );
    });
  };

  /* SCREEN CAPTURE API's */

  saveVideoFilesOnServer = () => {
    blob = new Blob(blobs, { type: "video/webm" });
    blobs = [];

    const extension = blob.type.split("/")[1];
    const fileName =
      new Date()
        .toISOString()
        .replace(/[-:.]/g, "") +
      "." +
      extension;
    const { class_id } = this.state;
    const data = new FormData();
    data.append("video", blob, fileName);
    data.append("classroom_session_uuid", class_id);
    data.append("file_name", fileName);

    // Upload Rercorded Video To Server
    this.props.saveClassRoomVideo(data);
  }

  mergeAudioStreams = (desktopStream, voiceStream) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasDesktop = false;
    let hasVoice = false;
    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      // If you don't want to share Audio from the desktop it should still work with just the voice.
      const source1 = context.createMediaStreamSource(desktopStream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
      hasDesktop = true;
    }

    if (voiceStream && voiceStream.getAudioTracks().length > 0) {
      const source2 = context.createMediaStreamSource(voiceStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source2.connect(voiceGain).connect(destination);
      hasVoice = true;
    }

    return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : [];
  };

  /* SCREEN RECORDING  */

  onPlay = () => {
    this.setState({ play: true }, () => {
      let teacherAudioTime = document.getElementById("audio1");
      this._updateAudioControl({
        state: this.state.play,
        teacherAudioTime: teacherAudioTime.currentTime,
      });
    });
  };
  onPause = () => {
    this.setState({ play: false }, () => {
      let teacherAudioTime = document.getElementById("audio1");
      this._updateAudioControl({
        state: this.state.play,
        teacherAudioTime: teacherAudioTime.currentTime,
      });
    });
  };

  onSkip = () => {
    let teacherAudioTime = document.getElementById("audio1");
    this._updateAudioControl({
      state: this.state.play,
      teacherAudioTime: teacherAudioTime.currentTime,
    });
  };

  onEndAudio = () => {
    this.setState({ ...this.state, isAudioPlaying: false });
  };

  _adjustTheme = () => {
    try {
      // Commenting because client wants blue background
      //EL_HTML = document.getElementsByTagName("html")[0];
      //EL_HTML.classList.add("join-room");
    } catch { }
  };


  // Get current slide to display on screen
  _getCurrentSlide = (slides) => {
    const { class_session } = this.state;
    let slide_index = 0;

    try {
      if (slides.length > 0) {
        let slide = slides[0];

        if (!_.isEmpty(class_session.current_course_material_slide_uuid)) {
          slide_index = _.findIndex(slides, (c) => {
            return c.id === class_session.current_course_material_slide_uuid;
          });
          if (slide_index != -1) {
            slide = slides[slide_index];
          } else {
            slide = slides[0];
            slide_index = 0;
          }
        }

        getClassRoomSlide({ id: slide.id }).then((res) => {
          const data = _.get(res, "response.data", {});

          slides[slide_index] = {
            ...slides[slide_index],
            path: `data:${data.content_type};base64,${data.encoded_base64_data}`,
            audioPath: data.course_audio
              ? `data:audio/mp3; base64, ${data.course_audio.audio_data}`
              : `#`,
            audio_x_cordinate: data.course_audio
              ? data.course_audio.x_cordinate
              : 300,
            audio_y_cordinate: data.course_audio
              ? data.course_audio.y_cordinate
              : 100,
          };

          this._loadSlidesInBackground(slide_index, slides); // load the slides in background

          this.setState(
            {
              slides,
              current_slide: {
                ...slide,
                audio_x_cordinate: data.course_audio
                  ? data.course_audio.x_cordinate
                  : 300,
                audio_y_cordinate: data.course_audio
                  ? data.course_audio.y_cordinate
                  : 100,
                path: `data:${data.content_type};base64,${data.encoded_base64_data}`,
                audioPath: data.course_audio
                  ? `data:audio/mp3; base64, ${data.course_audio.audio_data}`
                  : `#`,
              },
              render: true,
            },
            () => this._initFabricCanvas()
          );
        });
      } else {
        return {};
      }
    } catch {
      return {};
    }
  };

  // FETCH THE SLIDES IMAGES IN BACKGROUND FROM API
  _loadSlidesInBackground = (current_index, slides) => {
    local_slides = slides; // store locally all slides
    let new_slide_arr = [];

    // try to load the adjoining slides before fetching rest of the slides
    new_slide_arr.push(slides[current_index]);

    if (current_index !== 0) {
      if (current_index + 1 !== slides.length)
        new_slide_arr.push(slides[current_index + 1]);
      new_slide_arr.push(slides[current_index - 1]);
    } else {
      if (slides.length > 1) new_slide_arr.push(slides[current_index + 1]);
    }

    _.map(slides, (slide, index) => {
      if (
        index !== current_index &&
        index !== current_index - 1 &&
        index !== current_index + 1
      ) {
        new_slide_arr.push(slide);
      }
      return slide;
    });

    _.map(new_slide_arr, (slide) => {
      if (!_.has(slide, "path")) {
        getClassRoomSlide({ id: slide.id }).then((res) => {
          const data = _.get(res, "response.data", {});

          const matched_index = _.findIndex(local_slides, (ls) => {
            return ls.id === slide.id;
          });

          local_slides[matched_index] = {
            ...local_slides[matched_index],
            path: `data:${data.content_type};base64,${data.encoded_base64_data}`,
            audioPath: data.course_audio
              ? `data:audio/mp3; base64, ${data.course_audio.audio_data}`
              : `#`,
          };
        });
      }
    });
  };

  // Get slide drawing
  _getSlideDrawing = (slide_uuid) => {
    const { class_drawing } = this.state;
    try {
      const found = _.find(class_drawing, (c) => {
        return c.course_material_slide_uuid === slide_uuid;
      });
      return !_.isEmpty(found) ? found.canvas_json_data : null;
    } catch {
      return null;
    }
  };



  // Validate class room session against login user
  _validClassSession = (class_session) => {
    const { user, user_type } = this.props;

    try {
      const end_at = moment(class_session.end_at)
        .tz(_CONST.TIMEZONE)
        .add(
          _.has(class_session, "extended_min")
            ? class_session.extended_min || 0
            : 0,
          "minutes"
        );

      const time_expired = moment().tz(_CONST.TIMEZONE).isAfter(end_at);
      if (!time_expired) {
        const check_uuid =
          user_type === "teacher"
            ? class_session.teacher_account_uuid
            : class_session.student_account_uuid;
        return user.uuid === check_uuid;
      }
    } catch {
      return false;
    }
    return false;
  };

  // Initial loading
  _initFabricCanvas = () => {
    const { class_id, current_slide } = this.state;
    const { user_type } = this.props;
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d");

    // Colors
    const colors = document.getElementsByClassName("color");

    // Loop through the color elements and add the click event listeners
    for (let i = 0; i < colors.length; i++) {
      colors[i].addEventListener("click", this.onColorUpdate, false);
    }


    this.initiateFabricCanvas(true, class_id, current_slide, user_type)

    // Make the canvas fill its parent component
    window.addEventListener("resize", this.onResize, false);
  }


  // Helper that will update the current color
  onColorUpdate = (e) => {
    current.color = e.target.className.split(" ")[1];
    const { user_type } = this.props
    user_type === 'teacher' ? this.setState({ textColorForTeacher: current.color }) : this.setState({ textColorForStudent: current.color })
    if (this.state.textCanvas) {
      this.state.textCanvas.freeDrawingBrush.color = current.color;
    }
  };

  // Draw line on canvas
  drawLine = (x0, y0, x1, y1, color, emit, save_drawing = false) => {
    try {
      const { class_id, current_slide } = this.state;
      const canvas = this.canvasRef.current;
      const context = canvas.getContext("2d");

      context.beginPath();
      context.moveTo(x0, y0);
      context.lineTo(x1, y1);
      context.strokeStyle = color;
      context.lineWidth = 2;
      context.stroke();
      context.closePath();

      if (!emit) {
        return;
      }
      const w = canvas.width;
      const h = canvas.height;

      this.socketRef.current.emit("drawing", {
        x0: x0 / w,
        y0: y0 / h,
        x1: x1 / w,
        y1: y1 / h,
        color,
        class_id,
      });

      // save canvas drawing in database
      if (save_drawing) {
        this.props.saveClassRoomDrawingRequest({
          classroom_session_uuid: class_id,
          course_material_slide_uuid: _.get(current_slide, "id", ""),
          canvas_json_data: this.state.textCanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor'])
        });
      }
    } catch { }
  };

  // Mouse movement
  onMouseDown = (e) => {
    try {
      var c = document.getElementById("canvas");
      if (e.type === 'touchstart' && e.target === c) {
        e.preventDefault()
      }
      drawing = true;

      var pos = getComputedStyle(document.body)
      var x =
        (e.clientX || e.touches[0].clientX) / pos.zoom - c.getBoundingClientRect().left;
      var y =
        (e.clientY || e.touches[0].clientY) / pos.zoom - c.getBoundingClientRect().top;

      current.x = x;
      current.y = y;
    } catch { }
  };

  onMouseMove = (e) => {
    try {
      if (!drawing) {
        return;
      }
      var c = document.getElementById("canvas");
      var pos = getComputedStyle(document.body)
      var x1 =
        (e.clientX || e.touches[0].clientX) / pos.zoom - c.getBoundingClientRect().left;
      var y1 =
        (e.clientY || e.touches[0].clientY) / pos.zoom - c.getBoundingClientRect().top;

      this.drawLine(current.x, current.y, x1, y1, current.color, true);

      current.x = x1;
      current.y = y1;
    } catch { }
  };

  onMouseUp = (e) => {
    try {
      if (!drawing) {
        return;
      }
      drawing = false;

      var c = document.getElementById("canvas");

      var pos = getComputedStyle(document.body)
      var x1 =
        (e.clientX || e.touches[0].clientX) / pos.zoom - c.getBoundingClientRect().left;
      var y1 =
        (e.clientY || e.touches[0].clientY) / pos.zoom - c.getBoundingClientRect().top;
      this.drawLine(current.x, current.y, x1, y1, current.color, true, true);
    } catch { }
  };

  // Limit the number of events per second
  throttle = (callback, delay) => {
    let previousCall = new Date().getTime();
    return function () {
      const time = new Date().getTime();

      if (time - previousCall >= delay) {
        previousCall = time;
        callback.apply(null, arguments);
      }
    };
  };

  onResize = () => {
    // window.location.reload();
  };

  onDrawingEvent = (data) => {
    try {
      const canvas = this.canvasRef.current;
      const w = canvas.width;
      const h = canvas.height;

      this.drawLine(
        data.x0 * w,
        data.y0 * h,
        data.x1 * w,
        data.y1 * h,
        data.color,
        false
      );
    } catch { }
  };


  onDrawingImage = (data) => {
    const { user_type } = this.props
    if (this.state.textCanvas) {

      // draw text from the teacher to the student side
      let jsonData = data.jsonData;
      if (user_type === 'student') {
        if (!this.state.isStudentAllowedToWrite) {
          jsonData?.objects?.forEach(obj => {
            if (obj.type === 'textbox' && obj.owner === 'teacher') {
              obj.editable = false
              obj.deleteable = false
              obj.selectable = false
            }
          })
        } else {
          jsonData?.objects?.forEach(obj => {
            if (obj.type === 'textbox' && obj.owner === 'teacher') {
              obj.editable = false
              obj.deleteable = false
              obj.selectable = true
            }
          })
        }

      } else if (user_type === 'teacher') {
        jsonData?.objects?.forEach(obj => {
          if (obj.type === 'textbox' && obj.owner === 'teacher') {
            obj.editable = true
            obj.selectable = true
          }
        })
      }
      this.state.textCanvas.loadFromJSON(jsonData, this.state.textCanvas.renderAll.bind(this.state.textCanvas));
      this.saveMyDrawings(this.state.textCanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor']), false);
    }
  }

  textboxFalse = (data) => {
    var obj = this.state.textCanvas.getObjects()
    obj.forEach((obj, index) => {
      this.state.textCanvas.item(index).isAdded = false;
    })
    this.state.textCanvas.renderAll();
  }

  onSlideChangeEvent = (data) => {
    try {
      const { slides } = this.state;
      let all_slides = [...slides];
      let current_slide = all_slides[data.current_index];

      // try to find the image data in local_slides first
      const found = _.find(local_slides, (s) => {
        return s.id === current_slide.id && !_.isEmpty(s.path);
      });
      if (!_.isEmpty(found)) {
        if (_.has(found, "path") && !_.isEmpty(found.path)) {
          current_slide = {
            ...current_slide,
            path: found.path,
          };
        }
        if (_.has(found, "audioPath") && !_.isEmpty(found.audioPath)) {
          current_slide = {
            ...current_slide,
            audioPath: found.audioPath,
          };
        }
      }

      if (_.has(current_slide, "path")) {
        // avoid re-fetching image from BE if already fetched
        this._loadSlideForStudent(
          current_slide,
          all_slides,
          data.current_index,
          data.jsonData
        );
      } else {
        this.props.showPageLoader();
        getClassRoomSlide({ id: current_slide.id }).then((res_cs) => {
          const img_slide = _.get(res_cs, "response.data", {});
          let current_slide = {
            ...all_slides[data.current_index],
            path: `data:${img_slide.content_type};base64,${img_slide.encoded_base64_data}`,
            audioPath: data.course_audio
              ? `data:audio/mp3; base64, ${data.course_audio.audio_data}`
              : `#`,
          };

          this._loadSlideForStudent(
            current_slide,
            all_slides,
            data.current_index,
            data.jsonData
          );
        });
      }
    } catch {
      this.props.hidePageLoader();
    }
  };

  onAudioChangeEvent = (data) => {
    const audio_tag = document.getElementById("audio1");
    try {
      this.setState({ play: data.state, isAudioPlaying: data.state }, () => {
        audio_tag.currentTime = data.teacherAudioTime;
        this.state.play ? audio_tag.play() : audio_tag.pause();
      });
    } catch {
      this.props.hidePageLoader();
    }
  };

  _loadSlideForStudent = (current_slide, all_slides, current_index, jsonData) => {
    const { class_id } = this.state;
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d");

    // API call to get class drawing
    getClassRoomDrawing({ id: class_id }).then((result) => {
      const drawing_slides = _.get(result, "response.data", []);

      all_slides = _.map(all_slides, (slide) => {
        const found = _.find(drawing_slides, (sd) => {
          return sd.course_material_slide_uuid === slide.id;
        });
        slide.drawing = !_.isEmpty(found) ? found.canvas_json_data : null;
        return slide;
      });

      current_slide = {
        ...current_slide,
        drawing: all_slides[current_index].drawing,
      };

      all_slides[current_index] = { ...current_slide };

      this.setState(
        {
          current_slide,
          slides: all_slides,
        },
        () => {
          // apply the saved drawing on next slide
          this.props.hidePageLoader();
          this.state.textCanvas?.clear()
          jsonData?.objects?.forEach(obj => {
            if (obj.type === 'textbox' && obj.owner === 'teacher') {
              obj.editable = false
              obj.deleteable = false
            }
          })
          this.state.textCanvas.loadFromJSON(jsonData, this.state.textCanvas.renderAll.bind(this.state.textCanvas))
        }
      );
    });
  };


  _prevImage = () => {
    try {
      const { current_slide, slides } = this.state;
      let all_slides = [...slides];
      const canvas = this.canvasRef.current;
      const context = canvas.getContext("2d");
      const index = _.findIndex(all_slides, function (s) {
        return s.id === current_slide.id;
      });

      // store the drawing to current slide first
      all_slides[index] = {
        ...all_slides[index],
        drawing: this.state.textCanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor']),
      };


      let prev_slide = { ...all_slides[index - 1] };

      // try to find the image data in local_slides first
      const found = _.find(local_slides, (s) => {
        return s.id === prev_slide.id && !_.isEmpty(s.path);
      });
      if (!_.isEmpty(found)) {
        if (_.has(found, "path") && !_.isEmpty(found.path)) {
          prev_slide = {
            ...prev_slide,
            path: found.path,
          };
        }
        if (_.has(found, "audioPath") && !_.isEmpty(found.audioPath)) {
          prev_slide = {
            ...prev_slide,
            audioPath: found.audioPath,
          };
        }
      }

      if (_.has(prev_slide, "path")) {
        // avoid re-fetching image from BE if already fetched
        this.setState({ disabled_navigation: true, isAudioPlaying: false }, () => {
          this._updateSlideNavigation(
            prev_slide,
            all_slides,
            context,
            index - 1
          );
        });

        setTimeout(() => {
          this.setState({ disabled_navigation: false });
        }, 500);
      } else {
        getClassRoomSlide({ id: prev_slide.id }).then((res) => {
          const data = _.get(res, "response.data", {});

          // update slide array with image path on navigating to prev slide.
          // this will help to avoid the API call when re-visit the same slide.
          all_slides[index - 1] = {
            ...all_slides[index - 1],
            path: `data:${data.content_type};base64,${data.encoded_base64_data}`,
            audioPath: data.course_audio
              ? `data:audio/mp3; base64, ${data.course_audio.audio_data}`
              : `#`,
          };

          this._updateSlideNavigation(
            {
              ...prev_slide,
              path: `data:${data.content_type};base64,${data.encoded_base64_data}`,
              audioPath: data.course_audio
                ? `data:audio/mp3; base64, ${data.course_audio.audio_data}`
                : `#`,
            },
            all_slides,
            context,
            index - 1
          );
        });
      }
    } catch {
      this.props.hidePageLoader();
    }
  };

  _nextImage = () => {
    try {
      const { current_slide, slides } = this.state;
      let all_slides = [...slides];
      const canvas = this.canvasRef.current;
      const context = canvas.getContext("2d");
      const index = _.findIndex(all_slides, function (s) {
        return s.id === current_slide.id;
      });

      // first, store the drawing to current slide
      all_slides[index] = {
        ...all_slides[index],
        drawing: this.state.textCanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor']),
      };

      if (this.state.textCanvas) {
        this.state.textCanvas.clear();
      }

      let next_slide = { ...all_slides[index + 1] };

      // try to find the image data in local_slides first
      const found = _.find(local_slides, (s) => {
        return s.id === next_slide.id && !_.isEmpty(s.path);
      });
      if (!_.isEmpty(found)) {
        if (_.has(found, "path") && !_.isEmpty(found.path)) {
          next_slide = {
            ...next_slide,
            path: found.path,
          };
        }
        if (_.has(found, "audioPath") && !_.isEmpty(found.path)) {
          next_slide = {
            ...next_slide,
            audioPath: found.audioPath,
          };
        }
      }

      if (_.has(next_slide, "path")) {
        // avoid re-fetching image from BE if already fetched
        this.setState({ disabled_navigation: true, isAudioPlaying: false }, () => {
          this._updateSlideNavigation(
            next_slide,
            all_slides,
            context,
            index + 1
          );
        });

        setTimeout(() => {
          this.setState({ disabled_navigation: false });
        }, 500);
      } else {
        this.props.showPageLoader();
        getClassRoomSlide({ id: next_slide.id }).then((res) => {
          const data = _.get(res, "response.data", {});

          // update slide array with image path on navigating to next slide.
          // this will help to avoid the API call when re-visit the same slide.
          all_slides[index + 1] = {
            ...all_slides[index + 1],
            path: `data:${data.content_type};base64,${data.encoded_base64_data}`,
            audioPath: data.course_audio
              ? `data:audio/mp3; base64, ${data.course_audio.audio_data}`
              : `#`,
          };

          this._updateSlideNavigation(
            {
              ...next_slide,
              path: `data:${data.content_type};base64,${data.encoded_base64_data}`,
              audioPath: data.course_audio
                ? `data:audio/mp3; base64, ${data.course_audio.audio_data}`
                : `#`,
            },
            all_slides,
            context,
            index + 1
          );
        });
      }
    } catch {
      this.props.hidePageLoader();
    }
  };

  _clearCurrentCanvas = () => {
    try {
      const { current_slide, slides, class_id } = this.state;
      let all_slides = [...slides];
      const canvas = this.canvasRef.current;
      const context = canvas.getContext("2d");
      const current_index = _.findIndex(slides, function (s) {
        return s.id === current_slide.id;
      });
      this.state.textCanvas?.clear()
      all_slides[current_index] = {
        ...current_slide,
      };
      this.setState(
        {
          slides: all_slides,
        },
        () => {
          // Remove the entry from database on clear action
          this.socketRef.current.emit('drawing-image', {
            class_id,
            jsonData: [],
            place: 'from clear canvas method'
          })
          this.props.removeClassRoomDrawingRequest({
            classroom_session_uuid: class_id,
            course_material_slide_uuid: _.get(current_slide, "id", ""),
          });
        }
      );
    } catch { }
  };

  _updateSlideNavigation = (
    current_slide,
    all_slides,
    context,
    current_index
  ) => {
    const { class_id } = this.state;

    this.setState(
      {
        current_slide,
        slides: all_slides,
        tips_visible: false,
      },
      () => {
        this.props.hidePageLoader();
        this.socketRef.current.emit("slide_change", {
          current_index,
          current_course_material_slide_uuid: current_slide.id, // to maintain the slide index after reload and rejoin
          class_id,
          jsonData: current_slide.drawing
        });
        this.state.textCanvas?.clear();
        this.state.textCanvas?.loadFromJSON(current_slide.drawing, this.state.textCanvas.renderAll.bind(this.state.textCanvas));
      }
    );
  };

  _updateAudioControl = ({ state, teacherAudioTime }) => {
    const { class_id } = this.state;
    this.setState({}, () => {
      this.props.hidePageLoader();
      this.socketRef.current.emit("audio_change", {
        state,
        class_id,
        teacherAudioTime,
      });
    });
  };

  _disabledButton = (type) => {
    try {
      const { slides, current_slide } = this.state;
      const current_index = _.findIndex(slides, function (s) {
        return s.id === current_slide.id;
      });

      if (type === "prev") {
        return current_index === 0;
      } else if (type === "next") {
        return current_index === slides.length - 1;
      }
    } catch {
      return true;
    }
  };

  _showTips = () => {
    this.setState({ tips_visible: true });
  };

  _hideTips = () => {
    this.setState({ tips_visible: false });
  };

  playAudioButton = () => {
    const audio = document.getElementById("audio1");
    if (audio.paused) {
      this.setState({ ...this.state, isAudioPlaying: true });
      audio.play();
    } else {
      this.setState({ ...this.state, isAudioPlaying: false });
      audio.pause();
    }
  };

  _handleChageSelect = (value) => {
    this.setState({ isStatusChanged: true, classroomStatus: value });
  };

  _informParents = () => {
    let reminder_time = moment().seconds(0).format();
    _.replace(
      reminder_time = reminder_time,
      reminder_time.substring(reminder_time.length - 6),
      "+08:00"
    );
    this.props.callClassStudents({
      student_account_uuid: this.state.class_session.student_account_uuid,
      class_id: this.state.class_id,
      reminder_time: reminder_time
    });
  };

  _closeModal = () => {
    this.setState({ isStatusChanged: false });
  };

  _onUpdate = () => {
    const payload = {
      status: this.state.classroomStatus,
      sub_status: this.state.classroomSubstatus,
      reason: this.state.classroomReason,
      id: this.state.class_session.uuid,
    };
    if (payload.status === "Incomplete" && !payload.sub_status) {
      this.setState({
        sub_status_error: "Please select any one radion option",
        error: true,
      });
    } else {
      this.setState({ isStatusChanged: false }, () => {
        this.props.updateClassroomSessionRequest(payload);
        window.location.href = "/";
      });
    }
  };

  handleChange = (value) => {
    this.setState({ classroomSubstatus: Object.keys(value)[0] });
  };

  handleChangeReason = (value) => {
    this.setState({ classroomReason: Object.values(value)[0] });
  };

  handleZoomIn = (range) => {
    switch (range) {
      case zoomRange.one:
        return zoomRange.two;
      case zoomRange.two:
        return zoomRange.three;
      case zoomRange.three:
        return zoomRange.four;
      default:
        return zoomRange.four;
    }

  };

  handleZoomOut = (range) => {
    switch (range) {
      case zoomRange.four:
        return zoomRange.three;
      case zoomRange.three:
        return zoomRange.two;
      case zoomRange.two:
        return zoomRange.one;
      default:
        return zoomRange.one;
    }
  };

  changedTextboxSelectable = (value) => {
    var objects = this.state.textCanvas.getObjects();
    objects.forEach((obj, index) => {
      this.state.textCanvas.item(index).selectable = value
    })
    fabric.Object.prototype.evented = value;
    this.state.textCanvas.selection = value;
  }

  restrictTextWriting = (data) => {
    this.setState({ isStudentAllowedToWrite: data.isTextAllowedForStudent });
    if (data.isTextAllowedForStudent) {
      this.state.textCanvas.isDrawingMode = true;
      this.setState({ isDrawingModeForStudent: true });
      this.changedTextboxSelectable(true);
    } else {
      this.state.textCanvas.isDrawingMode = false;
      this.setState({ isDrawingModeForStudent: false });
      this.changedTextboxSelectable(false);
    }
    this.state.textCanvas.renderAll();
  }

  initiateFabricCanvas = (DrawingMode, class_id, current_slide, user_type) => {

    // Event to add textbox from mobile app
    const addTextBox = (txtObj) => {
      var textbox = new fabric.Textbox(txtObj.text, {
        left: txtObj.x,
        top: txtObj.y,
        strokeWidth: 1,
        fontSize: 19,
        stroke: txtObj.color,
        width: 100,
        fill: txtObj.color,
        borderColor: txtObj.borderColor,
        selectable: true,
        isAdded: txtObj.isAdded
      });

      fcanvas.add(textbox);
      fcanvas.renderAll();
      this.saveMyDrawings(fcanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor']));
    }

    const addPath = (textObj) => {
      let pointsArray = [...textObj.points]
      let convertedPoints = []
      pointsArray.forEach(point => {
        var sample = new fabric.Point(point.x, point.y)
        convertedPoints.push(sample)
      })

      // Convert Points to SVG Path
      var res = brush.convertPointsToSVGPath(convertedPoints);
      // Create the Path
      var path = brush.createPath(res.toString());
      // Set the tickness and color
      path.set({ strokeWidth: 3, stroke: textObj.color });
      // Add to the Canvas
      fcanvas.add(path);
      fcanvas.renderAll();
      this.saveMyDrawings(fcanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor']));
    }

    this.socketRef.current.on("restrict_text_writing", this.restrictTextWriting);
    this.socketRef.current.on("add_textbox", addTextBox);
    this.socketRef.current.on("add_path", addPath);

    var fcanvas = new fabric.Canvas("canvas", {
      width: 800,
      height: 595,
      isDrawingMode: DrawingMode,
      allowTouchScrolling: false
    });

    // by default all canvas objects not selectable
    fabric.Object.prototype.selectable = false;

    // by default canvas group not selectable
    fabric.Group.prototype.lockScalingX = true;
    fabric.Group.prototype.lockScalingY = true;


    var brush = new fabric.PencilBrush(fcanvas)
    fcanvas.freeDrawingBrush = brush
    fcanvas.freeDrawingBrush.width = 2;
    fcanvas.freeDrawingBrush.color = current.color
    var drawing = false


    fcanvas.on('path:created', (event) => {
      var emptyText = fcanvas.getObjects().filter(obj => obj.text === '');
      if (emptyText.length > 1) {
        emptyText.forEach(textbox => {
          fcanvas.remove(textbox)
        })
      }
      fcanvas.renderAll();
      this.saveMyDrawings(fcanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor']));
    });

    fcanvas.on('text:changed', (e) => {
      if (!fcanvas.isDrawingMode) {
        fcanvas.renderAll();
        timeOutId = setTimeout(() => {
          const canvasText = fcanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor']);
          const currentCavasText = canvasText && canvasText.objects && canvasText.objects.length > 0 ? canvasText.objects[canvasText.objects.length - 1].text : {};
          const lastCavasText = this.state.lastTextCanvasChange && this.state.lastTextCanvasChange.objects && this.state.lastTextCanvasChange.objects.length > 0 ? this.state.lastTextCanvasChange.objects[this.state.lastTextCanvasChange.objects.length - 1].text : {};

          if (currentCavasText !== lastCavasText) {
            this.saveMyDrawings(fcanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor']));
            this.setState({
              ...this.state,
              lastTextCanvasChange: canvasText
            })
          }
        }, 1000);
      }
    });

    fcanvas.on('object:modified', (e) => {
      if (!fcanvas.isDrawingMode) {
        var emptyText = fcanvas.getObjects().filter(obj => obj.text === '');
        if (emptyText.length > 1) {
          emptyText.forEach(textbox => {
            fcanvas.remove(textbox)
          })
        }
        fcanvas.renderAll();
        this.saveMyDrawings(fcanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor']));
      }
    });

    fcanvas.on('selection:created', function (o) {
      var grp = fcanvas.getActiveObjects();
      if (grp.length > 1) {
        fcanvas.discardActiveObject();
      }
      var allObj = fcanvas.getObjects();
      fcanvas.item(allObj.length - 1).set({ editingBorderColor: 'black' });

    });

    fcanvas.on('mouse:dblclick', (event) => {

      if (user_type === 'student' && !this.state.isStudentAllowedToWrite) {
        return;
      }

      // get active object and dont add textbox when click on active object
      const activeObj = fcanvas.getActiveObject();
      if (!fcanvas.isDrawingMode) {
        var pos = getComputedStyle(document.body)
        var c = document.getElementById("canvas")
        let x = event.e.clientX / pos.zoom - c.getBoundingClientRect().left;
        let y = event.e.clientY / pos.zoom - c.getBoundingClientRect().top;
        let textBoxSize = 300;
        let remainSize = (c.getBoundingClientRect().width - x);
        x = remainSize > textBoxSize ? x : x - (textBoxSize - remainSize)
        var textbox = new fabric.Textbox('', {
          left: x,
          top: y,
          strokeWidth: 1,
          fontSize: 19,
          stroke: current.color,
          width: textBoxSize,
          fill: current.color,
          selectable: true,
          borderColor: 'black',
          lineHeight: 1
        })

        if (user_type === 'teacher') {
          textbox.toObject = (function (toObject) {
            return function (propertiesToInclude) {
              return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                owner: 'teacher',
                editable: true,
                deleteable: true,
                isAdded: false,
                borderColor: 'black'
              });
            };
          })(textbox.toObject);
        }
        fcanvas.add(textbox);
        fcanvas.setActiveObject(textbox)
        textbox.enterEditing()
        textbox.hiddenTextarea.focus();
        fcanvas.renderAll();
        this.saveMyDrawings(fcanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor']));
      }
    });

    this.setState({ textCanvas: fcanvas }, () => {
      // Socket event invoke
      const { user_type } = this.props;
      if (user_type === 'student') {
        if (this.state.class_session.restrict_text_for_student) {
          this.state.textCanvas.isDrawingMode = true;
          this.setState({ isDrawingModeForStudent: true })
        }
        this.setState({ isScreenSharedByTeacher: this.state.class_session.screen_shared_by_teacher })
      }
      if (!_.isEmpty(class_id)) {
        this.socketRef.current.on("drawing-image", this.onDrawingImage);
        this.socketRef.current.on("slide_change", this.onSlideChangeEvent);
        this.socketRef.current.on("added_false", this.textboxFalse);

        if (user_type === "student") {
          this.socketRef.current.on("audio_change", this.onAudioChangeEvent);
        }
      }

      // Apply preserved slide drawing on canvas if exists
      if (!_.isEmpty(current_slide.drawing)) {
        let jsonData = JSON.parse(current_slide.drawing)
        if (user_type === 'student' && !this.state.isStudentAllowedToWrite) {
          jsonData.objects.forEach((obj) => {
            if (obj.type === 'textbox')
              obj.selectable = false
          })
        } else {
          jsonData.objects.forEach((obj) => {
            if (obj.type === 'textbox')
              obj.selectable = true
          })
        }
        this.state.textCanvas.loadFromJSON(jsonData, this.state.textCanvas.renderAll.bind(this.state.textCanvas))
      }
    });
  }

  saveMyDrawings = (json, emit = true) => {
    const { class_id, current_slide } = this.state
    if (emit) {
      this.socketRef.current.emit("drawing-image", {
        class_id,
        jsonData: json,
        place: "from save My Drawings method"
      });
    }
    this.props.saveClassRoomDrawingRequest({
      classroom_session_uuid: class_id,
      course_material_slide_uuid: _.get(current_slide, "id", ""),
      canvas_json_data: JSON.stringify(json),
    });
  }

  renderWriteTextButton = () => {
    const { user_type } = this.props;
    if (user_type === 'teacher') {
      return (
        <div
          className={this.state.isDrawingModeForTeacher === false ? "button tool-buttons-active button-spacing" : "button button-spacing"}
          onClick={() => {
            this.state.textCanvas.isDrawingMode = false;
            this.setState({ isDrawingModeForTeacher: false });
          }}
        >
          <FaTextHeight />
        </div>
      )
    }
    else {
      if (this.state.isStudentAllowedToWrite === true) {
        return (
          <div
            className={this.state.isDrawingModeForStudent === false ? "button tool-buttons-active button-spacing" : "button button-spacing"}
            onClick={() => {
              this.state.textCanvas.isDrawingMode = false;
              this.setState({ isDrawingModeForStudent: false });
            }}
          >
            <FaTextHeight />
          </div>
        )
      }
    }
  }

  renderDrawingButton = () => {
    const { user_type } = this.props;
    if (user_type === 'teacher') {
      return (
        <button
          className={this.state.isDrawingModeForTeacher === true ? "button tool-buttons-active button-spacing" : "button button-spacing"}
          onClick={() => {
            if (this.state.textCanvas) {
              this.state.textCanvas.isDrawingMode = true;
              this.setState({ isDrawingModeForTeacher: true });
            }
          }}
        >
          <FaPen />
        </button>
      )
    }
    else {
      if (this.state.isStudentAllowedToWrite === true) {
        return (
          <button
            className={this.state.isDrawingModeForStudent === true ? "button tool-buttons-active button-spacing" : "button button-spacing"}
            onClick={() => {
              if (this.state.textCanvas) {
                this.state.textCanvas.isDrawingMode = true;
                this.setState({ isDrawingModeForStudent: true });
              }
            }}
          >
            <FaPen />
          </button>
        )
      }
    }
  }

  whiteboardButtons = () => {
    const { user_type } = this.props;
    return (
      <>
        {
          this.renderDrawingButton()
        }
        {
          this.renderWriteTextButton()
        }

        {
          user_type === 'teacher' && (
            <div className="button-spacing" title="stop student writing on whiteboard">
              <Switch
                onChange={() => {

                  const { class_id } = this.state
                  this.socketRef.current.emit("restrict_text_writing", {
                    class_id,
                    isTextAllowedForStudent: !this.state.isStudentAllowedToWrite
                  });
                  this.setState({ isStudentAllowedToWrite: !this.state.isStudentAllowedToWrite }, () => {
                    this.props.restrictText({
                      id: class_id,
                      restrict_text_for_student: !this.state.isStudentAllowedToWrite
                    })
                  })

                }}
                checked={this.state.isStudentAllowedToWrite}
                onColor="#ffc782"
                onHandleColor={this.state.isStudentAllowedToWrite === true ? "#ff8c00" : "#FFFFFF"}
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
              />
            </div>
          )
        }

        {(user_type === 'teacher' || (user_type === 'student' && this.state.isStudentAllowedToWrite)) && <button
          className="button tool-buttons button-spacing"
          onClick={() => {
            if (user_type === 'student') {
              if (this.state.textCanvas) {
                let current = this.state.textCanvas.getActiveObject();
                if (!current?.hasOwnProperty("deleteable")) {
                  this.state.textCanvas.remove(this.state.textCanvas.getActiveObject());
                } else {
                  Toastr.error('You can not delete the textbox')
                }
              }
            } else {
              this.state.textCanvas.remove(this.state.textCanvas.getActiveObject());
            }
            this.saveMyDrawings(this.state.textCanvas.toJSON(['editable', 'owner', 'deleteable', 'selectable', 'isAdded', 'borderColor']))

          }}
        >
          <FaTrash />
        </button>}
      </>
    )
  }

  // Timer
  handleUpdateTrackingTime = (time) => {
    const payload = {
      time,
      id: this.state.class_session.uuid,
    };
    this.props.updateTrackingTimeRequest(payload);
  }

  handleTimerStart = () => {
    this.setState({
      timerStatus: true,
    });

    this.timer = setInterval(() => {
      this.setState({
        timerTime: this.state.timerTime + 1000,
      });
    }, 1000);

    this.timerUpdate = setInterval(() => {
      this.handleUpdateTrackingTime(this.state.timerTime)
    }, 10000);
  };

  handleTimerStop = () => {
    this.handleUpdateTrackingTime(this.state.timerTime)
    this.setState({
      timerStatus: false,
    });
    clearInterval(this.timer);
    clearInterval(this.timerUpdate);
  };

  handleTimerReset = () => {
    this.setState({
      timerStatus: false,
      timerTime: 0,
    });
    clearInterval(this.timer);
    clearInterval(this.timerUpdate);
    this.handleUpdateTrackingTime(0)
  };

  render() {
    const {
      current_slide,
      tips_visible,
      class_session,
      render,
      error,
      disabled_navigation,
      isStatusChanged,
      classroomStatus,
      sub_status_error,
      isReminderSent,
      timerTime,
      lastReminder,
      timerStatus,
      class_id,
      isScreenSharedByTeacher,
      isScreenSharingAllowed
    } = this.state;
    const { user, user_type } = this.props;
    let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    let minutes = ("0" + (Math.floor(timerTime / 60000) % 60)).slice(-2);
    let hours = ("0" + Math.floor(timerTime / 3600000)).slice(-2);
    return (
      <div>
        {isStatusChanged && (
          <UpdateStatusPopUp
            show={isStatusChanged}
            onClose={this._closeModal}
            onUpdate={this._onUpdate}
          >
            <div>
              <p className="modal-title">Are you sure to update the status ?</p>
              {classroomStatus === "Incomplete" && (
                <div>
                  <p>Please select any reason</p>
                  <form onSubmit={this._onUpdate}>
                    <div className="control radio-list">
                      <label className="radio">
                        <input
                          type="radio"
                          name="class_status"
                          onChange={({ target: { value } }) =>
                            this.handleChange({ "Cancelled by student": value })
                          }
                        />
                        Cancelled by student
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          name="class_status"
                          onChange={({ target: { value } }) =>
                            this.handleChange({ "Cancelled by teacher": value })
                          }
                        />
                        Cancelled by teacher
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          name="class_status"
                          onChange={({ target: { value } }) =>
                            this.handleChange({
                              "Regular student no-show": value,
                            })
                          }
                        />
                        Regular student no-show
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          name="class_status"
                          onChange={({ target: { value } }) =>
                            this.handleChange({
                              "Trial student no-show": value,
                            })
                          }
                        />
                        Trial student no-show
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          name="class_status"
                          onChange={({ target: { value } }) =>
                            this.handleChange({ "Teacher no-show": value })
                          }
                        />
                        Teacher no-show
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          name="class_status"
                          onChange={({ target: { value } }) =>
                            this.handleChange({ Others: value })
                          }
                        />
                        Others
                      </label>
                    </div>
                    <p
                      className="help is-danger ml15"
                      style={{ fontSize: "20px" }}
                    >
                      {error && sub_status_error}
                    </p>
                    <div className="field">
                      <div className="control">
                        <textarea
                          id="reason"
                          type="text"
                          className={`textarea`}
                          placeholder="Reason to cancel the session"
                          onChange={({ target: { value } }) =>
                            this.handleChangeReason({ value })
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </UpdateStatusPopUp>
        )}
        <div className="container">
          {render && user_type === "student" && <WalletNotification />}
          {!_.isEmpty(error) && (
            <section className="section pb0 pt10">
              ,<div className="notification">{error}</div>
            </section>
          )}
          <br />
          {render && (
            <section className="section pb0 pt0">
              <div className="container">
                <div className="columns">
                  <div className="teacher-name-container">
                    <div className="info-class-holder">
                      <div className="teacher-student-name">
                        <span className="icon-holder">
                          <FaAngleRight />
                        </span>
                        <span className="value-container-nm">Teacher:</span>
                        <span>
                          {
                            this.state.class_session.teacher_account.profile
                              .fullname
                          }
                        </span>
                      </div>
                      <div className="teacher-student-name">
                        <span className="icon-holder">
                          <FaAngleRight />
                        </span>
                        <span className="value-container-nm">Student:</span>
                        <span>
                          {this.state.class_session.account.profile.fullname}
                        </span>
                      </div>
                    </div>
                    <div className="info-class-holder">
                      <div className="teacher-student-name">
                        <span className="icon-holder">
                          <FaAngleRight />
                        </span>
                        <span
                          className="value-container-nm"
                          style={{ marginRight: "35px" }}
                        >
                          Time:
                        </span>
                        <span>
                          {`${moment(this.state.class_session.start_at)
                            .tz(_CONST.TIMEZONE)
                            .format("YYYY - MM - DD h:mmA")} - ${moment(
                              this.state.class_session.end_at
                            )
                              .tz(_CONST.TIMEZONE)
                              .format("h:mmA")}`}
                        </span>
                      </div>
                      <div className="teacher-student-name">
                        <span className="icon-holder">
                          <FaAngleRight />
                        </span>
                        <span className="value-container-nm">Course:</span>
                        <span>{this.state.class_session.course.name} {user_type === "teacher" && this.state.slides && this.state.slides.length && `(${this.state.slides.length} slides)`}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                user_type === "teacher" ?
                  <div className="container-button">
                    <div className="button-holder">
                      <button
                        className="button is-primary button-color"
                        onClick={this._informParents}
                        disabled={this.checkTimePassed(this.state.lastReminder)}
                      >
                        Remind Parents
                      </button>
                    </div>
                  </div>
                  : ""
              }

              {user_type === "teacher" && this.state.class_session.allow_video_recording === "Allowed" && (<div className="container">
                <div className="columns">
                  <div
                    style={{
                      width: "88%",
                      height: "40px",
                      marginBottom: "20px",
                      margin: "auto",
                    }}
                  >
                    <div id="notifyHolder" className="notificationContainer">
                      Video has been saved successfully
                    </div>
                    <button
                      className="tag is-info record-button"
                      disabled={this.state.isStartRecord}
                      style={this.state.isStartRecord ? { border: "none", cursor: "pointer", backgroundColor: "rgb(220 160 87)" } : { border: "none", cursor: "pointer", backgroundColor: "#ff8c00" }}
                      onClick={async () => {
                        if (this.state.isRecording !== true) {
                          try {
                            this.setState({
                              isRecording: true,
                            });

                            const audio = true;
                            const mic = true;

                            desktopStream =
                              await navigator.mediaDevices.getDisplayMedia({
                                video: {
                                  cursor: "always",
                                  displaySurface: "browser",
                                },
                                cursor: "motion",
                                displaySurface: ["browser"],
                                selfBrowserSurface: "include",
                                audio: {
                                  autoGainControl: false,
                                  echoCancellation: false,
                                  noiseSuppression: false,
                                  googAutoGainControl: false
                                },
                              });

                            if (mic === true) {
                              voiceStream =
                                await navigator.mediaDevices.getUserMedia({
                                  video: false,
                                  audio: mic,
                                });
                            }

                            const tracks = [
                              ...desktopStream.getVideoTracks(),
                              ...this.mergeAudioStreams(
                                desktopStream,
                                voiceStream
                              ),
                            ];

                            stream = new MediaStream(tracks);

                            blobs = [];

                            rec = new MediaRecorder(stream, {
                              mimeType: "video/webm; codecs=vp8,opus",
                            });

                            const fileName =
                              new Date()
                                .toISOString()
                                .replace(/[-:.]/g, "") + ".webm";

                            var chunkCount = 1;

                            rec.ondataavailable = (e) => {

                              if (e.data.size > 0) {
                                this.socketVideoRecordRef.current.emit('blobs',
                                  {
                                    "file_name": fileName,
                                    "data": e.data,
                                    "classroom_id": this.state.class_id,
                                    "chunkCount": chunkCount++,
                                    "session_start": moment(this.state.class_session.start_at).tz(_CONST.TIMEZONE).format("YYYY-MM-DD h:mmA"),
                                    "session_end": moment(this.state.class_session.end_at).tz(_CONST.TIMEZONE).format("YYYY-MM-DD h:mmA")
                                  });
                              }
                            };

                            rec.onstop = async () => {
                              await this.socketVideoRecordRef.current.emit('blobs_complete',
                                {
                                  "classroom_id": this.state.class_id,
                                  "file_name": fileName
                                }, () => {
                                  this.socketVideoRecordRef.current.disconnect(true);
                                });
                              // Close socket connection
                              // this.socketVideoRecordRef.current.disconnect(true);

                              // Show video saved success message
                              document.getElementById("notifyHolder").style.display = "block";
                              setTimeout(function () {
                                document.getElementById("notifyHolder").style.display = "none";
                              }, 5000);
                            };

                            // Establish socket connection
                            this.socketVideoRecordRef.current = io.connect(API_BASE_URL, {
                              transports: ["websocket"],
                              secure: true,
                            });

                            // scroll to see video screen properly in a little center
                            window.scrollTo(0, document.body.scrollHeight);

                            // start recording
                            rec.start(3000);
                            this.setState({
                              isStartRecord: true
                            }, () => {
                              setTimeout(() => {
                                this.setState({
                                  isStartRecord: false
                                });
                              }, 10000);
                            })

                          } catch (err) {
                            this.setState({
                              isRecording: false,
                            });
                          }
                        } else {
                          this.setState(
                            {
                              isRecording: false,
                            },
                            () => {
                              rec.stop();
                              stream.getTracks().forEach((s) => s.stop());
                              desktopStream = null;
                              stream = null;
                            }
                          );
                        }
                      }}
                    >
                      {this.state.isRecording !== true
                        ? "RECORD VIDEO"
                        : "STOP RECORDING"}
                    </button>
                  </div>
                </div>
              </div>
              )}

              <div className="container" ref={this.buttonContainer}>
                <div className="columns">
                  <div className="column canvas-offset">
                    <div className="columns">
                      <div className={user_type === "teacher" ? "column is-3 colors-containet-wt" : "column is-3"}>
                        <div className={isScreenSharedByTeacher && user_type === "student" ? "hidden" : "colors"}>
                          {
                            user_type === "teacher" ? <> <div className="color black" style={this.state.textColorForTeacher === 'black' ? { border: "2px solid white" } : {}} />
                              <div className="color red" style={this.state.textColorForTeacher === 'red' ? { border: "2px solid white" } : {}} />
                              <div className="color green" style={this.state.textColorForTeacher === 'green' ? { border: "2px solid white" } : {}} />
                              <div className="color blue" style={this.state.textColorForTeacher === 'blue' ? { border: "2px solid white" } : {}} />
                              <div className="color yellow" style={this.state.textColorForTeacher === 'yellow' ? { border: "2px solid white" } : {}} /></>
                              :
                              <>
                                <div className="color black" style={this.state.textColorForStudent === 'black' ? { border: "2px solid white" } : {}} />
                                <div className="color red" style={this.state.textColorForStudent === 'red' ? { border: "2px solid white" } : {}} />
                                <div className="color green" style={this.state.textColorForStudent === 'green' ? { border: "2px solid white" } : {}} />
                                <div className="color blue" style={this.state.textColorForStudent === 'blue' ? { border: "2px solid white" } : {}} />
                                <div className="color yellow" style={this.state.textColorForStudent === 'yellow' ? { border: "2px solid white" } : {}} />
                              </>
                          }

                        </div>
                      </div>
                      {user_type === "student" && (
                        <>
                          <div className="column is-5 zoom-in-out-uttons-holder">
                            {/* {this.whiteboardButtons()} */}
                            <button
                              className="button is-primary"
                              onClick={() => {
                                this.setState(
                                  {
                                    current_zoom_value: this.handleZoomOut(
                                      this.state.current_zoom_value
                                    ),
                                  },
                                  function () {
                                    document.body.style.transform = `scale(${this.state.current_zoom_value})`
                                    window.scrollTo(0, 0);
                                  }
                                );
                              }}
                              title="zoom out"
                            >
                              <FaMinusCircle />
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="button is-primary"
                              onClick={() => {
                                this.setState(
                                  {
                                    current_zoom_value: this.handleZoomIn(
                                      this.state.current_zoom_value
                                    ),
                                  },
                                  function () {
                                    document.body.style.transform = `scale(${this.state.current_zoom_value})`

                                    if (
                                      this.state.current_zoom_value ===
                                      zoomRange.four
                                    ) {
                                      this.buttonContainer.current.scrollIntoView(
                                        { block: "start" }
                                      );
                                    }
                                  }
                                );
                              }}
                              title="zoom in"
                            >
                              <FaPlusCircle />
                            </button>
                          </div>
                        </>
                      )}
                      {user_type !== "student" && (
                        <div className="column pt5">
                          {/* {this.whiteboardButtons()} */}
                          <button
                            className="button is-primary"
                            onClick={this._prevImage}
                            disabled={
                              this._disabledButton("prev") ||
                              disabled_navigation
                            }
                          >
                            Back
                          </button>
                          &nbsp;&nbsp;
                          <button
                            className="button is-primary"
                            onClick={this._nextImage}
                            disabled={
                              this._disabledButton("next") ||
                              disabled_navigation
                            }
                          >
                            Next
                          </button>
                          &nbsp;&nbsp;
                          <button
                            className="button is-primary"
                            onClick={this._clearCurrentCanvas}
                            disabled={disabled_navigation}
                          >
                            Clear
                          </button>
                          &nbsp;&nbsp;
                          <div className="select is-info room-select-option">
                            <select
                              className="room-select"
                              onChange={({ target: { value } }) =>
                                this._handleChageSelect(value)
                              }
                              value={
                                class_session.status && class_session.status
                              }
                            >
                              <option value="Scheduled">Scheduled</option>
                              <option value="Completed">Completed</option>
                              <option value="Incomplete">Incomplete</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {render && user_type === "teacher" && (
                        <div className="column pt5 timer-container">
                          <TimerClock
                            timerStatus={timerStatus}
                            seconds={seconds}
                            minutes={minutes}
                            hours={hours}
                            handleTimerStart={this.handleTimerStart}
                            handleTimerStop={this.handleTimerStop}
                            handleTimerReset={this.handleTimerReset}
                          />
                        </div>
                      )}
                      {/* <span className="audioController disablecontrol">
                      {user_type == 'student' && current_slide.audioPath !== '#' && <audio id="audio" controls controlsList="nodownload" src={current_slide.audioPath} ></audio>}
                    </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>

        {render && (
          <div className="container">
            <section className="section drawing-section pt0">
              <div className="columns col-overflow">
                <div className={isScreenSharedByTeacher && user_type === "student" ? "hidden" : "tools-container"}>
                  <div className="tools-button-holder">
                    {this.whiteboardButtons()}
                  </div>
                </div>
                <div className="column canvas-offset">
                  <div className="columns is-gapless">
                    <div className={isScreenSharedByTeacher && user_type === "student" ? "hidden" : "column flex-grow"}>
                      <div className={`canvas-div ${user_type === 'student' ? 'student-canvas-div' : 'teacher-canvas-div'}`}>
                        <span
                          className={
                            user_type == "student"
                              ? "audioController disablecontrol"
                              : "audioController"
                          }
                        >
                          {current_slide.audioPath !== "#" && (
                            <div className="room-audio-button">
                              <audio
                                id="audio1"
                                controlsList="nodownload"
                                onPlay={this.onPlay}
                                onPause={this.onPause}
                                onEnded={this.onEndAudio}
                                onSeeked={this.onSkip}
                                src={current_slide.audioPath}
                              ></audio>
                              <button
                                style={{
                                  zIndex: "1",
                                  position: "absolute",
                                  marginTop: current_slide.audio_y_cordinate
                                    ? current_slide.audio_y_cordinate + "px"
                                    : "100px",
                                  marginLeft: current_slide.audio_x_cordinate
                                    ? current_slide.audio_x_cordinate + "px"
                                    : "300px",
                                }}
                                id={
                                  user_type == "student"
                                    ? "student-audio-button"
                                    : "audio-button"
                                }
                                className="button is-primary"
                                onClick={this.playAudioButton}
                              >
                                <img

                                  src={
                                    this.state.isAudioPlaying
                                      ? pauseButton
                                      : playButton
                                  }
                                  height="20"
                                  width="20"
                                ></img>
                              </button>
                            </div>
                          )}
                        </span>
                        {user_type !== "student" &&
                          !_.isEmpty(_.get(current_slide, "tips", "")) && (
                            <div className="tips-section">
                              {!tips_visible && (
                                <button
                                  className="button"
                                  onClick={this._showTips}
                                >
                                  Show Tips
                                </button>
                              )}
                              {tips_visible && (
                                <p
                                  className="tips"
                                  style={{
                                    width: `${document.getElementById("canvas").width
                                      }px`,
                                  }}
                                  onClick={this._hideTips}
                                >
                                  {current_slide.tips}
                                </p>
                              )}
                            </div>
                          )}
                        {_.has(current_slide, "path") && (
                          <img
                            id="slide1"
                            src={current_slide.path}
                            alt={current_slide.name}
                            className={`img whiteboard unselectable ${user_type === 'student' ? 'student-canvas-img' : 'teacher-canvas-img'}`}
                            width="660"
                            height="495"
                          />
                        )}
                        <canvas
                          ref={this.canvasRef}
                          className="whiteboard"
                          id="canvas"
                          width="660"
                          height="495"
                        />
                      </div>
                    </div>
                    <div className="column">
                      <div className="twilio-box">
                        <Twilio
                          roomName={class_session.videoroom_id}
                          userName={_.get(user, "profile.fullname", "")}
                          class_id={class_id}
                          isScreenSharedByTeacher={isScreenSharedByTeacher}
                          setIsScreenSharedByTeacher={this.setIsScreenSharedByTeacher}
                          isScreenSharingAllowed={isScreenSharingAllowed}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        <div>
          <Popup onClose={this.showModal} show={this.state.show}>
            {modelContent}
          </Popup>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    class_slides: _.get(state, "room.class_slides", {}),
    class_session: _.get(state, "room.class_session", {}),
    class_drawing: _.get(state, "room.class_drawing", {}),
    save_drawing: _.get(state, "room.save_drawing", {}),
    save_video: _.get(state, "room.save_video", {}),
    remove_drawing: _.get(state, "room.remove_drawing", {}),
    user: _.get(state, "auth.login.result.response.data", {}),
    user_type: _.get(state, "auth.login.result.response.role", ""),
    call_parents: _.get(state, "room.call_parents", {})
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClassRoomSlidesRequest: (payload) =>
    dispatch({ type: reduxActions.GET_CLASS_ROOM_SLIDES_REQUEST, payload }),
  getClassRoomSessionRequest: (payload) =>
    dispatch({ type: reduxActions.GET_CLASS_ROOM_SESSION_REQUEST, payload }),
  getClassRoomDrawingRequest: (payload) =>
    dispatch({ type: reduxActions.GET_CLASS_ROOM_DRAWING_REQUEST, payload }),
  saveClassRoomDrawingRequest: (payload) =>
    dispatch({ type: reduxActions.SAVE_CLASS_ROOM_DRAWING_REQUEST, payload }),
  removeClassRoomDrawingRequest: (payload) =>
    dispatch({ type: reduxActions.REMOVE_CLASS_ROOM_DRAWING_REQUEST, payload }),
  updateClassroomSessionRequest: (payload) =>
    dispatch({ type: reduxActions.UPDATE_CLASS_ROOM_SESSION_REQUEST, payload }),
  updateTrackingTimeRequest: (payload) =>
    dispatch({ type: reduxActions.UPDATE_TRACKING_TIME_REQUEST, payload }),
  showPageLoader: () => dispatch({ type: reduxActions.SHOW_PAGE_LOADER }),
  hidePageLoader: () => dispatch({ type: reduxActions.HIDE_PAGE_LOADER }),
  callClassStudents: (payload) => dispatch({
    type: reduxActions.CALL_CLASS_STUDENT_REQUEST, payload
  }),
  restrictText: (payload) =>
    dispatch({ type: reduxActions.RESTRICT_TEXT_FOR_STUDENT_REQUEST, payload }),
  saveClassRoomVideo: (payload) =>
    dispatch({ type: reduxActions.SAVE_CLASS_ROOM_VIDEO_REQUEST, payload }),
  screenSharedByteacher: (payload) =>
    dispatch({ type: reduxActions.SCREEN_SHARED_BY_TEACHER_REQUEST, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(Room);
