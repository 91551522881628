import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";
import ProfileWidget from "../../components/layout/ProfileWidget";
import { _ROUTES } from "../../constants/GlobalConst";
import { _CONST, _LEARNING_PROGRESS_LEVEL } from "../../constants/GlobalConst";
import ClassColorIcon from "../../assets/images/icon_class_color.png";
import UserIcon from "../../assets/images/icon_user.jpg";
import StarFilledIcon from "../../assets/images/icon_star_filled.png";
import StarIcon from "../../assets/images/icon_star.png";
import * as reduxActions from "../../constants/ActionTypes";
import { generateStudentGrade } from "../../helpers/Utils";
import starkid_logo from '../../assets/images/starkid-logo-transparent-01.png';
import { FaLanguage } from 'react-icons/fa';
import "./ReportStyle.css";

class ViewReport extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { classId },
      },
    } = props;

    this.state = {
      class_id: classId,
      class_session: {},
      total_stars: null,
      form_data: {
        number_of_stars_rewarded_to_student: null,
        feedback: "",
        chinese_feedback: "",
        social_studies_and_science_level: null,
        speaking_and_listening_level: null,
        reading_and_writing_level: null,
        grammar_level: null,
        concentration_level: null,
        reaction_level: null,
        expression_level: null,
        interaction_level: null,

        strenth_and_weakness: [],
        teacher_suggested_area: [],
        csv_data: [],

      },
      error: "",
      render: false,
      student_grade: { grade: 'F', average: 0 },
      class_learning: 0,
      parents_feedback: '',
      parents_suggested_area: [{ sugestion: 'Grammar', isSelected: false }, { sugestion: 'Oral', isSelected: false }, { sugestion: 'Reading', isSelected: false }, { sugestion: 'Listening', isSelected: false }, { sugestion: 'Vocabulary', isSelected: false }],

      parents_feedback_error: "",
      parents_suggested_error: "",
      unit_uuid: "",
      is_unit_report_available: false,
      parent_suggested_response: [],
      parents_feedback_rasponse: "",
      display_chinese_feedback: false,
      chinese_feedback_loader: false

    };
  }

  componentDidMount() {
    const { getClassRoomSessionRequest, getAllPetsRequest } = this.props;
    const { class_id } = this.state;

    getAllPetsRequest();

    // Get session details
    getClassRoomSessionRequest({ id: class_id });
  }

  componentDidUpdate(prevProps) {
    const {
      class_session,
      get_report,
      all_pets,
      getClassRoomReportRequest,
      update_parents_suggestions,
      storeNotificationRequest,
      history
    } = this.props;
    const { class_id, form_data } = this.state;

    if (prevProps.update_parents_suggestions !== update_parents_suggestions) {
      const { result } = update_parents_suggestions;

      if (result.success) {
        // Get session details
        storeNotificationRequest("<b>Heads up!</b> Your feedback has been submitted.");
        history.push(_ROUTES.PATH_DASHBOARD);
      }
    }

    if (prevProps.all_pets !== all_pets) {
      const { result } = all_pets;
      if (result.success) {
        this.setState({
          total_stars: _.get(result, "response.data.stars_in_total", null),
        });
      } else {
        // pets not found
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.class_session !== class_session) {
      const { result } = class_session;
      if (result.success) {
        const cs = _.get(result, "response.data", {});
        if (this._validClassSession(cs)) {
          this.setState(
            {
              class_session: cs,
            },
            () => getClassRoomReportRequest({ class_id })
          );
        } else {
          this.setState({ error: "Invalid classroom session" });
        }
      } else {
        // class session is not found
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.get_report !== get_report) {
      const { result } = get_report;
      if (result.success) {
        const data = _.get(result, "response.data", {});
        if (!_.isEmpty(data)) {
          const csv_report_data = _.get(data, "csv_report_data", null);

          if (csv_report_data) {
            let strenth_and_weakness = JSON.parse(_.get(data, "strenth_and_weakness", null));
            let parents_suggested_res = JSON.parse(_.get(data, "parents_suggested_area", null));
            const parents_feed = _.get(data, "parents_feedback", null);

            this.setState({
              form_data: {
                ...form_data,
                feedback: _.get(data, "feedback", null),
                chinese_feedback: _.get(data, "chinese_feedback", null),
                strenth_and_weakness,
                teacher_suggested_area: JSON.parse(_.get(data, "teacher_suggested_area", null)),
                csv_data: JSON.parse(_.get(data, "csv_report_data", null)),
              },
              render: true,
              student_grade: generateStudentGrade(strenth_and_weakness),
              class_learning: data.class_learning,
              unit_uuid: _.get(data, "uuid", null),
              is_unit_report_available: true,
              parent_suggested_response: Boolean(parents_suggested_res) ? parents_suggested_res : [],
              parents_feedback_rasponse: Boolean(parents_feed) ? parents_feed : ""
            });
          }
          else {
            this.setState({
              form_data: {
                ...form_data,
                number_of_stars_rewarded_to_student: _.get(
                  data,
                  "number_of_stars_rewarded_to_student",
                  null
                ),
                feedback: _.get(data, "feedback", null),
                chinese_feedback: _.get(data, "chinese_feedback", null),

                social_studies_and_science_level: _.get(
                  data,
                  "social_studies_and_science_level",
                  null
                ),
                speaking_and_listening_level: _.get(
                  data,
                  "speaking_and_listening_level",
                  null
                ),
                reading_and_writing_level: _.get(
                  data,
                  "reading_and_writing_level",
                  null
                ),
                grammar_level: _.get(data, "grammar_level", null),

                concentration_level: _.get(data, "concentration_level", null),
                reaction_level: _.get(data, "reaction_level", null),
                expression_level: _.get(data, "expression_level", null),
                interaction_level: _.get(data, "interaction_level", null),
              },
              render: true,
            });
          }
        } else {
          this.setState({ error: "Report data does not exists" });
        }
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }
}

  // Validate class room session against login user
  _validClassSession = (class_session) => {
    const { user } = this.props;
    try {
      return user.uuid === class_session.student_account_uuid; // only student can view report
    } catch {
      return false;
    }
  };

  _renderStars = (level, small = false) => {
    try {
      return (
        <React.Fragment>
          {_.map([1, 2, 3, 4, 5], (limit, key) => {
            return (
              <img
                src={limit <= level ? StarFilledIcon : StarIcon}
                className={`image ${small ? `is-32x32` : `is-48x48`}`}
                key={key}
              ></img>
            );
          })}
        </React.Fragment>
      );
    } catch {
      return false;
    }
  };

  // Display leaning level text
  _displayLearningLevelText = (level) => {
    try {
      const found = _.find(_LEARNING_PROGRESS_LEVEL, (lpl) => {
        return lpl.value === level;
      });
      return (
        <span style={{ ...found.style }} className="subtitle">
          {found.text}
        </span>
      );
    } catch {
      return "";
    }
  };

  _handleSetSugestions = (sugestItem) => {
    const updatedItem = this.state.parents_suggested_area.map((item) => {
      if (Boolean(item)) {
        if (sugestItem.sugestion === item.sugestion) {
          return { ...item, isSelected: !sugestItem.isSelected }
        }
        else {
          return item;
        }
      }
    });

    this.setState({ parents_suggested_area: updatedItem }, () => {
      if (this._getSugestionCount() > 0) {
        this.setState({ parents_suggested_error: "" });
      }
    });
  }

  _getSugestionCount = () => {
    let counter = 0;
    for (const sugestion of this.state.parents_suggested_area) {
      if (sugestion.isSelected === true) counter += 1;
    }
    return counter;
  }

  _handleParentsSugestions = () => {
    if (!Boolean(this.state.parents_feedback)) {
      this.setState({ parents_feedback_error: "This field can't be empty" });
    }

    if (this._getSugestionCount() === 0) {
      this.setState({ parents_suggested_error: "Select at least 1 or 3 suggestions" });
    }

    if (Boolean(this.state.parents_feedback) && this._getSugestionCount() > 0) {

      // Remove non selected items from parentas suggested area
      let parentsSuggestedArea = this.state.parents_suggested_area.filter(function (filterItem) {
        return filterItem.isSelected === true;
      });

      const payload = {
        uuid: this.state.unit_uuid,
        parents_feedback: this.state.parents_feedback,
        parents_suggested_area: JSON.stringify(parentsSuggestedArea),
        is_acknowledge: false
      }
      this.props.updateParentsSuggestions(payload);
    }
  }

  _handleDisplayChineseFeedback = () => {
    this.setState({
      chinese_feedback_loader: true
    })
    const { class_id, form_data } = this.state;
    if(!form_data.chinese_feedback){
      
      const feedback = form_data.feedback;
      this.props.getChinesesFeedbackRequest({
        class_id,
        feedback
      });

      setTimeout(() => {
        this.props.getClassRoomReportRequest({ class_id });
        this.setState({
          display_chinese_feedback: !this.state.display_chinese_feedback, 
          chinese_feedback_loader: false
        })
      }, 3000);
      
    }
    else{
      setTimeout(() => {
        this.setState({
          display_chinese_feedback: !this.state.display_chinese_feedback, 
          chinese_feedback_loader: false
        })
      }, 1000);
    }
    
  }
  _render_parents_suggested_area = () => {
    if (this.state.parent_suggested_response.length > 0) {
      return (
        this.state.parent_suggested_response.length > 0 && this.state.parent_suggested_response
          .map((item, index) => {
            if (item.isSelected) {
              return (
                <div class="button btn-go-to-class teacher-suggestin-buttons" style={{ backgroundColor: '#efefef' }}>{`${item.sugestion}`}</div>
              )
            }
          })
      );
    }
    else {
      return (
        this.state.parents_suggested_area.map((item, index) => {
          return (
            <button class="button btn-go-to-class teacher-suggestin-buttons" style={{ backgroundColor: item.isSelected === true ? '#ffcc01' : '#efefef' }} onClick={(e) => {
              e.preventDefault();
              if (this._getSugestionCount() < 3 || (this._getSugestionCount() === 3 && item.isSelected === true)) {
                this._handleSetSugestions(item);
              }
            }}>{`${item.sugestion}`}</button>
          )
        })
      )
    }
  }

  _render_student_report_card = () => {
    const { class_session, parents_feedback_rasponse, chinese_feedback_loader } = this.state;
    return (
      <div>
        <section className="section report-section pb0 report-card-title">
          <div className="columns">
            <div className="column pg-title">Student Report Card</div>
          </div>
        </section>

        <section className="section report-section pt10 pb0">
          <div className="box report-box main-container-holder">

            <form>
              <div className="report-frame">

                <div className="report-frame-header">
                  <div className="report-frame-logo">
                    <img src={starkid_logo} />
                  </div>
                  <div className="report-frame-grade-container">
                    <span className="grade">{this.state.student_grade.grade}</span>
                    <span className="avg">{this.state.student_grade.average}/100</span>
                  </div>
                </div>

                <div className="report-frame-body">
                  <p className="presented">This Report Card is Presented To</p>
                  <p className="student-name">{this.state.class_session.account.profile.fullname}</p>
                  <p className="desc">Congratulations on completing Level {this.state.class_session.course.level} Unit {this.state.class_session.course.unit} Lesson {this.state.class_session.course.lesson}.<br /> This report card is created for parents to
                    understand deeply about what the student has learned in Starkid. It includes the
                    strengths and weaknesses of student identified by the teacher,
                    and some areas of improvementthat suggested by teacher. Parents also have a chance to coomunicate
                    back to teacher to align with the best learning objectives for student.</p>
                </div>

                <div className="report-frame-footer">
                  <div className="left">
                    <p className="teacher-name">{this.state.class_session.teacher_account.profile.fullname}</p>
                    <p>Starkid Instructor</p>
                  </div>
                  <div className="right">
                    <p className="date">{moment(this.state.class_session.assigned_at).format("YYYY-MM-DD")}</p>
                    <p>Date</p>
                  </div>
                </div>

              </div>

              <div className="line">
                <div className="title is-spaced">
                  Strengths & Weaknesses
                </div>
                <span className="subtitle">
                  Learning Progress Summary
                </span>
              </div>

              <div className="line">
                <div className="columns mb0">
                  <div className="column mar-s-and-w">
                    <span className="strength-title">
                      Reading
                    </span>
                    <div className="progress-report">
                      <div className="progress-bar" style={{ width: `${this.state.form_data.strenth_and_weakness[0].reading}%` }}>
                      </div>
                    </div>
                    <span className="txt-align-right">
                      {this.state.form_data.strenth_and_weakness[0].reading}/100
                    </span>
                  </div>
                </div>

                <div className="columns mb0">
                  <div className="column mar-s-and-w">
                    <span className="strength-title">
                      Listening
                    </span>
                    <div className="progress-report">
                      <div className="progress-bar" style={{ width: `${this.state.form_data.strenth_and_weakness[1].listening}%` }}>
                      </div>
                    </div>
                    <span className="txt-align-right">
                      {this.state.form_data.strenth_and_weakness[1].listening}/100
                    </span>
                  </div>
                </div>

                <div className="columns mb0">
                  <div className="column mar-s-and-w">
                    <span className="strength-title">
                      Grammar
                    </span>
                    <div className="progress-report">
                      <div className="progress-bar" style={{ width: `${this.state.form_data.strenth_and_weakness[2].grammar}%` }}>
                      </div>
                    </div>
                    <span className="txt-align-right">
                      {this.state.form_data.strenth_and_weakness[2].grammar}/100
                    </span>
                  </div>
                </div>

                <div className="columns mb0">
                  <div className="column mar-s-and-w">
                    <span className="strength-title">
                      Pronunciation
                    </span>
                    <div className="progress-report">
                      <div className="progress-bar" style={{ width: `${this.state.form_data.strenth_and_weakness[3].pronunciation}%` }}>
                      </div>
                    </div>
                    <span className="txt-align-right">
                      {this.state.form_data.strenth_and_weakness[3].pronunciation}/100
                    </span>
                  </div>
                </div>

                <div className="columns mb0">
                  <div className="column mar-s-and-w">
                    <span className="strength-title">
                      Vocabulary
                    </span>
                    <div className="progress-report">
                      <div className="progress-bar" style={{ width: `${this.state.form_data.strenth_and_weakness[4].vocabulary}%` }}>
                      </div>
                    </div>
                    <span className="txt-align-right">
                      {this.state.form_data.strenth_and_weakness[4].vocabulary}/100
                    </span>
                  </div>
                </div>
              </div>

              <div className="line">
              <div className="feedback-field">
                <div className="title is-spaced">
                  Recommendations for {" "}
                  {_.get(
                    class_session,
                    "account.profile.fullname",
                    ""
                  )}
                </div>
                <button className="button"  onClick={(e) => {
                        e.preventDefault();
                        this._handleDisplayChineseFeedback();
                      }}> 
                      {
                        chinese_feedback_loader ?
                        <div className="loader"></div> :
                        <><FaLanguage size={25} className="language-icon" /> Translate </>
                      }
                </button>
                </div>
                <div className="field">
                  <div className="control">
                    <span className="subtitle">
                      { 
                        this.state.display_chinese_feedback ? 
                        this.state.form_data.chinese_feedback :
                        this.state.form_data.feedback
                      }
                    </span>
                  </div>
                </div>
              </div>

              <div className="line">
                <div className="title is-spaced">
                  Suggested Areas to Practice
                </div>
                <div className="field">
                  <div className="control">
                    {
                      this.state.form_data.teacher_suggested_area.length > 0 && this.state.form_data.teacher_suggested_area
                        .map((item, index) => {
                          if (item.isSelected) {
                            return (
                              <div class="button btn-go-to-class teacher-suggestin-buttons" style={{ backgroundColor: '#efefef' }} >{`${item.sugestion}`}</div>
                            )
                          }
                        })
                    }
                  </div>
                  <p className="help is-danger">

                  </p>
                </div>
              </div>

              <div className="line">
                <div className="title is-spaced">
                  Parents' Feedback
                </div>
                <div className="field">
                  <div className="control">
                    {Boolean(parents_feedback_rasponse) ? (
                      <span className="subtitle">
                        {this.state.parents_feedback_rasponse}
                      </span>)
                      : (
                        <textarea
                          className={`textarea`}
                          row="5"
                          onChange={(e) => {
                            this.setState({ parents_feedback: e.target.value }, () => {
                              if (this.state.parents_feedback) {
                                this.setState({ parents_feedback_error: "" });
                              }
                            });
                          }}
                        >
                        </textarea>
                      )}

                  </div>
                  <p className="help is-danger">
                    {this.state.parents_feedback_error}
                  </p>
                </div>
              </div>

              <div className="line">
                <div className="title is-spaced">
                  Parents' Suggested Area of Focus
                </div>
                <div className="field">
                  <div className="control">
                    {
                      this._render_parents_suggested_area()
                    }
                  </div>
                  <p className="help is-danger">
                    {this.state.parents_suggested_error}
                  </p>
                </div>
              </div>

              {
                this.state.parent_suggested_response.length === 0 &&
                (
                  <div className="button-holder pb40">
                    <button
                      className="button is-large"
                      onClick={(e) => {
                        e.preventDefault();
                        this._handleParentsSugestions();
                      }}
                    >
                      Confirm Review
                    </button>
                  </div>
                )
              }

              <div className="class-learning">
                <div className="title-learning">
                  Class Learning
                </div>

                <div className="progress-container">
                  <div className="progress-report-advanced">
                    <div className="progress-bar-advanced" style={{ width: `${this.state.class_learning}%` }}></div>
                    <span className="percentage" style={{ color: 'green' }}>{this.state.class_learning}% Mastered</span>
                  </div>

                </div>
              </div>

              <div className="line">
                {
                  this.state.form_data.csv_data.length > 0 && this.state.form_data.csv_data.map((item, mainIndex) => {
                    return (
                      <div className="report_cell" key={`key-${mainIndex}`}>
                        <div className="report_cell_header">
                          {item[0].task}
                        </div>
                        <div className="column_data_holder">
                          <ul class="unstyled centered">
                            {item.slice(1).map((subItem, index) => {
                              let checkboxId = `styled-checkbox-key-${Math.random().toString(16).slice(2)}-${index}`;
                              if (Boolean(subItem)) {
                                return (
                                  <li key={`sub-item-${index}`}>
                                    <div className="report-text-holder">
                                      {mainIndex !== 0 ? (<>
                                        <input class="styled-checkbox" id={checkboxId} type="checkbox" value={subItem.task} checked={subItem.isChecked} onClick={() => {
                                          this._handleCheckTask(subItem);
                                        }} />
                                        <label htmlFor={checkboxId}>{subItem.task}</label>
                                      </>) : (<label>{subItem.task}</label>)}
                                    </div>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }

  _render_class_report = () => {
    const { class_session, form_data, total_stars, chinese_feedback_loader, display_chinese_feedback } = this.state;
    return (
      <div>
        <ProfileWidget totalStars={total_stars} />

        <section className="section report-section pt0 pb0">
          <div className="columns">
            <div className="column pg-title">Class Report</div>
          </div>
        </section>

        <section className="section report-section pt10 pb0">
          <div className="box report-box">
            <div className="line">
              <div className="columns">
                <div className="column is-7">
                  <div className="title first is-spaced">
                    <img
                      className="image is-32x32"
                      src={ClassColorIcon}
                      alt="Class"
                    />
                    <span>
                      {`Level ${_.get(
                        class_session,
                        "course.level",
                        ""
                      )} - Unit ${_.get(
                        class_session,
                        "course.unit",
                        ""
                      )} - Lesson ${_.get(
                        class_session,
                        "course.lesson",
                        ""
                      )}`}
                    </span>
                  </div>
                  <div className="subtitle is-4 time">{`${moment(
                    class_session.start_at
                  )
                    .tz(_CONST.TIMEZONE)
                    .format("YYYY - M - D dddd h:mmA")} - ${moment(
                      class_session.end_at
                    )
                      .tz(_CONST.TIMEZONE)
                      .format("h:mmA")}`}</div>
                  <div className="columns user-details">
                    <div className="column">
                      <img
                        className="image is-16x16"
                        src={UserIcon}
                        alt="User"
                      />
                      <span className="subtitle ml10">
                        Teacher:{" "}
                        {_.get(
                          class_session,
                          "teacher_account.profile.fullname",
                          ""
                        )}
                      </span>
                    </div>
                    <div className="column">
                      <img
                        className="image is-16x16"
                        src={UserIcon}
                        alt="User"
                      />
                      <span className="subtitle ml10">
                        Student:{" "}
                        {_.get(
                          class_session,
                          "account.profile.fullname",
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="column is-5 col-stars has-text-right-desktop">
                  <div className="stars">
                    {this._renderStars(
                      form_data.number_of_stars_rewarded_to_student
                    )}
                  </div>
                  <div className="subtitle">
                    Stars given to{" "}
                    {_.get(class_session, "account.profile.fullname", "")}
                  </div>
                </div>
              </div>
            </div>

            <div className="line">
            <div className="feedback-field">
              <div className="title is-spaced">Teacher Comments</div>
              <button className="button"  onClick={(e) => {
                        e.preventDefault();
                        this._handleDisplayChineseFeedback();
              }}> 
                {
                  chinese_feedback_loader ?
                  <div className="loader"></div> :
                  <><FaLanguage size={25} className="language-icon" /> Translate </>
                }
              </button>
              </div>
              <div className="subtitle">
                { 
                  display_chinese_feedback ? 
                  form_data.chinese_feedback :
                  form_data.feedback
                }
              </div>
            </div>

            <div className="line">
              <div className="title is-spaced">Learning progress</div>
              <div className="columns">
                <div className="column is-half">
                  <span className="subtitle">
                    Social Studies and Science
                  </span>
                </div>
                <div className="column is-half">
                  {this._displayLearningLevelText(
                    form_data.social_studies_and_science_level
                  )}
                </div>
              </div>
              <div className="columns">
                <div className="column is-half">
                  <span className="subtitle">Speaking and Listening</span>
                </div>
                <div className="column is-half">
                  <span className="subtitle">
                    {this._displayLearningLevelText(
                      form_data.speaking_and_listening_level
                    )}
                  </span>
                </div>
              </div>
              <div className="columns">
                <div className="column is-half">
                  <span className="subtitle">Reading and Writing</span>
                </div>
                <div className="column is-half">
                  <span className="subtitle">
                    {this._displayLearningLevelText(
                      form_data.reading_and_writing_level
                    )}
                  </span>
                </div>
              </div>
              <div className="columns">
                <div className="column is-half">
                  <span className="subtitle">Grammar</span>
                </div>
                <div className="column is-half">
                  <span className="subtitle">
                    {this._displayLearningLevelText(
                      form_data.grammar_level
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="line">
              <div className="title is-spaced">Class Performance</div>
              <div className="columns col-performance">
                <div className="column is-half">
                  <span className="subtitle">Concentration</span>
                </div>
                <div className="column is-half">
                  {this._renderStars(form_data.concentration_level, true)}
                </div>
              </div>

              <div className="columns col-performance">
                <div className="column is-half">
                  <span className="subtitle">Reaction</span>
                </div>
                <div className="column is-half">
                  {this._renderStars(form_data.reaction_level, true)}
                </div>
              </div>

              <div className="columns col-performance">
                <div className="column is-half">
                  <span className="subtitle">Expression</span>
                </div>
                <div className="column is-half">
                  {this._renderStars(form_data.expression_level, true)}
                </div>
              </div>

              <div className="columns col-performance">
                <div className="column is-half">
                  <span className="subtitle">Interaction</span>
                </div>
                <div className="column is-half">
                  {this._renderStars(form_data.interaction_level, true)}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  render() {
    const { render, error, is_unit_report_available } = this.state;

    return (
      <div className="container">
        {!_.isEmpty(error) && (
          <section className="section pb0 pt0">
            <div
              className="notification"
              dangerouslySetInnerHTML={{ __html: error }}
            ></div>
          </section>
        )}

        {render === true && is_unit_report_available === true ? this._render_student_report_card() : this._render_class_report()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _.get(state, "auth.login.result.response.data", {}),
    class_session: _.get(state, "room.class_session", {}),
    get_report: _.get(state, "room.get_report", {}),
    all_pets: _.get(state, "room.all_pets", {}),
    update_parents_suggestions: _.get(state, "room.update_parents_suggestions", {}),
    get_chinese_feedback: _.get(state, "get_chinese_feedback",{})
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClassRoomSessionRequest: (payload) =>
    dispatch({ type: reduxActions.GET_CLASS_ROOM_SESSION_REQUEST, payload }),
  getClassRoomReportRequest: (payload) =>
    dispatch({ type: reduxActions.GET_CLASS_ROOM_REPORT_REQUEST, payload }),
  getAllPetsRequest: () =>
    dispatch({ type: reduxActions.GET_ALL_PETS_REQUEST }),
  updateParentsSuggestions: (payload) =>
    dispatch({ type: reduxActions.PARENTS_SUGGESTIONS_REQUEST, payload }),
  storeNotificationRequest: (payload) =>
    dispatch({ type: reduxActions.STORE_NOTIFICATION_REQUEST, payload }),
  getChinesesFeedbackRequest: (payload) =>
    dispatch({ type: reduxActions.GET_CHINESE_FEEDBACK_REQUEST, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewReport);
