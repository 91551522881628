import React, { Component } from 'react';
class BrowserNote extends Component {
    render() {
        return (
            <div className='browserNote'>
                <div><p className='headNote'>NOTE</p>
                    <p>You are not using Chrome becuse of that some features will not support</p>
                </div>
            </div>
        );
    }
}

export default BrowserNote;